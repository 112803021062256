
inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};
inz.forms.validations = inz.forms.validations || {};

/**
 * BankCode
 */

inz.forms.validations.BankCodeIBAN = class {
    static nam = "IBAN";
    static prefix = "I";
    static format = /I(\d+)-?(.*)/;
    static bban_re = /(\d+[n|c|a])?/g;

    static B_DIGITS = "n";
    static B_UPPER = "a"; // a Upper case letters (alphabetic characters A-Z only)
    static B_ALPHA = "c"; // c upper and lower case alphanumeric characters (A-Z, a-z and 0-9)
    static B_BLANK = "e"; // e blank space

    static mask_map = {
        "n": "9", // n Digits (numeric characters 0 to 9 only)
        "a": "A", // a Upper case letters (alphabetic characters A-Z only)
        "c": "+", // c upper and lower case alphanumeric characters (A-Z, a-z and 0-9) converted to upper case
        "e": " ", // e blank space
    }

    static re_map = {
        "n": "[0-9]",
        "a": "[A-Z]",
        "c": "[A-z0-9]",
        "e": " ",
    }

    static create(cfg_str, vp) {
        if (!cfg_str.startsWith(this.prefix))
            return null;
        let m = cfg_str.match(this.format);
        if (m === null) {
            console.log("ERROR: validation config");
            return null;
        }
        let len = Number(m[1]);
        let sections = m[2].match(this.bban_re);
        if (sections === null) {
            console.log("ERROR: invalid sub config: "+cfg_str);
            return null;
        }
        return new inz.forms.validations.BankCodeIBAN(len, sections, vp);
    }

    constructor(len, secs, vp) {
        this.length = len;
        this.sections = [];
        let re_str;
        let definitions = null;
        if (vp !== undefined) {
            this.mask = "XY99";
            let lwr = vp.toLowerCase();
            definitions = {
                "X": {"validator": `[${vp[0]}${lwr[0]}]`, "casing": "upper", "placeholder": `${vp[0]}`},
                "Y": {"validator": `[${vp[1]}${lwr[1]}]`, "casing": "upper", "placeholder": `${vp[1]}`}
            };
            re_str = `^${vp}[0-9]{2}`;
        } else {
            this.mask = "AA99";
            re_str = "^[A-Z]{2}[0-9]{2}";
        }
        for (let s of secs) {
            if (s.length === 0) break;
            let a = {"l": parseInt(s), "c": s[s.length-1] };
            this.sections.push(a);
            this.mask += inz.forms.validations.BankCodeIBAN.mask_map[a.c].repeat(a.l);
            re_str += `${inz.forms.validations.BankCodeIBAN.re_map[a.c]}{${a.l}}`;
        }
        this.mask = this.mask.replace(/(.{4})/g, "$1-");
        if (this.mask[this.mask.length-1] === '-')
            this.mask = this.mask.slice(0, -1);
        re_str += '$';
        this.re = new RegExp(re_str);
        if (definitions !== null) {
            this.mask = {"mask": this.mask, "definitions": definitions};
        }
    }

    getInputMask() {
        return this.mask;
    }

    getValidationMessage() {
        return `a valid IBAN code of ${this.length} characters`;
    }

    setup(field) {
        let $f = $(field);
        $f.inputmask(this.mask);
        $f.attr("data-parsley-error-message", `a valid IBAN code of ${this.length} characters`);
    }

    validate(input) {
        //console.log("Validate: "+inz.forms.validations.BankCodeIBAN.nam);
        let i = input.replaceAll('-','');
        if (!this.re.test(i))
            return false;
        return this.validateIBAN(i);
    }

    validateIBAN(input) {
        let iban = (input.substr(4) + input.substr(0,4)).toUpperCase();
        iban = iban.replace(/[A-Z]/g, function (m) { return m.charCodeAt(0) - 55; });
        // mod
        let block;
        let iter = 0;
        while (iban.length > 2 && iter < 100) {
            block = iban.slice(0, 9);
            iban = parseInt(block, 10) % 97 + iban.slice(block.length);
            iter += 1;
        }
        let checkcode = parseInt(iban, 10) % 97;
        //console.log(`Checkcode: ${checkcode}`);
        return checkcode === 1;
    }

    inputTransforms(field) {
    }
}

inz.forms.validations.BankCodeSWIFT = class {
    static nam = "SWIFT";
    static prefix = "S";
    static def_re = /^[A-Z]{4}-[A-Z]{2}-[A-Z0-9]{2}(?:-[A-Z0-9]{3})?$/;
    //static def_mask = {"mask": "AAAA-AA-[A|9]{2}[-[A|9]{3}]", "greedy": true};
    static def_mask = {"mask": "AAAA-AA-++[-+{3}]", "greedy": false};

    static create(cfg_str, vp) {
        if (!cfg_str.startsWith(this.prefix))
            return null;
        return new inz.forms.validations.BankCodeSWIFT();
    }

    constructor() {
        this.re = inz.forms.validations.BankCodeSWIFT.def_re;
    }

    getInputMask() {
        return inz.forms.validations.BankCodeSWIFT.def_mask;
    }

    getValidationMessage() {
        return "a valid 8 or 11 character SWIFT code";
    }

    setup(field) {
        let $f = $(field);
        $f.inputmask(inz.forms.validations.BankCodeSWIFT.def_mask);
        $f.attr("data-parsley-error-message", `Please enter a valid 8 or 11 character SWIFT code`);
    }

    validate(input) {
        //console.log("Validate: "+inz.forms.validations.BankCodeSWIFT.nam);
        return this.re.test(input);
    }

    inputTransforms(field) {
        /*let $f = $(field);
        let v = $f.val();
        if (v) {
            $f.val(v.toUpperCase());
        }*/
    }
}

inz.forms.validations.BankCodeAlpha = class {
    static nam = "Alpha";
    static prefix = "A";
    static format = /A(\d+)(?:-(\d+))?/;

    static create(cfg_str, vp) {
        if (!cfg_str.startsWith(this.prefix))
            return null;
        let m = cfg_str.match(this.format);
        if (m === null) {
            console.log("ERROR: validation config");
            return null;
        }
        if (m[2] === undefined) {
            return new inz.forms.validations.BankCodeAlpha(Number(m[1]));
        } else {
            return new inz.forms.validations.BankCodeAlpha(Number(m[1]), Number(m[2]));
        }
    }

    constructor(len, max_length) {
        this.length = len;
        this.max_length = max_length;
        if (this.max_length === undefined) {
            this.range = false;
            this.re = new RegExp(`^[0-9A-z]{${this.length}}$`);
            this.mask = `*{${this.length}}`;
        } else {
            this.range = true;
            this.re = new RegExp(`^[0-9A-z]{${this.length},${this.max_length}}$`);
            this.mask = {"mask": `*{${this.length}}[*{${this.max_length-this.length}}]`, "greedy": false};
        }
    }

    setup(field) {
        let $f = $(field);
        $f.inputmask(this.mask);
        if (this.range) {
            $f.attr("data-parsley-error-message", `Please enter ${this.length} to ${this.max_length} characters`);
        } else {
            $f.attr("data-parsley-error-message", `Please enter ${this.length} digits`);
        }
    }

    getInputMask() {
        return this.mask;
    }

    getValidationMessage() {
        if (this.range) {
            return `${this.length} to ${this.max_length} characters`;
        } else {
            return "data-parsley-error-message", `${this.length} characters`;
        }
    }

    validate(input) {
        //console.log("Validate: "+inz.forms.validations.BankCodeAlpha.nam);
        let unmasked = Inputmask.unmask(input, {mask: this.mask});
        return this.re.test(input);
    }

    inputTransforms(field) {
    }

}

inz.forms.validations.BankCodeDigit = class {
    static nam = "Digit";
    static prefix = "D";
    static format = /D(\d+)(?:-(\d+))?/;

    static create(cfg_str, vp) {
        if (!cfg_str.startsWith(this.prefix))
            return null;
        let m = cfg_str.match(this.format);
        if (m === null) {
            console.log("ERROR: validation config");
            return null;
        }
        if (m[2] === undefined) {
            return new inz.forms.validations.BankCodeDigit(Number(m[1]));
        } else {
            return new inz.forms.validations.BankCodeDigit(Number(m[1]), Number(m[2]));
        }
    }

    constructor(len, max_length) {
        this.length = len;
        this.max_length = max_length;
        if (this.max_length === undefined) {
            this.range = false;
            this.re = new RegExp(`^\\d{${this.length}}$`);
            this.mask = `9{${this.length}}`;
        } else {
            this.range = true;
            this.re = new RegExp(`^\\d{${this.length},${this.max_length}}$`);
            //this.mask = `9{${this.length}}9{${this.max_length-this.length}}`;
            this.mask = {"mask": `9{${this.length}}[9{${this.max_length-this.length}}]`, "greedy": false};
        }
    }

    getInputMask() {
        return this.mask;
    }

    getValidationMessage() {
        if (this.range) {
            return `${this.length} to ${this.max_length} digits`;
        } else {
            return "data-parsley-error-message", `${this.length} digits`;
        }
    }

    setup(field) {
        let $f = $(field);
        $f.inputmask(this.mask);
        if (this.range) {
            $f.attr("data-parsley-error-message", `Please enter ${this.length} to ${this.max_length} digits`);
        } else {
            $f.attr("data-parsley-error-message", `Please enter ${this.length} digits`);
        }
    }

    validate(input) {
        //console.log("Validate: "+inz.forms.validations.BankCodeDigit.nam);
        let unmasked = Inputmask.unmask(input, {mask: this.mask});
        return this.re.test(unmasked);
    }

    inputTransforms(field) {
    }

}


inz.forms.validations.BankCodeMask = class {
    static nam = "Mask";
    static prefix = "M";
    static format = /^M(.*)\|(.*)$/;

    static create(cfg_str, vp) {
        if (!cfg_str.startsWith(this.prefix))
            return null;
        let m = cfg_str.match(this.format);
        if (m === null) {
            console.log("ERROR: validation config");
            return null;
        }
        // mask should be JSON string, convert it here
        let mask;
        try {
            mask = JSON.parse(m[1]);
        } catch (e) {
            console.log("ERROR: mask invalid syntax");
            return null;
        }
        return new inz.forms.validations.BankCodeMask(mask, m[2]);
    }

    constructor(mask, message) {
        this.mask = mask;
        this.message = message;
    }

    getInputMask() {
        return this.mask;
    }

    getValidationMessage() {
        return this.message;
    }

    validate(input) {
        //console.log("Validate: "+inz.forms.validations.BankCodeDigit.nam);
        let unmasked = Inputmask.unmask(input, {mask: this.mask});
        //return this.re.test(unmasked);
        return true;
    }

    inputTransforms(field) {
    }
}

inz.forms.fields.BankCode = class extends inz.forms.fields.Field {

    static validation_classes = [
        inz.forms.validations.BankCodeIBAN,
        inz.forms.validations.BankCodeSWIFT,
        inz.forms.validations.BankCodeAlpha,
        inz.forms.validations.BankCodeDigit,
        inz.forms.validations.BankCodeMask,
    ];

    /*constructor(form, $ctx, config) {
        super(form, $ctx, config);
    };*/

    static setupFieldType(parsley) {
        window.Parsley.addValidator('bankCode', {
            validateString: function(value, requirement, instance)
            {
                return instance.$element.data("field").validateInput(value);
            },
            /*messages: {
                en: 'This value should be a valid New Zealand Bank Account Number'
            }*/
        });
        Inputmask.extendDefinitions({
            '+': {
                validator: "[0-9A-z]",
                casing: "upper"
            }
        });
    }

    useGetOnSubmit() { return true; };

    init(form, $ctx, config) {
        super.init(form, $ctx, config);
        this.validation = null;
        this.prefix = null;
        this.inputMask = "";
    }

    setPrefix(prefix) {
        if (prefix === null) {
            this.prefix = null;
            return;
        }
        this.prefix = prefix;
    }

    reset() {
        super.reset();
    }

    setup(initial) {
        let _this = this;
        this.$el.on("input", function() { _this.inputTransforms(this); });
        this.defaultDisplay();
    }

    setupField(f) {
        this.inputMask = {mask: "", definitions: {}};
        let message = "";

        // prefixes, pv = static value prefix, pu = user prefix
        if ("pv" in f) {
            this.prefix = {t: "static", v: f.pv };
        } else if ("pu" in f) {
            this.prefix = {t: "user", v: f.pu};
            // {"r": u"[CS]NIC", "v": "A4"}
            // get a validation
            this.prefix.validation = this.createValidation(f.pu);
            if (this.prefix.validation !== null) {
                this.combineMasks(this.inputMask, this.prefix.validation.getInputMask());
                this.combineMasks(this.inputMask, "-");
                //this.inputMask = this.prefix.validation.getInputMask()+"-";
                message = this.prefix.validation.getValidationMessage();
            } else {
                console.log("Validation not found: "+f.pu);
            }
        } else {
            this.prefix = null;
        }

        if ("v" in f) {  // has validation
            this.validation = this.createValidation(f.v, f.vp);
            if (this.validation !== null) {
                //console.log("Validation found: Mask: "+this.validation.getInputMask());
                this.combineMasks(this.inputMask, this.validation.getInputMask());
                if (message !== "")
                    message += " followed by ";
                message += this.validation.getValidationMessage();
            } else {
                console.log("Validation not found: "+f.v);
            }
        }
        this.$el.inputmask(this.inputMask);
        this.$el.attr("data-parsley-error-message", "Please enter "+message);
    }

    createValidation(vstr, vp) {
        for (let validation of inz.forms.fields.BankCode.validation_classes) {
            let v = validation.create(vstr, vp);
            if (v === null)
                continue;
            return v;
        }
        return null;
    }

    combineMasks(dst, src) {
        if(typeof src === 'object') {
            if (Array.isArray(dst.mask) || Array.isArray(src.mask)) {
                let dm = dst.mask;
                if (!Array.isArray(dm)) {
                    dm = [dm];
                }
                let sm = src.mask;
                if (!Array.isArray(sm)) {
                    sm = [sm];
                }
                for (let i in sm) {
                    if (dm[i] === undefined)
                        dm.push("");
                }

                for (let i in dm) {
                    dm[i] += sm[i];
                }
                dst.mask = dm;
            } else {
                dst.mask += src.mask;
            }
            if ('definitions' in src) { // merge in any definitions
                Object.assign(dst.definitions, src.definitions);
            }
            if ('greedy' in src) {
                dst.greedy = src.greedy;
            }
        } else {
            dst.mask += src;
        }
    }

    setLabel(label) {
        this.$container.find("label").contents().first().replaceWith(label);
    }

    inputTransforms(field) {
        if (this.validation === null || this.validation === undefined)
            return;
        this.validation.inputTransforms(field);
    }

    validateInput(str) {
        // var r = RegExp(/^(\d{1,2})-(\d{1,4})-(\d{1,8})-(\d{1,4})$/).exec(str);  // this is the correct validation for the IRD spec, however INZ require that we reject suffixes that are not 2 or 3 characters
        //console.log("Bank Code: Validate Input: "+this.validation);
        if (this.inputMask && !this.$el.inputmask("isComplete"))
            return false;
        if (this.validation === null || this.validation === undefined)
            return true;
        if (!this.validation.validate(str))
            return false;
        //return this.validation.validate(str);
        return true;
    }

    getValue() {
        if (this.prefix !== null && this.prefix.t === "static") {
            return this.prefix.v + this.$el.val();
        }
        return this.$el.val();
    }
}


/**
 * Condition
 */

//inz.forms.Condition = function() {};

inz.forms.Condition = class {
    init(form, field, config, registerDepend) {
    /*                "test": "MATCHES_VALUE",
                    "tvalue": "1",
                    "depends": "4oB4Xez",
                    "op": "SHOW"
                    NATIONAL_SECURITY
                    ADDITIONAL_INFORMATION
     */
        this.form = form;
        this.field = field;
        this.testType = config.test;
        this.testValue = config.tvalue;
        this.op = config.op;
        this.stopProcessing = config.st;

        this.dependField = form.getField(config.depends);
        if(this.dependField === null)
            return false;
        if (registerDepend === undefined || registerDepend === true) {
            this.dependField.addDependant(this.field);
        }
        return true;
    };

    remove() {  // tidy up for condition removal
        this.dependField.removeDependant(this.field);
    }

    update(field, dependant) {
        this.field = field;
        this.dependField = dependant;
        this.dependField.addDependant(this.field);
    }

    shouldStopProcessing() {
        return this.stopProcessing;
    }

    shouldTest(src) {
        if (src === undefined)
            return true;
        else
            return this.dependField === src;
        // if (src.group !== null) {
        //     return src.id.startsWith(this.dependField.name);
        // } else {
        //     return this.dependField === src;
        // }
    }

    getValuesFromGroup(src) {
        if (src.group === null) {
            return null;
        }
        let ret = []
        for (let i=0;i<src.group.getCount();i++) {
            let id = src.group.createGroupId(src.config.id, i);
            let field = this.form.fields[id];
            if (field !== undefined)
                ret.push(field.getValue(true));
        }
        return ret;
    }

    test(value) {
        // all test involve values, so grab dependents one
        if (value === undefined)
            value = this.dependField.getValue(true);
        let isArray = $.isArray(value);

        switch (this.testType) {
            case "VALUE":
                if ((isArray && value.length !== 0) || (!isArray && value))
                    return true;
                break;

            case "NO_VALUE":
                if ((isArray && value.length === 0) || (!isArray && !value))
                    return true;
                break;

            case "MATCHES_VALUE":
                if ((isArray && $.inArray(this.testValue, value) !== -1) || (!isArray && value === this.testValue))
                    return true;
                break;

            case "NOT_MATCHES_VALUE":
                if ((isArray && $.inArray(this.testValue, value) === -1) || (!isArray && value !== this.testValue))
                    return true;
                break;

            case "HAS_VALUE_AND_NOT_MATCHES_VALUE":
                if ((isArray && value.length === 0) || (!isArray && !value))
                    return false;  // no value, false
                if ((isArray && $.inArray(this.testValue, value) === -1) || (!isArray && value !== this.testValue))
                    return true;
                break;

            case "GROUP_MATCHES_VALUE": {
                let values = this.getValuesFromGroup(this.dependField);
                return $.inArray(this.testValue, values) !== -1;
            }

            case "OLDER_THAN":
            case "YOUNGER_THAN":
            case "LESS_THAN":
            case "GREATER_THAN":
                // need value as date
                let date = Date.parse(value);
                if (isNaN(date)) {
                    break;
                }
                date = new Date(date);
                let testAge = Number(this.testValue);
                if (this.testType === "LESS_THAN") {
                    return this.lessThan(new Date(), date, testAge);
                } else if (this.testType === "GREATER_THAN") {
                    return !this.lessThan(new Date(), date, testAge);
                } else {
                    let age = this.calcAge(new Date(), date);
                    if((this.testType === "OLDER_THAN" && testAge<age) || (this.testType === "YOUNGER_THAN" && testAge>age)) {
                        return true;
                    }
                }
                break;
        }
        return false;
    }

    calcAge(today, date) {
        let age = today.getFullYear() - date.getFullYear();
        let months = today.getMonth() - date.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        return age;
    }

    lessThan(today, date, test) {
        let age = today.getFullYear() - date.getFullYear();
        let months = today.getMonth() - date.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        if (age < test) {
            return true
        }
        if (months === 0 && today.getDate() === date.getDate()) {
            if (test === age) {  // 0 day new age is less than if equal
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
}

inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};
inz.forms.validations = inz.forms.validations || {};

/**
 * InternationalPhoneNumberField
 *
 * Embeds the intltelinput widget to allow entering of phone numbers in a variety of formats.
 * (see https://github.com/jackocnr/intl-tel-input)
 */

inz.forms.fields.InternationalPhoneNumberField = class extends inz.forms.fields.Field
{

    init(form, $ctx, config)
    {
        super.init(form, $ctx, config);
        this.iti = null;
    }

    reset()
    {
        if(this.iti != null)  /* reset can be called prior to setup() - eg: when field is in a FieldSet */
        {
            this.iti.setNumber('');
        }
    }

    setup(initial)
    {
        let args = {
            dropdownContainer: document.body,
            preferredCountries: ['nz'],
            utilsScript: this.config['iti_utils_url'],
            placeholderNumberType: this.$el.data('placeholderNumberType')
        }
        let cc = Cookies.get('geoip_user_cc');
        if(this.$el.data('geoip') !== undefined && typeof (cc) == 'string' && cc !== 'null')
        {
            // field has data-geoip attribute, and we got a valid country code
            args["initialCountry"] = "auto";
            args["geoIpLookup"] = (success, failure)=>{success(cc);};
        } else
        {
            // field has geoip disabled, or we weren't able to find a valid country code
            args["initialCountry"] = "NZ";
        }

        this.iti = window.intlTelInput(this.$el[0], args);
        this.$el.data('iti', this.iti);
        this.$el.keyup(this, this.form.changeEvent);
        this.defaultDisplay();
    }

    /**
     * Secret method that must return true to make the form submit the getValue() result rather than the form field's value
     * @returns {boolean}
     */
    useGetOnSubmit()
    {
        return true;
    };

    getValue()
    {
        return this.iti.getNumber();
    }

    setValue(value)
    {
        this.iti.setNumber(value);
    }

    static setupFieldType(parsley)
    {
        window.Parsley.addValidator(
            'internationalPhoneNumber',
            {
                validateString: function(value, requirement, instance)
                {
                    let iti = instance.$element.data("iti");
                    let isValid = iti.isValidNumber();
                    if(!isValid)
                    {
                        window.Parsley.addMessage('en', 'internationalPhoneNumber', ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"][iti.getValidationError()]);
                    }
                    return isValid;
                },
            }
        );
    }
}

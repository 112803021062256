window.inz = window.inz || {};
var inz = window.inz;
inz.forms = {};

/* Not even IE11 has String.endsWith()!! */
if(!String.prototype.endsWith)
{
    String.prototype.endsWith = function(search, this_len)
    {
        if(this_len === undefined || this_len>this.length)
        {
            this_len = this.length;
        }
        return this.substring(this_len-search.length, this_len) === search;
    };
}

// console logging
if (console.lg === undefined && typeof(document.body.dataset.sfjl) === 'undefined') {
    console.lg = [];
    console.defaultLog = console.log.bind(console);
    console.log = function() {
        //console.lg.push({"t":"log", "dt":Date.now(), "v":Array.from(arguments)});
        console.lg.push(Date.now()+",log,\""+Array.from(arguments)+"\"");
        console.defaultLog.apply(console, arguments);
    }
    console.defaultError = console.error.bind(console);
    console.error = function(){
        console.lg.push(Date.now()+",error,\""+Array.from(arguments)+"\"");
        console.defaultError.apply(console, arguments);
    }
    console.defaultWarn = console.warn.bind(console);
    console.warn = function(){
        console.lg.push(Date.now()+",warn,\""+Array.from(arguments)+"\"");
        console.defaultWarn.apply(console, arguments);
    }
    console.defaultDebug = console.debug.bind(console);
    console.debug = function(){
        console.lg.push(Date.now()+",debug,\""+Array.from(arguments)+"\"");
        console.defaultDebug.apply(console, arguments);
    }
}

inz.forms.init = function()
{
    inz.modularForms.errorScrollOffset = 30;

    // Setup Field Types
    inz.forms.fields.InternationalPayment.setupFieldType(window.Parsley);
    inz.forms.fields.BankCode.setupFieldType(window.Parsley);
    inz.forms.fields.InternationalPhoneNumberField.setupFieldType(window.Parsley);
    inz.forms.fields.Number.setupFieldType(window.Parsley);

    window.Parsley.addValidator('irdNumber', {
        validateString: function(value, requirement)
        {
            return inz.forms.validators.isValidIRDNumber(value);
        },
        messages: {
            en: 'This value should be a valid IRD Number'
        }
    });

    window.Parsley.addValidator('nzBankAccountNumber', {
        validateString: function(value, requirement)
        {
            return inz.forms.validators.isValidNZBankAccountNumber(value);
        },
        messages: {
            en: 'This value should be a valid New Zealand Bank Account Number'
        }
    });

    window.Parsley.addValidator('multilineMaxLength', {
        requirementType: 'integer',
        validateString: function(value, maxLength)
        {
            let len = value.replace(/\r(?!\n)|\n(?!\r)/g, "\r\n").length;
            return (len <= maxLength);
        },
        messages: {
            en: 'This value is too long.'
        }
    });

    window.Parsley.addValidator('maxFileSizeMb', {
        validateString: function(_value, maxSize, parsleyInstance)
        {
            if(!window.FormData)
                return true;
            var files = parsleyInstance.$element[0].files;
            if (files.length === 0)
                return true;
            for (var i=0; i<files.length; i++)
            {
                if (files[i].size>maxSize*1024*1024)
                    return false;
            }
            return true;
        },
        requirementType: 'integer',
        messages: {
            en: 'This file should not be larger than %s Mb',
        }
    });

    window.Parsley.addValidator('fileExt', {
        validateString: function(_value, nothing, parsleyInstance)
        {
            if(!window.FormData)
                return true;
            var files = parsleyInstance.$element[0].files;
            if (files.length === 0)
                return true;
            var exts = parsleyInstance.$element[0].getAttribute('accept').split(',');
            for (var i=0; i<files.length; i++)
            {
                var name = files[i].name.toLowerCase();
                var valid = false;
                for (var j=0; j<exts.length; j++)
                {
                    if (name.endsWith(exts[j]))
                    {
                        valid = true;
                        break;
                    }
                }
                if (valid === false)
                    return false;
            }
            return true;
        }
    });

    window.Parsley.addValidator('maxNumberFiles', {
        validateString: function(_value, maxNumber, parsleyInstance)
        {
            if(!window.FormData)
                return true;
            var files = parsleyInstance.$element[0].files;
            if (files.length === 0)
                return true;
            if (files.length > maxNumber)
                return false;
            if (files.length < maxNumber) {
                var previous = parsleyInstance.$element.siblings('.retain_file_marker').length;
                if ((files.length + previous) > maxNumber) {
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        },
        requirementType: 'integer',
        messages: {
            en: 'You are not allowed to upload more than %s files',
        }
    });

    window.Parsley.addValidator('dmyValidation', {
        validate: function(_value, requirement, instance)
        {
            //console.log("Instance: "+instance);
            var field = instance.$element.parents(".form_field").data("field");
            if (field !== undefined)
                return field.validate();
            return true;
        },
        messages: {
            en: 'This value should be a valid date'
        }
    });

    window.Parsley.addValidator('fieldValidate', {
        validateString: function(_value, requirement, instance)
        {
            //console.log("Instance: "+instance);
            let field = instance.$element.parents(".form_field").data("field");
            if (field !== undefined && field.validate !== undefined) {
                return field.validate();
            }
            return true;
        },
        messages: {
            en: 'This field is required'
        }
    });


    inz.forms.validators.initNZBankAccountNumberWidget();
};

inz.forms.escapeHTML = function(unsafe)
{
    return unsafe
     .replaceAll("&", "&amp;")
     .replaceAll("<", "&lt;")
     .replaceAll(">", "&gt;")
     .replaceAll("\"", "&quot;")
     .replaceAll("'", "&#039;");
};

inz.forms.unescapeHTML = function(unsafe)
{
    return unsafe
     .replaceAll("&amp;", "&")
     .replaceAll("&lt;", "<")
     .replaceAll("&gt;", ">")
     .replaceAll("&quot;", "\"")
     .replaceAll("&#039;", "'");
};

inz.forms.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

inz.forms.adjustHeight = function()
{
    var open = $(document).find('.accordion_content_container.active, .question_evidence.active');
    if(open)
    {
        inz.forms.setHeight(open, false);
    }
};

inz.forms.setHeight = function(targets, animate)
{
    var i, newHeight;

    isSwitch = false; // reset switch value

    $(targets).css({'display': '', 'height': 'auto'});

    for(i = 0; i<targets.length; i = i+1)
    {
        newHeight = targets[i].clientHeight;

        if(animate)
        {
            $(targets[i]).height(0);
        }
        $(targets[i]).height(newHeight);

        var accordParent = $(targets[i]);
        if(accordParent.find('.rcd_wrap').length>0 || accordParent.find('.process_downloads').length>0)
        {
            setTimeout(function()
            {
                accordParent.height('auto');
            }, 333);
            //Hide any open accords below selected
            if(!accordParent.hasClass('mobile_accord'))
            {
                accordParent.find('.question_evidencetrigger, .question_evidence').removeClass('active');
            }

        }


    }
};

inz.forms.getFieldObject = function(id) { return $("#"+id).data("field"); };

/**
 * Multiplicity forms
 */

inz.forms.groups = inz.forms.groups || {};
inz.forms.group_re = /g_([a-zA-Z0-9]{7,})\[(\d+)\]\.([a-zA-Z0-9]{7,})/;

/**
 * Form
 */

//inz.forms.Form = function() { };
inz.forms.Form = class {
    initCfg(cfg) {
        this.cfg = cfg;
        this.init($(cfg.c), cfg.i, cfg.t, cfg.h, cfg.a, cfg.s?"True":"False", cfg.l?"True":"False")
    }

    init($ctx, id, type, pid, action, canSubmit, requireLogin) {
        this.id = id;
        this.type = type;
        this.pid = pid;
        this.action = action;
        this.uid = this.action===null?null:this.action.substring(this.action.lastIndexOf('/')+1);
        this.$form = $ctx.find("#"+this.id).first();
        this.fields = {};  // fields by id
        this.groups = [];
        this.topFields = [];
        this.leaveWarning = false;
        this.settingValues = false;
        this.enableLogging = true;
        this.captchaField = null;
        this.submitCaptchaId = null;
        this.submitCaptchaSiteKey = null;
        this.submitDisableTimer = null;
        this.modals = {};
        this.requireLogin = requireLogin === "True";
        this.includeValuesOnSubmit = (this.cfg === undefined || this.cfg.v === undefined || this.cfg.v === false) ? false : true;

        this.enableLeaveWarning = true;
        this.canSubmit = true;
        if(canSubmit === "False") {
            this.canSubmit = false;
            this.enableLeaveWarning = false;
            this.enableLogging = false;
        }

        this.fieldMap = null;
        this.resvFieldIds = [];
        this.resvConditionFields = [];

        this.$form.data("form", this);
        this.$form.addClass("obj_form");

        this.parsleySetup();

        this.$form.find("div.form_field").each(function() {
            if($(this).attr('id'))
                $(this).attr("data-og-name", $(this).attr("id").substr(2));
        });

        this.$form.submit(this, this.submitEvent);
        this.$form.find(".reset_button").off("click");
        this.$form.find(".xreset_button").off("click");
        this.$form.find(".reset_button").click(this, this.resetEvent);
        this.$form.find(".xreset_button").click(this, this.resetEvent);
        $("#complete_later_button").click(this, this.saveEvent);
        $('body').on('form_user_logged_in', this, this.loggedInEvent);
        $('body').on('click', 'div.form_actions button.form_remove_upload', this, this.handleFileRemoveEvent);

        //var target = this.$form.find('.accordion_trigger, .question_evidencetrigger, .process_accordian_trigger');
        let target = $(".term_form").find('.accordion_trigger, .question_evidencetrigger, .process_accordian_trigger');
        this.isInAccordian = target.length>0;
        let form = this;

        let preFormActionsButton = $('.pre_form_actions button');
        if(preFormActionsButton.length) {
            $(document).keydown(function(e) {
                if(e.keyCode === 89 && e.altKey && e.ctrlKey && e.shiftKey) {
                    if($(".pre_form_validation").length)
                        inz.preFormValidation.showValidationFields();
                    else
                        $(preFormActionsButton).trigger('click');
                }
            });
        }

        if(this.requireLogin) { // change reset button text
            this.$form.find(".reset_btn").text("Cancel");
        }

        if(this.isInAccordian) {
            $(target).on('click', function() {
                setTimeout(function() { form.adjustHeight(); }, 200);
            });

            DO.Subscribe(['app:dl_afterShow'], function(e, $, element) {
                setTimeout(function() { form.adjustHeight(); }, 200);
            });

            this.disableFieldAdjust = false;
            this.$form.parsley().on('form:validate', function() {
                form.disableFieldAdjust = true;
            });


            this.$form.parsley().on('form:validated', function() {
                form.disableFieldAdjust = false;
                setTimeout(function() {
                    form.adjustHeight();
                }, 200);
            });

            this.$form.parsley().on('field:validated', function() {
                if(form.disableFieldAdjust)
                    return;
                setTimeout(function() {
                    form.adjustHeight();
                }, 200);
            });
        }

        this.$form.parsley().on('form:validate', function() {
            // adjust disabled fields...
            if(form.uLog)
                form.addLogEntry("Form", "V");
            for(let id in form.fields) {
                //if(form.fields[id].$el.is(":hidden")) {
                if (form.fields[id].isDisabled()) { // make sure non-visible fields are removed from validation
                    form.fields[id].disableValidation();
                    form.fields[id].isHidden = true;
                } else {
                    form.fields[id].enableValidation();
                    form.fields[id].isHidden = false;
                }
            }
        });

        this.$form.parsley().on('form:error', function() {
            if(form.uLog)
                form.addLogEntry("Form", "E", form.$form.find('.err-msg.filled').length);

            //form.$form.find('.banner__alert').toggleClass("hidden", true);
            $('#err_banner').toggleClass("hidden", true);
            form.$form.find(".banner_el").toggleClass('hidden', true);
            form.$form.find(".banner_err").removeClass('hidden').addClass('open');

            //$('#termination_form .banner__alert').removeClass('hidden').addClass('open');
            //var firstErrPos = $('#termination_form .err-msg').first().closest('div').offset().top - inz.modularForms.errorScrollOffset;
            //var firstErrPos = form.$form.find('.err-msg').first().closest('div').offset().top - inz.modularForms.errorScrollOffset;
            //var firstErrPos = form.$form.find('.parsley-error').first().closest('div').offset().top - inz.modularForms.errorScrollOffset;
            //var firstErrPos = form.$form.find('.err-msg.filled').first().closest('div').offset().top - inz.modularForms.errorScrollOffset;
            let firstErrPos = form.$form.find('.err-msg.filled').first().closest('.form_field').offset().top-inz.modularForms.errorScrollOffset;
            $('html, body').animate({
                'scrollTop': firstErrPos
            }, 666);
            if(form.captchaField != null)
                form.captchaField.reset();
            if(form.submitCaptchaSiteKey !== null) {
                grecaptcha.reset(form.submitCaptchaId);
            }
        });

        window.addEventListener('beforeunload', function(e) {
            if(form.enableLeaveWarning === true && form.leaveWarning) {
                e.preventDefault();
                e.returnValue = '';
            }
            if(form.uLog && sessionStorage.getItem("f_"+form.pid) !== null)
                sessionStorage.setItem("f_"+form.pid+"_ul", JSON.stringify(form.uLog));
        });

        // submit captcha
        let submitCaptcha = this.$form.find(".sub-recaptcha");
        if(submitCaptcha.length) {
            this.submitCaptchaSiteKey = submitCaptcha.attr("data-sitekey");
        }
    }

    parsleySetup() {
        this.$form.parsley().destroy();
        this.$form.parsley({
            inputs: Parsley.options.inputs+',[data-parsley-dmy-validation],div.ae_supporting_documents',
            excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], .disable_val, [disabled]",
            classHandler: function(el) { return el.$element.closest(".form_field"); },
            errorsContainer: this.errorContainer,
            errorsWrapper: '<span class="err-msg" role="alert"></span>',
            errorTemplate: '<span></span>',
            focus: 'none'
        });
    }

    setLeaveWarning(enable) {
        this.enableLeaveWarning = enable;
    }

    setMap(map) {
        this.fieldMap = map;
        for(let grp of Object.keys(this.fieldMap)) {
            for(let field of Object.keys(this.fieldMap[grp])) {
                this.resvFieldIds.push(this.fieldMap[grp][field]);
            }
        }
    }

    scrollToForm() {
        let pos = $('.term_form').offset().top;
        $('html, body').animate({'scrollTop': pos-50}, 500);
    }

    setupLogging() {
        // add fields for logging
        this.errLogEl = document.createElement("input");
        this.errLogEl.setAttribute("type", "hidden");
        this.errLogEl.setAttribute("name", "__el");
        this.errLogEl.setAttribute("value", "");
        this.$form[0].appendChild(this.errLogEl);

        this.errLog = [];
        let form = this;

        window.addEventListener('error', function(event) {
            let ts = Date.now();
            let msg = ts+","+event.filename+","+event.lineno+","+event.colno+",\""+event.message+"\"";
            form.errLog.push(msg);
        });

        this.uLogEl = document.createElement("input");
        this.uLogEl.setAttribute("type", "hidden");
        this.uLogEl.setAttribute("name", "__ul");
        this.uLogEl.setAttribute("value", "");
        this.$form[0].appendChild(this.uLogEl);

        this.uLog = [];
        this.uLogStart = Date.now();
        let log = sessionStorage.getItem("f_"+form.pid+"_ul");
        if(log)
            this.uLog = JSON.parse(log);

        this.$form.find("input[type=text], textarea, input[type=email], input[type=file]").focusin(function(event) {
            let field = form.findFieldByEl($(this));
            if(field === undefined)
                return;
            form.addLogEntry(field.name, "F");
            //console.log(ts+" Focus in: "+field.name);
        });

        this.$form.find("input[type=radio], input[type=checkbox]").on("change", function(event) {
            if(event.isTrigger !== undefined)
                return;
            let field = form.findFieldByEl($(this));
            if(field === undefined)
                return;
            form.addLogEntry(field.name, "C", field.getValue());
            //console.log(ts+" Change: "+field.name+" Val: "+field.getValue());
        });

        this.$form.find("select").on("select2:select", function(event) {
            let field = form.findFieldByEl($(this));
            if(field === undefined)
                return;
            form.addLogEntry(field.name, "C", field.getValue());
            //console.log(ts+" Change: "+field.name+" Val: "+field.getValue());
        });

        this.addLogEntry("Form", "B", Date.now());
    }

    addLogEntry(name, type, msg, ts) {
        if(ts === undefined)
            ts = Date.now()-this.uLogStart;
        if(msg === undefined)
            msg = "";
        this.uLog.push(ts+","+name+","+type+",\""+msg+"\"");
        let form_user_action_event = new Event("form_user_action", {bubbles: true});
        $('body')[0].dispatchEvent(form_user_action_event);
    }

    commitLogs() {
        if(this.errLog) {
            this.errLogEl.setAttribute("value", this.errLog.join("|"));
        }
        if(this.uLog) {
            this.addLogEntry("Form", "S");
            this.uLogEl.setAttribute("value", this.uLog.join("|"));
        }
    }

    findFieldByEl($el) {
        let field = $el.data("field");
        if(field === undefined)
            field = $el.parents(".obj_form_field").first().data("field");
        return field
    }

    restoreState(values) {
        if(values !== undefined && values !== null) {
            values = JSON.parse(values);
            this.setValues(values);
        }
        for(let id in this.fields) {
            this.fields[id].initialError();
        }
    }

    restoreLk(lk) {
        if(lk !== undefined && lk !== null) {
            let lk_val = JSON.parse(lk);
            $("#__apn").val(lk_val.k);
            if(lk_val.c !== undefined)
                $('#__cln').val(lk_val.c);
            if(lk_val.d !== undefined)
                $('#__dob').val(lk_val.d);
        }
    }

    load(config) {
        // create fields
        // inz.forms.fields
        for(let i = 0; i<config.length; i++) {
            if(config[i]["type"] in inz.forms.fields === false) {
                console.log("Unknown field type: "+config[i]["type"]+" for field: "+config[i]);
                continue;
            }

            if(this.submitCaptchaSiteKey !== null && config[i]["type"] === "CaptchaField")
                continue; // no captcha field if submit captcha enabled

            let field = Object.create(inz.forms.fields[config[i]["type"]].prototype);
            field.init(this, this.$form, config[i]);
            this.fields[config[i]["id"]] = field;
            if(field.parent === null)
                this.topFields.push(field);
            if(config[i]["type"] === "FieldGroup")
                this.groups.push(field);
            if(config[i]["type"] === "CaptchaField")
                this.captchaField = field;
        }

        // all fields now added, do setup and add conditions which can now resolve dependencies.
        for(let id in this.fields) {
            if(!this.fields[id].inGroupTmpl && this.fields[id].config.conditions.length !== 0)
                this.fields[id].addConditions(this.fields[id].config.conditions);
        }

        // for (var i=0;i < config.length; i++)
        // {
        //     if (config[i].conditions.length !== 0)
        //         this.fields[config[i].id].addConditions(config[i].conditions);
        // }

        //for (var i=0;i < config.length; i++)
        for(let id in this.fields) {
            this.fields[id].setup(true);
        }

        for(let i = 0; i<this.groups.length; i++)
            this.groups[i].setupGroup();

        this.$form.parsley()._refreshFields(); // incase of field changes

        if (this.requireLogin === false) {    // login forms get state from draft loads
            // session?
            let values = sessionStorage.getItem("f_"+form.pid);
            //console.log(this);
            this.restoreState(values);
            // APN?
            let lk = sessionStorage.getItem("f_"+form.pid+"_lk");
            this.restoreLk(lk);
        }

        // set action and enable submit
        if(this.action)
            this.$form.attr('action', this.action);
        this.$form.find(':input[type="submit"]').prop('disabled', false);
        this.$form.find('a.xreset_button').prop('disabled', false);
        $('.form_pre_load_hide').show();
        //this.$form.show();

        if(this.enableLogging)
            this.setupLogging();

        this.leaveWarning = false;
        let form_loaded_event = new Event("form_loaded", {bubbles: true});
        $('body')[0].dispatchEvent(form_loaded_event);
    }

    getModal(modal_name) {
        if(!(modal_name in this.modals)) {
            // create modal
            this.modals[modal_name] = Object.create(inz.forms.modals[modal_name].prototype);
            this.modals[modal_name].init(this);
        }
        return this.modals[modal_name];
    }

    setupCaptcha() {
        //console.log("type of: "+(typeof grecaptcha));
        if((typeof grecaptcha === 'undefined') || (typeof grecaptcha.render === 'undefined')) {
            return;
        }

        for(let id in this.fields) {
            if(this.fields[id] instanceof inz.forms.fields.CaptchaField)
                this.fields[id].setupCaptcha();
        }

        if(this.submitCaptchaSiteKey !== null) {
            let subCaptcha = this.$form.find(".sub-recaptcha");
            this.submitCaptchaId = grecaptcha.render(subCaptcha.get(0), {
                'sitekey': this.submitCaptchaSiteKey,
                'callback': this.submitCaptchaCallback,
                'expired-callback': this.submitCaptchaExpired,
            });
        }

        if(this.isInAccordian)
            this.adjustHeight();
    }

    submitCaptchaCallback(token) {
        //console.log("Submit token: "+token);
        //inz.forms.captchaField.$cc.val('Captcha completed');
        //inz.forms.captchaField.$container.find('.captcha_wrap .err-msg').remove();
        if(window.form && window.form.uLog)
            window.form.addLogEntry("Captcha", "C", "Completed");

        window.form.submit();
    }

    submitCaptchaExpired() {
        //console.log("submit Captcha expired");
        //inz.forms.captchaField.$cc.val('');
        if(window.form && window.form.uLog)
            window.form.addLogEntry("Captcha", "C", "Expired");
    }

    getValues(formData) {
        let data = {};
        for(let id in this.fields) {
            let value = this.fields[id].getValue();
            data[id] = [value, this.fields[id].isHidden, this.fields[id].state];
            if(formData !== undefined && value !==undefined && this.fields[id].useGetOnSubmit()) {
                if($.type(value) === "array") {
                    if(value.length !== 0) {
                        //for(let v in value) {
                        for(let i = 0; i<value.length; i++) {
                            if(i === 0)
                                formData.set(this.fields[id].getName(), value[i]);
                            else
                                formData.append(this.fields[id].getName(), value[i]);
                        }
                    } else {
                        formData.set(this.fields[id].getName(), value);
                    }
                } else {
                    formData.set(this.fields[id].getName(), value);
                }
                /*if ($.type(value) !== "string")
                    formData.set(id, JSON.stringify(value));
                else
                    formData.set(id, value);*/
            }
        }
        return data;
    }

    setValues(data, processConditions) {
        this.settingValues = true;
        for(let i = 0; i<this.groups.length; i++) {
            let value = data[this.groups[i].id];
            if(value !== undefined) {
                if(value.length >= 2) {
                    if(value[1] === true) {
                        this.groups[i].hide(true);
                    } else {
                        this.groups[i].show(true);
                        this.groups[i].setValue(value[0]);
                    }
                    if (value.length === 3) {  // have state
                        this.groups[i].setState(value[2]);
                    }
                }
            }
        }

        for(let id in this.fields) {
            if(this.fields[id] instanceof inz.forms.fields.FieldGroup)
                continue;

            let value = data[id];
            if(value !== undefined) {
                // Don't re-fill the form for old sessionStorage formats - it's just busted
                if(value.length >= 2) {
                    if(value[1] === true) {
                        this.fields[id].hide(true);
                    } else {
                        this.fields[id].show(true);
                        this.fields[id].setValue(value[0]);
                    }
                    if (value.length === 3) {  // have state
                        this.fields[id].setState(value[2]);
                    }
                }
            }
        }
        this.settingValues = false;
        this.$form.parsley()._refreshFields();
        if (processConditions !== undefined && processConditions === true) {
            // process conditions after setting all values, used for SMC
            for(let id in this.fields) {
                if(this.fields[id] instanceof inz.forms.fields.FieldGroup)
                    continue;

                let value = data[id];
                if(value !== undefined) {
                    this.fields[id].processConditions(undefined, undefined, true);  // no default displays
                }
            }
        }
    }

    resetForm() {
        for(let id in this.fields) {
            this.fields[id].reset();
        }
        this.leaveWarning = false;
        // clear session storage
        sessionStorage.removeItem("f_"+form.pid);
        sessionStorage.removeItem("f_"+form.pid+"_ul");
        sessionStorage.removeItem("f_"+form.pid+"_lk");

        inz.forms.message.hideMessage();
        this.adjustHeight();
    }

    addField(field) {
        this.fields[field.id] = field;
    }

    removeField(field) {
        delete this.fields[field.id];
    }

    getField(id) {
        if(!(id in this.fields)) {
            // One possibility is that this is coming from a Field inside a FieldGroup, and it's looking for a
            // Field outside the Fieldgroup in the general form:
            let fieldgroup_offset = id.search(/__\d/);
            if(fieldgroup_offset>0) {
                let testid = id.substring(0, fieldgroup_offset);
                if(!(testid in this.fields)) {
                    console.log("Unknown field: "+id);
                    return null;
                } else {
                    // The id is the id for a Field in the general Form - return it.
                    id = testid;
                }
            } else {
                // Could be an actual field name coming back from the server side
                for(let x in this.fields) {
                    if(id === this.fields[x].name) {
                        return this.fields[x];
                    }
                }
                // Fallthrough is failure
                console.log("Unknown field: "+id);
                return null;
            }
        }
        return this.fields[id];
    }

    errorContainer(el) {
        let field = el.$element.parents(".obj_form_container").data("field");
        if(field !== undefined)
            return field.getErrorContainer(el);

        let e = el.$element.parents(".field-col");
        if(e.length === 0)
            return el.$element.parents(".form_field");

        return e;
    }

    changeEvent(event) {
        let field = event.data;
        //console.log("ChangeEvent: "+field.id);
        field.form.leaveWarning = true;

        if(inz.login.session !== undefined && !field.form.settingValues)
            inz.login.session.processEvent(inz.login.Session.Events.Active);

        if(field.dependants.length === 0)
            return;

        field.form.processDependants(field);
    }

    processDependants(field, no_default, no_reset) {
        if(field.dependants.length === 0)
            return;

        let value = field.getValue(true);  // note true argument - want real value from ouid style fields

        let did_show = false;
        for(let i = 0; i<field.dependants.length; i++) {
            if(!field.form.settingValues) {// Change for BERI form issue
                if(field.dependants[i].processConditions(field, value, no_default, no_reset)) {
                    did_show = true;
                }
                field.dependants[i].depChanged(field, value);
            }
        }
        if(did_show) {
            field.form.$form.parsley()._refreshFields();
            console.debug("RFF");
        }
    }

    loggedInEvent(event) {
        let form = event.data;
        event.preventDefault();
        event.stopPropagation();
        let dfd = $.Deferred();
        form.loadDraftSubmissions()
              .done(function(data) {
                  // TODO: messages regarding success/failure of data load
                  if('sess' in data) {
                      inz.login.session.sessionDataUpdate(data.sess);
                  }
                  form.$form.append('<a style="display:none;" href="#" id="save_and_exit" class="btn btn__primary">Save and Exit</a>');
                  let has_subs = data.submissions;
                  if(typeof (has_subs) !== "undefined") {
                      has_subs = has_subs.length;
                  }
                  if(has_subs) {
                      const UNNAMED_DRAFT_LABEL = 'Unnamed Draft';

                      function onEditDraft(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          let draft_id = $(this).closest('tr').data('draftId');
                          if($('#__si').length) {
                              $('#__si').val(draft_id);
                          } else {
                              form.$form.append($('<input>', {type: 'hidden', id: '__si', name: '__si', value: draft_id}));
                          }
                          form.loadState().done(function(success) {
                              inz.forms.message.hideMessage();
                              form.adjustHeight();
                              inz.login.session.active(false);
                              //inz.login.session.init();
                              $('.login_confirmation').show();
                              $('#save_and_exit_initial').click(function(event) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  $('#save_and_exit').click();
                              });
                              $('.pre_form_actions .btn__primary').click();
                              form.leaveWarning = false; // just loaded no warning needed
                              inz.login.session.processEvent(inz.login.Session.Events.Loaded, draft_id);
                          });
                      }

                      /**
                       * Temporarily renders the specified content over the existing contents of the specified table row
                       * @param tableRow the table row element
                       * @param content the content to render over the specified row
                       * @param colspan int value indicating the number of columns to span (defaults to 1)
                       */
                      function applyOverlayToRow(tableRow, content, colspan) {
                          let tr = $(tableRow);

                          // first, remove any other overlays in the same table
                          tr.closest('table').find('tr.overlaid').each(function() {
                              removeOverlayFromRow(this);
                          });

                          // note the current height of the row, then move the current content into a data value
                          const minHeight = tr.innerHeight();

                          const headerCells = tr.closest('table').find('tr:first-of-type th').get();
                          const columnWidths = headerCells.map(e=>e.getBoundingClientRect().width);
                          for(let n = 0; n<columnWidths.length; n++) {
                              headerCells[n].style.width = columnWidths[n]+'px';
                          }

                          tr.data('originalContent', tr.children().detach());
                          tr.append($('<td>', {colspan: colspan}).append($('<div>', {style: `min-height: ${minHeight}px`, 'class': 'form_modal_overlay', html: content})));
                          tr.addClass('overlaid');

                      }

                      /**
                       * Applies a standardised overlay to the specified row that splits the content into a prompt and a group of action buttons.
                       * @param tableRow the table row element
                       * @param promptContent the content to render as the prompt/status/input widget
                       * @param actionButtons the buttons to render as the overlay actions
                       * @param colspan int value indicating the number of columns to span (defaults to 1)
                       */
                      function applyPromptActionsOverlayToRow(tableRow, promptContent, actionButtons, colspan) {
                          let content = $('<div>');
                          content.append($('<div>', {'class': 'form_modal_prompt', html: $(promptContent)}));
                          content.append($('<div>', {'class': 'form_modal_actions', html: $(actionButtons)}));
                          applyOverlayToRow(tableRow, content.children(), colspan);
                      }

                      /**
                       * Applies a standardised PromptActionsOverlay to the specified row with a colspan of 3.
                       * @param tableRow the table row element
                       * @param promptContent the content to render as the prompt/status/input widget
                       * @param actionButtons the buttons to render as the overlay actions
                       */
                      function applyExistingDraftFormOverlay(tableRow, promptContent, actionButtons) {
                          applyPromptActionsOverlayToRow(tableRow, promptContent, actionButtons, 3);
                      }

                      /**
                       * Removes the overlay (previously created by applyOverlayToRow()) from the specified table row
                       * @param tableRow
                       */
                      function removeOverlayFromRow(tableRow) {
                          let tr = $(tableRow);
                          tr.closest('table').find('tr:first-of-type th').css('width', '');
                          tr.empty();
                          tr.append(tr.data('originalContent'));
                          tr.removeClass('overlaid');
                      }

                      /**
                       * Event handler that is triggered when the user clicks on a Draft's 'Delete' action
                       * @param event
                       */
                      function onDeleteDraft(event) {
                          inz.login.session.active(false);
                          let tr = $(this).closest('tr');
                          let statusContent = $('<div>');
                          statusContent.append($('<h4>', {'class': 'draft_title', text: tr.data('draft')['label'] ? tr.data('draft')['label'] : UNNAMED_DRAFT_LABEL}));
                          statusContent.append($('<p>', {text: 'Are you sure you want to delete this draft?'}));
                          applyExistingDraftFormOverlay(
                              tr,
                              statusContent.children(),
                              $('<div>')
                              .append($('<button>', {'class': 'btn btn__primary btn__inline_right confirm_delete', type: 'button', text: 'Delete', click: onDeleteDraftConfirmation}))
                              .append($('<button>', {'class': 'btn btn__secondary btn__inline_right', type: 'button', text: 'Cancel', click: function() {removeOverlayFromRow(tr);}}))
                              .children()
                          )
                      }

                      /**
                       * Event handler that is triggered when the user confirms they wish to proceed with a Draft's 'Delete' action
                       * @param event
                       */
                      function onDeleteDraftConfirmation(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          inz.login.session.active(false);
                          let tr = $(this).closest('tr');
                          let draftId = tr.data('draftId');
                          let formData = new FormData();
                          formData.append('submission_id', draftId);
                          $.ajax({
                              type: "POST",
                              //url: form.action.replace(/(app_)?submit/, 'remove'),
                              url: "/@@acc_sf_remove/"+form.uid,
                              cache: false,
                              data: formData,
                              processData: false,
                              contentType: false,
                          })
                           .done(function(data, textStatus, xhr) {
                               // remove the row from the table as the draft has been deleted on the server
                               tr.remove();
                           })
                           .fail(function(xhr, textStatus, errorThrown) {
                               // something went wrong deleting the draft on the server. display and log an error message and then remove the 'Delete' button on the overlay
                               console.error(`Error deleting draft ${draftId}: status: ${xhr.status} / textStatus: ${textStatus} / errorThrown: ${errorThrown}`);

                               let p = tr.find('.form_modal_prompt p');
                               p.text('Unable to delete this draft. Please try again later.')
                               p.addClass('form_modal_error');
                               tr.find('.form_modal_actions button.confirm_delete').attr('disabled', true);
                           });
                      }

                      /**
                       * Event handler that is triggered when the user clicks on a Draft's 'Rename' action
                       * @param event
                       */
                      function onRenameDraft(event) {
                          let tr = $(this).closest('tr');
                          inz.login.session.active(false);

                          function renameOnEnter(e) {
                              if(e.keyCode === 13) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  tr.find('button.confirm_rename').click();
                              }
                          }

                          let input = $('<input>', {type: 'text', 'keydown': renameOnEnter, 'maxlength': 40}).attr('placeholder', 'Enter new draft name');
                          let statusContent = $('<div>');
                          statusContent.append($('<h4>', {'class': 'draft_title', text: tr.data('draft')['label'] ? tr.data('draft')['label'] : UNNAMED_DRAFT_LABEL}));
                          statusContent.append(input);
                          applyExistingDraftFormOverlay(
                              tr,
                              statusContent.children(),
                              $('<div>')
                              .append($('<button>', {'class': 'btn btn__primary btn__inline_right confirm_rename', type: 'button', text: 'Rename', click: onRenameDraftConfirmation}))
                              .append($('<button>', {'class': 'btn btn__secondary btn__inline_right', type: 'button', text: 'Cancel', click: function() {removeOverlayFromRow(tr);}}))
                              .children()
                          )
                          input.focus();
                      }

                      /**
                       * Event handler that is triggered when the user confirms they wish to proceed with a Draft's 'Rename' action
                       * @param event
                       */
                      function onRenameDraftConfirmation(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          inz.login.session.active(false);

                          let tr = $(this).closest('tr');
                          let draftId = tr.data('draftId');
                          let newLabel = tr.find('.form_modal_prompt input').val().trim();
                          let formData = new FormData();
                          formData.append('submission_id', draftId);
                          formData.append('label', newLabel);
                          $.ajax({
                              type: "POST",
                              //url: form.action.replace(/(app_)?submit/, 'rename'),
                              url: "/@@acc_sf_rename/"+form.uid,
                              cache: false,
                              data: formData,
                              processData: false,
                              contentType: false,
                          })
                           .done(function(data, textStatus, xhr) {
                               // remove the overlay from the row as the draft has been renamed on the server
                               removeOverlayFromRow(tr);
                               tr.find('td.label button').text(newLabel ? newLabel : UNNAMED_DRAFT_LABEL);
                               tr.data('draft').label = newLabel;
                           })
                           .fail(function(xhr, textStatus, errorThrown) {
                               // something went wrong renaming the draft on the server. display and log an error message and then remove the 'Rename' button on the overlay
                               console.error(`Error renaming draft ${draftId}: status: ${xhr.status} / textStatus: ${textStatus} / errorThrown: ${errorThrown}`);

                               let formModalPrompt = tr.find('.form_modal_prompt');
                               formModalPrompt.find('p').remove();
                               formModalPrompt.append($('<p>', {'class': 'form_modal_error', text: 'Unable to rename this draft. Please try again later.'}));
                               tr.find('.form_modal_actions button.confirm_rename, .form_modal_prompt input').attr('disabled', true);
                           });

                      }


                      let message = $('<div></div>');
                      message.append("<p>Select 'Open' to view or edit a draft. If you want to start a new draft, select 'Create New'.</p>");

                      let submissionTable = $('<table class="savedDrafts"><thead><tr><th>Name</th><th class="created">Created</th><th>Options</th></tr></thead><tbody></tbody></table>');
                      let tbody = submissionTable.find('tbody');
                      for(const draft of data.submissions) {
                          const createdDate = new Date(draft.created*1000);
                          const createdDateHtml = `${createdDate.getDate()} ${inz.forms.months[createdDate.getMonth()]} ${createdDate.getFullYear()} <span>${createdDate.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true})}</span>`;
                          let tr = $('<tr>', {'data-draft-id': draft.submission_id});
                          tr.data('draft', draft);
                          tr.append(
                              $('<td>', {'class': 'label'}).append(
                                  $('<button>', {type: 'button', 'class': 'btn btn__tertiary edit_draft', text: draft.label ? draft.label : UNNAMED_DRAFT_LABEL, click: onEditDraft}).append(
                                      $('<p>', {'class': 'inline_created', html: createdDateHtml})
                                  )
                              )
                          );
                          tr.append($('<td>', {'class': 'created', html: createdDateHtml}));
                          tr.append(
                              $('<td>', {'class': 'actions'})
                              .append($('<div>')
                                  .append($('<button>', {type: 'button', 'class': 'btn btn__primary edit_draft', text: 'Open', click: onEditDraft}))
                                  .append($('<button>', {type: 'button', 'class': 'btn btn__secondary', text: 'Rename', click: onRenameDraft}))
                                  .append($('<button>', {type: 'button', 'class': 'btn btn__secondary', text: 'Delete', click: onDeleteDraft}))
                              )
                          );
                          tbody.append(tr);
                      }
                      submissionTable.append(tbody);
                      message.append(submissionTable);

                      message.append('<input name="create_new_submission" class="btn btn__blockxs btn__primary center-block" type="submit" value="Create New" />');
                      message.append('<input name="submission_log_out" class="btn btn__blockxs btn__secondary center-block" type="submit" value="Log Out" />');
                      inz.forms.message.showMessage(
                          message,
                          'Existing drafts',
                          null,
                          false
                      );
                      $('.modal_forms_message input[name="create_new_submission"]').on('click', function(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          $(this).attr("disabled", "disabled");
                          $('.modal_forms_message input[name="submission_log_out"]').attr("disabled", "disabled");

                          form.$form.find('#save_and_exit').before('<fieldset class="form_field"><input class="input" style="display:none;" maxlength="40" type="text" id="__submission_label" name="__submission_label" placeholder="Enter a name to save this daft" /></fieldset>');
                          $('#save_and_exit_initial').click(function(event) {
                              event.preventDefault();
                              event.stopPropagation();
                              $('div.actions').hide();
                              form.setAllowSubmit(false);
                              $('#__submission_label').show();
                              $('#save_and_exit').show();
                              form.adjustHeight();
                          });
                          /* Reset form, clear session storages and fields for a blank slate */
                          form.resetForm();
                          $('.login_confirmation').show();
                          inz.login.session.processEvent(inz.login.Session.Events.New);
                          form.scrollToForm();
                      });
                      $('.modal_forms_message input[name="submission_log_out"]').on('click', function(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          $(this).attr("disabled", "disabled");
                          $('.modal_forms_message input[name="create_new_submission"]').attr("disabled", "disabled");

                          inz.forms.message.hideMessage();
                          inz.forms.message.showMessage('', 'Logging out', '', true);
                          inz.login.session.processEvent(inz.login.Session.Events.LogOut);
                      });
                      inz.login.session.processEvent(inz.login.Session.Events.Drafts);
                  } else {
                      inz.forms.message.hideMessage();
                      form.adjustHeight();
                      form.resetForm();
                      //inz.login.session.init();
                      $('.login_confirmation').show();
                      form.$form.find('#save_and_exit').before('<fieldset class="form_field"><input class="input" style="display:none;" maxlength="40" type="text" id="__submission_label" name="__submission_label" placeholder="Enter a name to save this draft" /></fieldset>');
                      $('#save_and_exit_initial').click(function(event) {
                          event.preventDefault();
                          event.stopPropagation();
                          $('div.actions').hide();
                          form.setAllowSubmit(false);
                          $('#__submission_label').show();
                          $('#save_and_exit').show();
                          form.adjustHeight();
                      });
                      inz.login.session.processEvent(inz.login.Session.Events.New);
                      form.scrollToForm();
                  }
              });
    }

    loadDraftSubmissions() {
        let dfd = $.Deferred();
        $.ajax({
            type: "POST",
            //url: this.action.replace(/(app_)?submit/, 'list'),
            url: "/@@acc_sf_list/"+this.uid,
            cache: false,
            processData: false,
            contentType: false,
        }).done(function(data, textStatus, xhr) {
            return dfd.resolve(data);
        }).fail(function(xhr, textStatus, errorThrown) {
            console.log("LDS - Result: "+xhr.status+" / "+textStatus+" / "+errorThrown);
            return dfd.resolve({'submissions': []});
        });
        return dfd.promise();
    }

    saveEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        let form_user_save_event = new Event("form_user_save", {bubbles: true});
        $('body')[0].dispatchEvent(form_user_save_event);
        $('#complete_later_button').attr('disabled', 'disabled');
        inz.forms.message.showMessage('<p>Your data is being saved for later</p>', 'Saving Progress', 'Data Saving', true);
        form.saveState().done(function() {
            inz.forms.message.hideMessage();
            $('#complete_later_button').removeAttr('disabled');
            form.adjustHeight();
        });
        return false;
    }

    saveState() {
        let dfd = $.Deferred();
        let form = this;
        // store settings
        let values = form.getValues();
        for(let id in this.fields) {
            if(this.fields[id] instanceof inz.forms.fields.Upload) {
                // We don't need to save the state in the session as any files will be saved as
                // first-class members by the backend
                // remove files leave state
                values[id][0] = "[]"; // no value
                //delete values[id];
            }
        }
        let formData = new FormData();
        sessionStorage.setItem("f_"+this.pid, JSON.stringify(values));
        sessionStorage.setItem("f_"+this.pid+"_ul", JSON.stringify(this.uLog));
        if($('#__apn').length) {
            var lk = {"k": $('#__apn').val()};
            if($('#__cln').length) {
                lk.c = $('#__cln').val();
            }
            if($('#__dob').length) {
                lk.d = $('#__dob').val();
            }
            sessionStorage.setItem("f_"+this.pid+"_lk", JSON.stringify(lk));
        }
        let userfiles = form.$form.find(':input[type="file"]');
        userfiles.each(function(i) {
            if(this.files.length) {
                for(let i = 0; i<this.files.length; i++) {
                    formData.append($(this).attr('name'), this.files[i]);
                }
            } else {
                if(!$(this).is(":hidden")) {
                    formData.append($(this).attr('name'), null);
                }
            }
        });
        let transientfiles = form.$form.find('input.uploadField');
        transientfiles.each(function(i) {
            formData.append($(this).attr('name'), $(this).val());
        });
        formData.append('__session_values', JSON.stringify(values))
        if(lk !== undefined && lk !== null) {
            formData.append('__session_lk', JSON.stringify(lk))
        }
        formData.append('__session_incomplete', 1);
        if($('#__si').length) {
            formData.append('submission_id', $('#__si').val());
        }
        formData.append('__ul', JSON.stringify(this.uLog));
        $('input[name="__retain_files:list"]').each(function(i) {
            formData.append('__retain_files:list', $(this).val());
        });
        if($('#__submission_label').length) {
            formData.append('__submission_label', $('#__submission_label').val());
        }
        $.ajax({
            type: "POST",
            //url: this.action.replace(/(app_)?submit/, 'save'),
            url: "/@@acc_sf_save/"+this.uid,
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
        }).done(function(data, textStatus, xhr) {
            console.log("SS: "+xhr.status+" "+data.result);
            if(data.result === "success") {
                if($('#__si').length) {
                    $('#__si').val(data.submission_id);
                } else {
                    form.$form.append('<input type="hidden" id="__si" name="__si" value="'+data.submission_id+'" />');
                }
                $('div.form_actions.uploaded_file').remove();
                for(let i = 0; i<data.submission_data.files.length; i++) {
                    form.setupUploadedFileUi(data.submission_data.files[i]);
                }
                form.leaveWarning = false; // we just saved it all
            } else if(data.result === "failed") {

                form.showErrors(data.errors);
                if(form.captchaField != null)
                    form.captchaField.reset();
                if(form.submitCaptchaSiteKey !== null) {
                    grecaptcha.reset(form.submitCaptchaId);
                }
                form.adjustHeight();
                return dfd.resolve(false);
            }
            return dfd.resolve(true);
        }).fail(function(xhr, textStatus, errorThrown) {
            console.log("SS - Result: "+xhr.status+" / "+textStatus+" / "+errorThrown);
            // show failed banner
            $('#err_banner').toggleClass("hidden", false);
            $('body').scrollTo('#err_banner');
            return dfd.resolve(false);
            //form.$form.find(':input[type="submit"]').prop('disabled', false).val("Submit form");
            //form.$form.find('a.reset_btn').toggleClass('disabled', false);
            //if (form.captchaField != null)
            //form.captchaField.reset();
            //if (form.submitCaptchaSiteKey !== null) {
        });
        return dfd.promise();
    }

    loadState() {
        let formData = new FormData();
        if($('#__si').length) {
            formData.append('submission_id', $('#__si').val());
        }
        let dfd = $.Deferred();
        let _this = this;
        $.ajax({
            type: "POST",
            //url: this.action.replace(/(app_)?submit/, 'load'),
            url: "/@@acc_sf_load/"+this.uid,
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
        }).done(function(data, textStatus, xhr) {
            //console.log("Load result: "+xhr.status);
            if(data.submission_id) {
                if($('#__si').length) {
                    $('#__si').val(data.submission_id);
                } else {
                    this.$form.append('<input type="hidden" id="__si" name="__si" value="'+data.submission_id+'" />');
                }
            }
            if(data.__session_values) {
                _this.restoreState(data.__session_values);
            }
            if(data.__session_lk) {
                _this.restoreLk(data.__session_lk);
            }
            if(data.files) {
                /*for (var i=0; i<data.files.length; i++) {
                    window.form.setupUploadedFileUi(data.files[i]);
                }*/
                let field = null;
                for(let fileinfo of data.files) {
                    if(fileinfo.fieldhash !== field) {
                        if(fileinfo.fieldhash.startsWith("g_")) {
                            field = null;
                            let match = fileinfo.fieldhash.match(inz.forms.group_re);
                            if(match !== null) {
                                let nam = `${match[3]}__${match[2]}`;
                                field = _this.getField(nam);
                            }
                        } else {
                            field = _this.getField(fileinfo.fieldhash);
                        }
                    }
                    if(field !== undefined && field !== null && field.addSavedFile !== undefined) {
                        field.addSavedFile(fileinfo);
                    } else {
                        _this.setupUploadedFileUi(fileinfo);
                    }
                }
            }
            //window.form.$form.parsley()._refreshFields();
            return dfd.resolve(true);
        }).fail(function(xhr, textStatus, errorThrown) {
            console.log("LS - Result: "+xhr.status+" / "+textStatus+" / "+errorThrown);
            // show failed banner
            return dfd.resolve(false);
            //form.$form.find(':input[type="submit"]').prop('disabled', false).val("Submit form");
            //form.$form.find('a.reset_btn').toggleClass('disabled', false);
            //if (form.captchaField != null)
            //form.captchaField.reset();
            //if (form.submitCaptchaSiteKey !== null) {
        });
        return dfd.promise();
    }

    setupUploadedFileUi(fileinfo) {
        let f_input = $('input[name="'+fileinfo.fieldhash+'"]');
        if(f_input.length) {
            f_input.after('<input type="hidden" class="retain_file_marker" id="file_'+fileinfo.unique_id+'" name="__retain_files:list" value="'+fileinfo.unique_id+'" />');
            f_input.after('<div class="form_actions uploaded_file"><p>Existing saved file: '+fileinfo.filename+' (size '+fileinfo.size+')<br /><button type="button" data-file-fingerprint="'+fileinfo.unique_id+'" class="form_remove_item form_remove_upload" style="display: block;">Remove file</button></p><hr /></div>');
            f_input.val(null);
            //if (f_input.data().parsleyMaxNumberFiles) {
            //    f_input.data().parsleyMaxNumberFiles -= 1;
            //    f_input.attr('data-parsley-max-number-files', f_input.data().parsleyMaxNumberFiles);
            //}
            this.checkFileInputAvailability(f_input);
        }
    }

    handleFileRemoveEvent(event) {
        let form = event.data;
        event.preventDefault();
        event.stopPropagation();
        let button = $(this);
        let file_id = button.data().fileFingerprint;
        let related_input = $('input#file_'+file_id).siblings('input[type=file]');
        button.parent('p').parent('div.uploaded_file').remove();
        form.removeSavedFile(file_id);
        form.checkFileInputAvailability(related_input);
    }

    removeSavedFile(file_id) {
        $('input#file_'+file_id).remove();
    }

    checkFileInputAvailability(input_element) {
        if($(input_element).data().parsleyMaxNumberFiles) {
            /* This input allows a specified number of files */
            if($(input_element).siblings('.retain_file_marker').length>=$(input_element).data().parsleyMaxNumberFiles) {
                $(input_element).hide();
            } else {
                $(input_element).show();
            }
        } else {
            /* No specific limit means only a single file in our case */
            if($(input_element).siblings('.retain_file_marker').length) {
                $(input_element).hide();
            } else {
                $(input_element).show();
            }
        }
    }

    focusOut(event) {
    }

    submitEvent(event) {
        let form = event.data;
        event.preventDefault();
        event.stopPropagation();

        if(form.canSubmit === false)
            return false;

        //form.$form.find(':input[type="submit"]').prop('disabled', true).val("Submitting");
        //form.$form.find('a.reset_btn').toggleClass('disabled', true);
        form.setAllowSubmit(false, "Submitting");

        if(form.submitCaptchaSiteKey !== null) {
            form.submitDisableTimer = setTimeout(function() {
                form.setAllowSubmit(true);
                //form.$form.find(':input[type="submit"]').prop('disabled', false).val("Submit form");
                //form.$form.find('a.reset_btn').toggleClass('disabled', false);
                form.submitDisableTimer = null;
            }, 70000);
            grecaptcha.execute(form.submitCaptchaId);
            return false;
        }

        form.submit();
        return false;
    }

    setAllowSubmit(allow_submit, label) {
        // TODO: why using form instead of this?
        allow_submit = !allow_submit;
        if(label !== undefined) {
            form.$form.find(':input[type="submit"]').prop('disabled', allow_submit).val(label);
        } else {
            form.$form.find(':input[type="submit"]').prop('disabled', allow_submit).val("Submit form");
        }
        form.$form.find('a.reset_btn').toggleClass('disabled', allow_submit);
        form.$form.find('#save_and_exit_initial').toggleClass('disabled', allow_submit);
    }

    submit() {
        let disabled = [];
        let form = this;

        if(form.submitDisableTimer !== null) {
            clearTimeout(form.submitDisableTimer);
            form.submitDisableTimer = null;
        }

        for(let id in this.fields) {
            if(this.fields[id].isDisabled()) {
                disabled.push(this.fields[id].name);
            }
        }
        this.$form.find('#__disabled').val(disabled.join('|'));

        this.commitLogs();
        this.leaveWarning = false;

        // form values
        let formData = new FormData(this.$form[0]);

        // store settings
        let values = this.getValues(formData);  // note this updates formData if the field has useGetOnSubmit
        sessionStorage.setItem("f_"+this.pid, JSON.stringify(values));
        sessionStorage.setItem("f_"+this.pid+"_ul", JSON.stringify(this.uLog));
        if($('#__apn').length) {
            var lk = {"k": $('#__apn').val()};
            if($('#__cln').length) {
                lk.c = $('#__cln').val();
            }
            if($('#__dob').length) {
                lk.d = $('#__dob').val();
            }
            sessionStorage.setItem("f_"+this.pid+"_lk", JSON.stringify(lk));
        }
        //this.$form.find(':input[type="submit"]').prop('disabled', true).val("Submitting");
        //this.$form.find('a.reset_btn').toggleClass('disabled', true);
        this.setAllowSubmit(false, "Submitting");

        $('#err_banner').toggleClass("hidden", true);
        form.$form.find(".banner_el").toggleClass('hidden', true);
        form.$form.find(".banner_err").toggleClass('hidden', true);

        if ($('#__si').length) {
            formData.append('submission_id', $('#__si').val());
        }
        if(this.includeValuesOnSubmit)
            formData.append('__v', JSON.stringify(values))
        if (lk !== undefined && lk !== null) {
            formData.append('__lk', JSON.stringify(lk))
        }

        $.ajax({
            type: "POST",
            url: this.action,
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
        }).done(function(data, textStatus, xhr) {
            //form.$form.find(':input[type="submit"]').prop('disabled', false).val("Submit form");
            //form.$form.find('a.reset_btn').toggleClass('disabled', false);
            form.setAllowSubmit(true);
            console.log("S - Result: "+xhr.status+" "+data.result);

            if(data.result === "success") {
                sessionStorage.removeItem("f_"+form.pid);
                sessionStorage.removeItem("f_"+form.pid+"_ul");
                sessionStorage.removeItem("f_"+form.pid+"_lk");
                if(data.url !== undefined) {
                    window.location = data.url;
                    return;
                }

                if (data.header !== undefined) {
                    $('#success_page').find("h2.banner_title").html(data.header);
                }
                $('#success_page').find("div.banner_text").html(data.msg);

                if (data.style !== undefined) {
                    if (data.style === "alert") {
                        $('#success-banner').removeClass("banner__message");
                        $('#success-banner').addClass("banner__alert");
                        $('#success-banner').find("span.banner_icon").attr("class", "banner_icon banner_icon__alert");
                    } else {
                        $('#success-banner').removeClass("banner__alert");
                        $('#success-banner').addClass("banner__message");
                        $('#success-banner').find("span.banner_icon").attr("class", "banner_icon banner_icon__comms");
                    }
                }

                $('#submit_page').hide();
                $('#success_page').show();
                $('body').scrollTo('#success_page');
                $('.form_pre_load_hide').hide();
            } else if(data.result === "failed") {
                form.showErrors(data.errors, data.el);
                if(form.captchaField != null)
                    form.captchaField.reset();
                if(form.submitCaptchaSiteKey !== null) {
                    grecaptcha.reset(form.submitCaptchaId);
                }
                form.adjustHeight();
            }
        }).fail(function(xhr, textStatus, errorThrown) {
            console.log("S - Result: "+xhr.status+" / "+textStatus+" / "+errorThrown);
            // report failure
            let rep_data = {"s": xhr.status, "ts": textStatus, "err": errorThrown, "rs": xhr.readyState, "hdr": xhr.getAllResponseHeaders()};
            let rs = false;
            if(xhr.status === 403) {
                // check for red shield support Id
                let match = xhr.responseText.match(/Support ID (\d+)/);
                if(match !== null && match.length === 2) {
                    rep_data["rs_id"] = match[1];
                    rs = true;
                }
            }
            form.sfer(rep_data);
            // show failed banner
            if(rs) {
                $('#err_banner').find(".s_error").hide();
                $('#err_banner').find(".r_error").show();
            } else {
                $('#err_banner').find(".s_error").show();
                $('#err_banner').find(".r_error").hide();
            }
            $('#err_banner').toggleClass("hidden", false);
            $('body').scrollTo('#err_banner');
            form.setAllowSubmit(true);
            //form.$form.find(':input[type="submit"]').prop('disabled', false).val("Submit form");
            //form.$form.find('a.reset_btn').toggleClass('disabled', false);
            if(form.captchaField != null)
                form.captchaField.reset();
            if(form.submitCaptchaSiteKey !== null) {
                grecaptcha.reset(form.submitCaptchaId);
            }
            form.adjustHeight();
        });
    }

    sfer(repData) {
        if(repData === undefined || repData == null)
            repData = {};

        repData['_action'] = this.action;
        repData['_id'] = window.location.href.substring(window.location.href.lastIndexOf('/')+1);
        repData['_pid'] = this.pid;
        repData['_clog'] = console.lg;
        repData['_ulog'] = this.uLog;
        repData['_elog'] = this.errLog;
        repData['_type'] = this.type;
        repData['_url'] = window.location.href;

        $.ajax({
            type: "POST",
            url: "/@@acc_sf_er",
            data: repData,
            cache: false,
            processData: true,
        }).done(function(data, textStatus, xhr) {
            console.log("sfer result: "+xhr.status+" "+data.result);
            if(data.result === "success") {
            } else if(data.result === "failed") {
            }
        }).fail(function(xhr, textStatus, errorThrown) {
            console.log("sfer Result: "+xhr.status+" / "+textStatus+" / "+errorThrown);
        });
    }

    showErrors(errs, el) {
        if(el !== undefined) {
            if(el === 1) {
                let banner = this.$form.find(".banner_el");
                banner.removeClass('hidden').addClass('open');
                firstErrPos = this.$form.find(".banner_el").first().offset().top-inz.modularForms.errorScrollOffset;
                $('html, body').animate({
                    'scrollTop': firstErrPos
                }, 600);
                return;
            }
        }

        for(let i = 0; i<errs.length; i++) {
            let field = this.getField(errs[i].id);
            field.config["error"] = errs[i]["error"];
            field.initialError();
        }
        let firstErrPos;
        this.$form.find(".banner_err").removeClass('hidden').addClass('open');
        if(this.$form.find('.err-msg.filled').length>0) {
            firstErrPos = this.$form.find('.err-msg.filled').first().closest('.form_field').offset().top-inz.modularForms.errorScrollOffset;
        } else {
            firstErrPos = this.$form.find(".banner_err").first().offset().top-inz.modularForms.errorScrollOffset;
        }
        $('html, body').animate({
            'scrollTop': firstErrPos
        }, 600);
    }

    resetEvent(event) {
        sessionStorage.removeItem("f_"+form.pid);
        sessionStorage.removeItem("f_"+form.pid+"_ul");
        sessionStorage.removeItem("f_"+form.pid+"_lk");
    }

    adjustHeight() {
        let open = $(document).find('.accordion_content_container.active, .question_evidence.active');
        if(open)
            this.setHeight(open, false);
    }

    setHeight(targets, animate) {
        let i, newHeight;

        // TODO: isSwitch = false; // reset switch value
        $(targets).css({'display': '', 'height': 'auto'});

        for(i = 0; i<targets.length; i = i+1) {
            newHeight = targets[i].clientHeight;

            if(animate) {
                $(targets[i]).height(0);
            }
            $(targets[i]).height(newHeight);

            let accordParent = $(targets[i]);
            if(accordParent.find('.rcd_wrap').length>0 || accordParent.find('.process_downloads').length>0) {
                setTimeout(function() {
                    accordParent.height('auto');
                }, 333);
                //Hide any open accords below selected
                if(!accordParent.hasClass('mobile_accord')) {
                    accordParent.find('.question_evidencetrigger, .question_evidence').removeClass('active');
                }
            }
        }
    }
}

inz.forms.message = inz.forms.message || {};


/**
 * Function to display a functionally modal message (in place of the current forms content)
 *
 * @param body the (rich text) content to be displayed in the left hand (non-spinner) column of the message panel
 * @param title the optional title to appear at the top of the widget, above the body
 * @param subtitle the optional subtitle to appear at the top of the left hand (non-spinner) column, above the message body
 * @param showSpinner boolean indicating whether to show the spinner alongside the message (defaults to true)
 * @param highlightBackground boolean indicating whether to render the widget with an alternate background style to separate it visually from the rest of the page (defaults to false)
 */
inz.forms.message.showMessage = function(body, title, subtitle, showSpinner, highlightBackground) {
    let form_message = $('.modal_forms_message');
    if(form_message.length<1) {
        return;
    }

    let message_body = $(form_message).find('.modal_forms_message_body');
    $(message_body).empty();
    if(title) {
        $(form_message).find('.modal_forms_message_title').html(title);
    }
    if(subtitle) {
        $(message_body).append($('<h2>', {html:subtitle}));
    }
    if(body) {
        $(message_body).append($(body));
    }
    if(showSpinner === undefined || Boolean(showSpinner)) {
        $(form_message).find('.modal_forms_message_spinner').show();
    } else {
        $(form_message).find('.modal_forms_message_spinner').hide();
    }
    form_message.toggleClass('highlightBackground', highlightBackground === undefined || Boolean(highlightBackground));

    $(form_message).show();
    $('.form_pre_load_hide').hide();
    // $(form_message).get(0).scrollIntoView();
};

inz.forms.message.scrollToMessage = function() {
    let pos = $('.modal_forms_message').offset().top;
    $('html, body').animate({'scrollTop':pos - 50}, 500);
};

inz.forms.message.hideMessage = function() {
    /*
     *  Function to hide the login_message (and re-display the current content)
     *
     */

    $('.modal_forms_message').hide();
    $('.form_pre_load_hide').show();
};


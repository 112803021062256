
inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};

/**
 * FieldPanel
 */

inz.forms.fields.InternationalPayment = class extends inz.forms.fields.Field {
    static fids = {
        COUNTRY: 0,
        UNSUP: 1,
        CURRENCY: 2,
        ACCOUNT_TYPE: 7,
        SWIFT: 9,
        IBAN: 10,
        OTHER: 11,
        ACC: 12,
        ROUTING: 13,
        TAX_ID: 14,
        OTHER_BANNER: 15,
    }

    static reset_fields = [
        2, // fids.CURRENCY,
        9, // fids.SWIFT,
        10, //fids.IBAN,
        12, // fids.ACC,
        11, // fids.OTHER,
        13, //fids.ROUTING,
        14, // fids.TAX_ID,
        15,
    ]

    static account_type_field = "bank_account_type";

    /*constructor(form, $ctx, config) {
        super(form, $ctx, config);
    };*/

    static setupFieldType(parsley) { }

    init(form, $ctx, config) {
        super.init(form, $ctx, config);
        this.fieldMap = {};
        this.defaultLabels = {};
        this.currentCountry = undefined;
        this.processHide = {};
    }

    addChild(child) {
        super.addChild(child);
        let fid = this.config.fields[child.name];
        if (fid !== undefined && fid !== null)
            this.fieldMap[fid] = child;
    }

    reset() {
        // hide everything except Country, make sure hide UNSUP so actions are enabled.
        for (const child of this.children) {
            //console.log("Child: "+child.id);
            child.clearValidation();
            if (child !== this.fieldMap[inz.forms.fields.InternationalPayment.fids.UNSUP] && child !== this.fieldMap[inz.forms.fields.InternationalPayment.fids.COUNTRY] ) {
                child.hide();
            } else if (child === this.fieldMap[inz.forms.fields.InternationalPayment.fids.COUNTRY]) {
                child.show();
            }
        }
        this.fieldMap[inz.forms.fields.InternationalPayment.fids.UNSUP].hide();
    }

    setup(initial) {
        this.defaultDisplay();
        // default labels
        this.defaultLabels[inz.forms.fields.InternationalPayment.fids.OTHER] = this.getLabel(inz.forms.fields.InternationalPayment.fids.OTHER);
        let f = this;
        this.fieldMap[inz.forms.fields.InternationalPayment.fids.COUNTRY].$el.change(function(evt) { f.countrySelected(evt) });
        this.fieldMap[inz.forms.fields.InternationalPayment.fids.ACCOUNT_TYPE].$el.change(function(evt) { f.accountTypeSelected(evt) });
    }

    /*hide(initial) {
        super.hide(initial);
    }*/

    getLabel(fid) {
        let f = this.fieldMap[fid];
        if (f === undefined)
            return null;
        //return f.$container.find("label").text();
        return f.$container.find("label").contents().first();
    }

    setLabel(fid, label) {
        let f = this.fieldMap[fid];
        if (f === undefined)
            return null;
        //f.$container.find("label").text(label);
        f.$container.find("label").contents().first().replaceWith(label);
    }

    resetCodes() {
        for (const f of inz.forms.fields.InternationalPayment.reset_fields) {
            this.fieldMap[f].clearValidation();
            this.fieldMap[f].hide();
        }
    }

    accountTypeSelected(evt) {
        let account_type = this.fieldMap[inz.forms.fields.InternationalPayment.fids.ACCOUNT_TYPE].getValue();
        if (this.currentCountry === undefined)
            return;

        this.processHide = {};
        for (const f of this.currentCountry['f']) {
            if("c" in f) { // condition check
                this.processField(this.currentCountry, f, account_type);
            }
        }
        this.hideRemaining(this.processHide);
    }

    countrySelected(evt) {
        this.currentCountry = undefined;
        let country = this.fieldMap[inz.forms.fields.InternationalPayment.fids.COUNTRY];
        //console.log("Country: "+country.getValue());
        if (country.getValue() === '')
            return;  // blank don't do anything
        let c = this.config.countries[country.getValue()];
        if (c === undefined) {
            //console.log("Unknown country: "+country.getValue());
            this.countrySupported(false);
            return;
        }
        if (!("f" in c)) {
            console.log("No fields defined");
            this.countrySupported(false);
            return;
        }
        this.currentCountry = c;
        this.countrySupported(true);
        this.resetCodes();

        let account_type = this.fieldMap[inz.forms.fields.InternationalPayment.fids.ACCOUNT_TYPE].getValue();
        this.processHide = {};
        for (const f of c['f']) {
            this.processField(c, f, account_type);
        }
        this.hideRemaining(this.processHide);

        // currency
        if (c['c'] !== undefined && c['c'].length > 1) {
            // multiple currencies
            let data = [];
            for (const cur of c['c']) {
                data.push({id: cur, text: cur});
            }
            //this.fieldMap[inz.forms.fields.InternationalPayment.fids.CURRENCY].$el.select2({data: data}).trigger("change");
            this.fieldMap[inz.forms.fields.InternationalPayment.fids.CURRENCY].$container.find('select').empty().select2({data: data}).trigger("change");
            this.fieldMap[inz.forms.fields.InternationalPayment.fids.CURRENCY].show(false);
        } else {
            this.fieldMap[inz.forms.fields.InternationalPayment.fids.CURRENCY].hide(false);
        }

    }

    hideRemaining(fields) {
        for (const f of Object.keys(fields)) {
            if (fields[f]) {
                let field = this.fieldMap[f];
                field.hide(false);
            }
        }
    }

    processField(c, f, account_type) {
        let field = this.fieldMap[f.i];
        if ("c" in f) { // condition check
            if ((f.c === "IND" && account_type === "Business") || (f.c === "BUS" && (account_type === undefined || account_type === "Individual"))) {
                if (!(f.i in this.processHide)) {
                    this.processHide[f.i] = true;
                }
                // field.hide(false);
                return; // don't use this field
            }
        }

        this.processHide[f.i] = false;

        if ("l" in f) {
            this.setLabel(f.i, f.l);
        } else if (f.i in this.defaultLabels) {
            this.setLabel(f.i, this.defaultLabels[f.i]);
        }
        if ("e" in f && field.setContent !== undefined) {
            if ("t" in f) {
                field.setContent(inz.forms.unescapeHTML(f.t), inz.forms.unescapeHTML(f.e));
            } else {
                field.setContent(null, inz.forms.unescapeHTML(f.e));
            }
        }
        if ("o" in f) {
            field.setRequired(f.o);
        } else {
            field.setRequired(true);  // default to required
        }
        if (field.setupField !== undefined)
            field.setupField(f);
        field.show(true);
    }

    countrySupported(supported) {
        for (const child of this.children) {
            //console.log("Child: "+child.id);
            if (child !== this.fieldMap[inz.forms.fields.InternationalPayment.fids.UNSUP] && child !== this.fieldMap[inz.forms.fields.InternationalPayment.fids.COUNTRY] ) {
                child.clearValidation();
                if (supported)
                    child.show();
                else
                    child.hide();
            }
        }
        if (supported) {
            this.fieldMap[inz.forms.fields.InternationalPayment.fids.UNSUP].hide();
        } else {
            this.fieldMap[inz.forms.fields.InternationalPayment.fids.UNSUP].show();
        }
    }
}


inz.forms = inz.forms || {};
inz.forms.panel = inz.forms.panel || {};

/**
 * CustomEvent polyfill
 */

(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

/**
 * Panel Form
 */

$(window).ready(function() {
    for (let idx in window.inz.lhf) {
        let form = window.inz.lhf[idx];
        form.form = inz.forms.panel.createForm($("body"), form.id, form.type, form.pid, inz.lhf_context ? inz.lhf_context : inz.forms.panel.contexts.LOGIN_HEADER, form.el_prefix);
        form.form.load(form.config);
        form.form.nextPanel();
    }

    const loginBtn = document.getElementsByClassName("nav_trigger__login");
    if (loginBtn.length > 0) {
        loginBtn[0].addEventListener("click", ()=>{
            setTimeout(function() {
                for(let idx in window.inz.lhf) {
                    let form = window.inz.lhf[idx];
                    form.form.reset();
                }
            }, 333);
        });
    }

    const mobileLoginBtn = document.getElementsByClassName("nav_mobile_trigger__login");
    if (mobileLoginBtn.length > 0) {
        mobileLoginBtn[0].addEventListener("click", ()=>{
            setTimeout(function() {
                for(let idx in window.inz.lhf) {
                    let form = window.inz.lhf[idx];
                    form.form.reset();
                }
            }, 333);
        });
    }

    inz.forms.panel.setup_cta_forms();
    inz.forms.panel.setup_details_cta_forms();
});

inz.forms.panel.setup_cta_forms = function() {
    for (let idx in window.inz.ctf) {
        let form = window.inz.ctf[idx];
        form.form = inz.forms.panel.createForm($("body"), form.id, form.type, form.pid, inz.forms.panel.contexts.PAGE_CTA);
        form.form.load(form.config);
        form.form.nextPanel();
    }

    //const btns = document.getElementsByTagName("button");
    const btns = document.getElementsByClassName("btn_popout");
    if (btns.length > 0) {
        for (let idx in btns) {
            let btn = btns[idx];
            if (btn.tagName === "BUTTON" && btn.id.startsWith("submit_eoi_btn")) {
                btn.addEventListener("click", ()=>{
                    setTimeout(function()
                    {
                        for(let idx in window.inz.ctf) {
                            let form = window.inz.ctf[idx];
                            form.form.reset();
                        }
                    }, 333);
                });
            }
        }
    }
}

inz.forms.panel.setup_details_cta_forms = function() {

    if (window.inz.details.ls === undefined || window.inz.details.ls.config ===undefined)
        return;

    let form = window.inz.details.ls.config;
    form.form = inz.forms.panel.createForm($("body"), form.id, form.type, form.pid, inz.forms.panel.contexts.PAGE_DETAILS);
    form.form.load(form.config);
    form.form.nextPanel();

    $("#details_login_lightbox").on('inz_lightbox:hidden', function(event, lightbox) {
        // IRT-445 reset light box forms to initial state
        window.inz.details.ls.config.form.reset();
    });

    /*const btns = document.getElementsByClassName("btn_popout");
    if (btns.length > 0) {
        for (let idx in btns) {
            let btn = btns[idx];
            if (btn.tagName === "BUTTON" && btn.id.startsWith("submit_eoi_btn")) {
                btn.addEventListener("click", ()=>{
                    setTimeout(function()
                    {
                        for(let idx in window.inz.ctf) {
                            let form = window.inz.ctf[idx];
                            form.form.reset();
                        }
                    }, 333);
                });
            }
        }
    }*/
}

inz.forms.panel.getLoginForm = function(formId) {
    for (let idx in window.inz.lhf) {
        if (window.inz.lhf[idx].id === formId) {
            return window.inz.lhf[idx].form;
        }
    }
}

inz.forms.panel.htmlDecode = function(input) {
    let e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

inz.forms.panel.contexts = {
    LOGIN_HEADER: "login_header",
    LOGIN_LIGHTBOX: "login_lightbox",
    PAGE_CTA: "page_cta",
    PAGE_DETAILS: "page_details",
}

inz.forms.panel.createForm = function($ctx, id, type, pid, panelContext, elPrefix) {
    return new inz.forms.panel.Form($ctx, id, type, pid, panelContext, elPrefix);
}

inz.forms.panel.createElement = function(tagName, props, attributes) {
    let el = document.createElement(tagName);
    if (props !== undefined) {
        for(const [k, v] of Object.entries(props)) {
            el[k] = v;
        }
    }
    if (attributes !== undefined) {
        for(const [k, v] of Object.entries(attributes)) {
            el.setAttribute(k, v);
        }
    }
    return el;
}

inz.forms.panel.events = {
    PANEL_SHOWN: "forms:panel:shown",
    PANEL_HIDDEN: "forms:panel:hidden",
}

inz.forms.panel.Form = class {

    constructor($ctx, id, type, pid, panelContext, elPrefix) {
        this.id = id;
        this.type = type;
        this.pid = pid;
        if (elPrefix === undefined)
            this.elPrefix = "";
        else
            this.elPrefix = elPrefix; // prefix HTML element ids with this to avoid conflicts
        this.panelContext = panelContext;
        this.$form = $ctx.find("#"+this.id).first();
        this.fields = {};  // fields by id
        this.panels = [];
        this.curPanel = undefined;

        this.containerEl = document.getElementById(id);
        this.containerEl.classList.add("panel-form-container");

        /*this.$form.submit(this, this.submitEvent);
        this.$form.find(".reset_button").click(this, this.resetEvent);
        this.$form.find(".xreset_button").click(this, this.resetEvent);*/
    }

    /*findFieldByEl($el) {
        let field = $el.data("field");
        if(field === undefined)
            field = $el.parents(".obj_form_field").first().data("field");
        return field
    };*/

    load(config) {
        // create fields
        // inz.forms.fields
        for(let i = 0; i<config.length; i++) {
            if(!(config[i]["type"] in inz.forms.panel.fields)) {
                console.log("Unknown field type: "+config[i]["type"]+" for field: "+config[i]);
                continue;
            }

            //var field = Object.create(inz.forms.fields[config[i]["type"]].prototype);
            let field = new inz.forms.panel.fields[config[i]["type"]](this, this.$form, this.panelContext, config[i]);
            //field.init(this, this.$form, config[i]);
            this.fields[config[i]["id"]] = field;
            if(config[i]["type"] === "FieldPanel" || config[i]["type"] === "FieldOnlineService")
                this.panels.push(field);
        }

        // all fields now added, do setup and add conditions which can now resolve dependencies.
        for(let fieldId in this.fields) {
            if(this.fields[fieldId].config.conditions.length !== 0)
                this.fields[fieldId].addConditions(this.fields[fieldId].config.conditions);
        }

        // for (var i=0;i < config.length; i++)
        // {
        //     if (config[i].conditions.length !== 0)
        //         this.fields[config[i].id].addConditions(config[i].conditions);
        // }

        for(let fieldId in this.fields) {
            this.fields[fieldId].setup(true);
        }
        //this.curPanel = this.panels[0];
    }

    fireEvent(src, name, data, bubbles = true) {
        let event = new CustomEvent(name, {
            bubbles: bubbles,
            detail: data,
        });
        src.dispatchEvent(event);
    }

    nextPanel(panelId) {
        if (panelId === undefined) {
            if (this.curPanel !== undefined && this.curPanel.rendered()) {
                this.curPanel.hide();
                this.fireEvent(this.curPanel.container, inz.forms.panel.events.PANEL_HIDDEN, this.curPanel);
            }
            this.curPanel = this.panels[0];
        } else {
            if (this.curPanel !== undefined && this.curPanel.rendered()) {
                this.curPanel.hide();
                this.fireEvent(this.curPanel.container, inz.forms.panel.events.PANEL_HIDDEN, this.curPanel);
            }
            this.curPanel = this.getField(panelId);
        }

        if (!this.curPanel.rendered()) { // create the panel
            let content = this.curPanel.render();
            this.containerEl.appendChild(content);
            this.curPanel.attached();
            this.fireEvent(this.curPanel.container, inz.forms.panel.events.PANEL_SHOWN, this.curPanel);
        } else {
            // panel should already exist show it
            this.curPanel.show();
            this.fireEvent(this.curPanel.container, inz.forms.panel.events.PANEL_SHOWN, this.curPanel);
        }
    }

    reset() {
        this.nextPanel(this.panels[0].id);
    }

    getValues() {
        var data = {};
        for(var id in this.fields) {
            var value = this.fields[id].getValue();
            data[id] = [value, this.fields[id].isHidden];
        }
        return data;
    }

    setValues(data) {
        for(var i = 0; i<this.groups.length; i++) {
            var value = data[this.groups[i].id];
            if(value !== undefined) {
                if(value.length === 2) {
                    if(value[1] === true) {
                        this.groups[i].hide(true);
                    } else {
                        this.groups[i].show(true);
                        this.groups[i].setValue(value[0]);
                    }
                }
            }
        }

        for(var id in this.fields) {
            if(this.fields[id] instanceof inz.forms.fields.FieldGroup)
                continue;

            var value = data[id];
            if(value !== undefined) {
                // Don't re-fill the form for old sessionStorage formats - it's just busted
                if(value.length === 2) {
                    if(value[1] === true) {
                        this.fields[id].hide(true);
                    } else {
                        this.fields[id].show(true);
                        this.fields[id].setValue(value[0]);
                    }
                }
            }
        }
    }

    addField(field) {
        this.fields[field.id] = field;
    }

    removeField(field) {
        delete this.fields[field.id];
    }

    getField(id) {
        if(id in this.fields === false) {
            console.log("Unknown field: "+id);
            return null;
        }
        return this.fields[id];
    }

    changeEvent(event) {
        let field = event.data;
        //console.log(`ChangeEvent: ${field.name}`);
        if(field.dependants.length === 0)
            return;
        let value = field.getValue();
        //console.log(`ChangeEvent value: ${value}`);

        for(let i = 0; i<field.dependants.length; i++) {
            field.dependants[i].processConditions(field, value);
        }
    }

    focusOut(event) {
    }
}

/**
 * Condition
 */

inz.forms.panel.Condition = class {

    constructor(form, field, config) {
        this.form = form;
        this.field = field;
        this.testType = config.test;
        this.testValue = config.tvalue;
        this.op = config.op;
        this.dependField = form.getField(config.depends);
        if(this.dependField !== null)
            this.dependField.addDependant(this.field);
    }

    isValid() {
        return this.dependField !== null;
    }

    remove() {  // tidy up for condition removal
        this.dependField.removeDependant(this.field);
    }

    update(field, dependant) {
        this.field = field;
        this.dependField = dependant;
        this.dependField.addDependant(this.field);
    }

    test(value) {
        // all test involve values, so grab dependents one
        if(value === undefined)
            value = this.dependField.getValue();
        var isArray = $.isArray(value);

        switch(this.testType) {
            case "VALUE":
                if((isArray && value.length !== 0) || (!isArray && value))
                    return true;
                break;

            case "NO_VALUE":
                if((isArray && value.length === 0) || (!isArray && !value))
                    return true;
                break;

            case "MATCHES_VALUE":
                if((isArray && $.inArray(this.testValue, value) !== -1) || (!isArray && value === this.testValue))
                    return true;
                break;

            case "NOT_MATCHES_VALUE":
                if((isArray && $.inArray(this.testValue, value) === -1) || (!isArray && value !== this.testValue))
                    return true;
                break;

            case "HAS_VALUE_AND_NOT_MATCHES_VALUE":
                if((isArray && value.length === 0) || (!isArray && !value))
                    return false;  // no value, false
                if((isArray && $.inArray(this.testValue, value) === -1) || (!isArray && value !== this.testValue))
                    return true;
                break;
        }
        return false;
    }
}

/**
 * Field
 */

inz.forms.panel.fields = {};

inz.forms.panel.fields.Field = class {

    constructor(form, $ctx, panelContext, config) {
        this.form = form;
        this.container = undefined;
        this.el = undefined;
        this.panelContext = panelContext;
        this.config = config;
        this.name = config.name;
        this.id = config.id;
        this.display = config.display;
        this.isHidden = (this.display == "SHOW");

        this.children = [];
        this.parent = null;
        //this.group = null;
        this.conditions = [];
        this.dependants = [];

        if("parent" in config) {
            let parent = this.form.getField(config.parent);
            if(parent === null)
                console.log("Form Error: Parent: "+config.parent+" not in fields registry");
            else {
                this.parent = parent;
                this.parent.addChild(this);
            }
        }
    }

    rendered() {
        return this.container !== undefined;
    }

    renderContainer() {
        this.container = document.createElement("div");
        this.container.className = "obj_form_container";
        this.container.id = `c_${this.form.elPrefix}_${this.id}`;
        return this.container;
    }

    renderField() {
        this.el = document.createElement("div");
        this.el.className = "obj_form_field";
        this.el.id = `f_${this.form.elPrefix}_${this.id}`;
        this.el.setAttribute("data-fid", this.id);
        return this.el;
    }

    render(context) {
        let container = this.renderContainer(); // this.container set
        let field = this.renderField();
        let def = document.createElement("h1");
        def.textContent= this.name;
        field.appendChild(def);
        container.appendChild(field);
        return this.container;
    }

    attached() {
    }

    addConditions(conditions) {
        for(let i = 0; i<conditions.length; i++) {
            let cond = new inz.forms.panel.Condition(this.form, this, conditions[i]);
            if(cond.isValid())
                this.conditions.push(cond);
        }
    }

    addCondition(condition) {
        let cond = new inz.forms.panel.Condition(this.form, this, conditions[i]);
        if(cond.isValid())
            this.conditions.push(cond);
    }

    addChild(child) {
        this.children.push(child);
    }

    addDependant(dependant) {
        if(dependant in this.dependants)
            return;
        this.dependants.push(dependant);
    }

    removeDependant(dependant) {
        let i = this.dependants.indexOf(dependant);
        if(i> -1)
            this.dependants.splice(i, 1);
    }

    addChildren(children) {
        $.merge(this.children, children);
    }

    setup(initial) {
        //this.$el.change(this, this.form.changeEvent);
        //this.defaultDisplay();
        // if (initial !== undefined && initial)
        //     this.initialError();
    }

    defaultDisplay() {
        /*if(this.display === "SHOW") {
            if(this.isHidden) {
                this.show();
            }
        } else if(this.display === "HIDE") {
            if(!this.isHidden) {
                this.hide();
            }
        }*/
    }

    getName() { return this.el.name; }

    setName(name) {
        this.name = name;
        this.el.name = name;
    }

    getId() { return this.el.id; }

    setId(id) {
        this.id = id;
        this.el.id = `f_${this.form.elPrefix}_${this.id}`;
        this.container.id = `c_${this.form.elPrefix}_${this.id}`;
    }

    hasValue() { return true; }

    getElement() { return this.el; }

    getValue() { return this.el.value; }
    setValue(value) {
        this.el.value = value;
        $(this.el).val(value).change();
    }

    reset() { $(this.el).val('').change(); }

    show(initial) {
        //console.log(`show field: ${this.name} Type: ${this.type} `);
        this.isHidden = false;
        this.reset();
        this.container.style.display = '';
    }

    hide(initial) {
        this.isHidden = true;
        this.reset();
        this.container.style.display = 'none';
    }

    processConditions(src, value) {
        for(let i = 0; i<this.conditions.length; i++) {
            const cond = this.conditions[i];
            if(src === undefined || cond.dependField === src) {
                if(cond.test(value)) {
                    switch (cond.op) {
                        case "SHOW":
                            if(this.isHidden) {
                                this.show();
                            }
                            break;
                        case "HIDE":
                            if(!this.isHidden) {
                                this.hide();
                            }
                            break;
                        case "NEXT":
                            this.form.nextPanel(this.id);
                            break;
                    }
                    return;
                }
            }
        }
        // nothing matched, do default
        this.defaultDisplay();
    }
}

/**
 * FieldPanel
 */

inz.forms.panel.fields.FieldPanel = class extends inz.forms.panel.fields.Field {
    constructor(form, $ctx, panelContext, config) {
        super(form, $ctx, panelContext, config);
    };

    reset() { // process any conditions / dependants
        //this.form.changeEvent({data: this});
        for (let child of this.children) {
            child.reset();
        }
    }

    setup(initial) {
        this.defaultDisplay();
    }

    hasValue() { return false; }

    renderLoginHeader()
    {
        let doc = document.createElement("div");
        doc.className = "obj_form_container";
        doc.id = `c_${this.form.elPrefix}_${this.id}`;

        //console.log(this.config.content);
        switch(this.panelContext)
        {
            case inz.forms.panel.contexts.LOGIN_LIGHTBOX:
            case inz.forms.panel.contexts.PAGE_DETAILS:
            {
                doc.innerHTML = `
                    <h1>${this.config.content.title}</h1>
                    ${this.config.content.intro?inz.forms.panel.htmlDecode(this.config.content.intro):''}                
                    <div class="header_login_content panel_content"></div>`;
                break;
            }

            case inz.forms.panel.contexts.PAGE_CTA:
            {
                doc.innerHTML = `
                    <div class="row">
                        <div class="col-pgmd-6">
                            <h4 class="popout_title">${this.config.content.title}</h4>
                        </div>
                        <div class="col-pgmd-13 popout__login_content">
                            ${this.config.content.intro?inz.forms.panel.htmlDecode(this.config.content.intro):''}
                            <div class="header_login_content panel_content">                    
                            </div>                                                            
                        </div>
                    </div>`;
                break;
            }

            default:
            {
                doc.innerHTML = `
                    <div class="row">
                        <div class="col-md-2">
                            <h4 class="header_login_title">${this.config.content.title}</h4>
                        </div>
                        <div class="col-md-5">
                            <div class="header_login_text">${this.config.content.intro?inz.forms.panel.htmlDecode(this.config.content.intro):''}</div>                
                        </div>
                        <div class="col-md-5">
                            <div class="header_login_content panel_content">                    
                            </div>                
                        </div>
                    </div>`;
            }
        }
        return doc;
    }

    render() {
        this.container = this.renderLoginHeader();
        let dst = this.container.getElementsByClassName("panel_content")[0];
        let field = this.renderField();
        // render panel fields
        for (let i=0; i<this.children.length; i++) {
            field.appendChild(this.children[i].render());
        }
        dst.appendChild(field);
        return this.container;
    }

    attached() {
        for (let i=0; i<this.children.length; i++) {
            this.children[i].attached();
        }
    }
}

/**
 * FieldOnlineService
 */

inz.forms.panel.fields.FieldOnlineService = class extends inz.forms.panel.fields.Field {
    constructor(form, $ctx, panelContext, config) {
        super(form, $ctx, panelContext, config);
    };

    reset() { // process any conditions / dependants
    }

    setup(initial) {
        this.defaultDisplay();
    }

    hasValue() { return false; }

    render() {
        this.container = document.createElement("div");

        let content = this.config.content;

        switch(this.panelContext)
        {
            case inz.forms.panel.contexts.LOGIN_LIGHTBOX:
            case inz.forms.panel.contexts.PAGE_DETAILS:
            {
                this.container.innerHTML = `
                <h1>${content.title}</h1>
                ${inz.forms.panel.htmlDecode(content.text)}
                <div>
                    <a class="btn btn__login${content.type==='Online'?' online_services_login':''}${content.type==='RealMe'?' realme_2023_login':''}" target="_blank" href="${content.login_url}">${content.type==='RealMe'?'Log in':content.login_cta}</a>
                </div>
                ${content.type==='RealMe'?`<a href="https://realme.govt.nz" class="realme_glossary_tip" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="${inz.lhf_realme_original_title}"><span class="tooltip-sronly">Glossary for </span>What is RealMe?</a>`:''}
                ${content.link1_text?`<a class="field_forgotten_password" href="${content.link1_url}">${content.link1_text}</a>`:''}
                ${content.link2_text?`<a class="field_forgotten_password" href="${content.link2_url}">${content.link2_text}</a>`:''}
               `;
                break;
            }

            case inz.forms.panel.contexts.PAGE_CTA:
            {
                let text = "";
                if (content.text) {
                    text = `${inz.forms.panel.htmlDecode(content.text)}`;
                }
                let loginContent = "";
                if (content.type === "RealMe") {
                    loginContent += '<div class="btn btn__realme">RealMe</div>';
                    const realme = window.inz.glossary.realme;
                    if (realme !== undefined)
                        loginContent += `<p><a href="${realme.url}" data-toggle="tooltip" data-original-title="${realme.definition}" data-toggle-switch="toggle" class="tooltip_content header__realme_info tooltip_lightbg">${realme.label}</a></p>`;
                } else {
                    if (content.link1_text && content.link1_url)
                        loginContent +=`<a class="login_panel_link field_forgotten_password" href="${content.link1_url}">${content.link1_text}</a>`;
                    if (content.link2_text && content.link2_url)
                        loginContent +=`<a class="login_panel_link field_forgotten_password" href="${content.link2_url}">${content.link2_text}</a>`;
                }

                this.container.innerHTML = `
                <div class="obj_form_container" id="c_${this.form.elPrefix}_${this.id}">
                    <div class="row">
                        <div class="col-pgmd-6">
                            <h4 class="popout_title">${content.title}</h4>
                        </div>
                        <div class="col-pgmd-13 popout__login_content">
                            ${text}
                            <div class="panel_content">
                                <a target="_blank" class="btn btn__login${content.type==='Online'?' btn_primary sitelogin':''}" href="${content.login_url}">${content.login_cta}</a>
                                ${loginContent}
                            </div>
                        </div>
                    </div>
                </div>`;
                break;
            }


            default:
            {
                let text = "";
                if (content.text) {
                    text = `<div class="header_login_text">${inz.forms.panel.htmlDecode(content.text)}</div>`;
                }
                let loginContent = "";
                if (content.type === "RealMe") {
                    loginContent += '<div class="btn btn__realme">RealMe</div>';
                    const realme = window.inz.glossary.realme;
                    if (realme !== undefined)
                        loginContent += `<a href="${realme.url}" data-toggle="tooltip" data-original-title="${realme.definition}" data-toggle-switch="toggle" class="tooltip_content header__realme_info tooltip_lightbg">${realme.label}</a>`;
                } else {
                    if (content.link1_text && content.link1_url)
                        loginContent +=`<a class="login_panel_link field_forgotten_password" href="${content.link1_url}">${content.link1_text}</a>`;
                    if (content.link2_text && content.link2_url)
                        loginContent +=`<a class="login_panel_link field_forgotten_password" href="${content.link2_url}">${content.link2_text}</a>`;
                }

                this.container.innerHTML = `
                <div class="obj_form_container${content.type==='Online'?' header_login__site':''}" id="c_${this.form.elPrefix}_${this.id}">
                    <div class="row">
                        <div class="col-md-2">
                            <h4 class="header_login_title">${content.title}</h4>
                        </div>
                        <div class="col-md-5">
                            ${text}
                        </div>
                        <div class="col-md-5">
                            <div class="header_login_content">
                                <a target="_blank" class="btn btn__login${content.type==='Online'?' btn_primary sitelogin':''}" href="${content.login_url}">${content.login_cta}</a>
                                ${loginContent}                                
                            </div>
                        </div>
                    </div>
                </div>`;
            }
        }

        // initialise the tooltip on any glossary links in the container content
        // ** Don't use this event as it causes a loop of events because DNA can't program, instead tool tip is setup in the attached call below
        //$(document).trigger('app:inittooltips', {target: $(this.container)});
        return this.container;
    }

    attached() {
        inz.forms.panel.tooltips.setup($(this.container));
    }
}

/**
 * Radio Buttons Field
 */

inz.forms.panel.fields.RadioButtonsField = class extends inz.forms.panel.fields.Field {
    constructor(form, $ctx, panelContext, config) {
        super(form, $ctx, panelContext, config);
        this.name = `${this.form.elPrefix}_${this.name}`;
    };

    getValue() { return this.container.querySelector('input:checked').value; }

    setValue(value) {
        /*this.$container.find('input:checked').removeAttr('checked'); // clear old
        if(value === null || value === undefined)
            this.$container.find('input:checked').removeAttr('checked');
        else {
            this.$container.find('input[type=radio][value=\"'+inz.forms.escapeHTML(value)+'\"]').prop('checked', true);
        }
        this.$el.find("input[type='radio']").first().change();*/
    }

    reset() {
        let radios = this.container.getElementsByTagName("input");
        for (let e of Array.from(radios)) {
            e.checked = false;
        }
    }

    setup(initial) {
        //this.$el.find("input[type='radio']").change(this, this.form.changeEvent);
        //this.defaultDisplay();
    }

    setName(name) {
        this.name = name;
        //this.$el.attr("name", name);
        this.$el.find("input").each(function(idx) {
            $(this).attr("name", name);
        });
    }

    setId(id) {
        inz.forms.panel.fields.Field.prototype.setId.call(this, id);
        var rb = this;
        // update button options
        this.$el.find("input").each(function(idx) {
            var $e = $(this);
            var oldId = $e.attr("id");
            var newId = "opt_"+id+String(idx);
            $e.attr("id", "opt_"+id+String(idx));
            $e.attr("data-parsley-multiple", id);
            rb.$el.find("label[for='"+oldId+"']").attr("for", newId);
        });
    }

    addOption(dst, optId, name, label, value) {
        const props = { id: optId, className: "input", name: name, type: "radio", value: value};
        const input = inz.forms.panel.createElement("input", props, {"data-fid": this.id});
        dst.appendChild(input);
        let labelEl = inz.forms.panel.createElement("label", {htmlFor: optId, textContent: label});
        dst.appendChild(labelEl);
        return input;
    }

    render() {
        //this.rendered = true;
        let container = this.renderContainer(); // this.container set
        let field = this.renderField();
        field.classList.add("input__radio");

        let radios = document.createElement("div");

        switch(this.panelContext)
        {
            case inz.forms.panel.contexts.LOGIN_LIGHTBOX:
            case inz.forms.panel.contexts.PAGE_DETAILS:
            {
                radios.className = `login_lightbox_radio_buttons${this.config.content.options.length > 3 ? ' fixed_width' : ''}`;
                let f = this;

                for(const opt of this.config.content.options)
                {
                    let o = document.createElement("div");
                    let input = this.addOption(o, this.form.elPrefix+"_"+opt.i, this.name, opt.n, opt.v);
                    input.addEventListener('change', ()=>{ setTimeout(function() { f.form.changeEvent({data: f}); }); }, 50);
                    radios.appendChild(o);
                }
                break;
            }

            default:
            {
                radios.className = "radio_field_container";
                let f = this;

                for(const opt of this.config.content.options)
                {
                    let o = document.createElement("div");
                    o.className = "col-md-6";
                    let input = this.addOption(o, this.form.elPrefix+"_"+opt.i, this.name, opt.n, opt.v);
                    input.addEventListener('change', ()=>{ setTimeout(function() { f.form.changeEvent({data: f}); }); }, 50);
                    radios.appendChild(o);
                }
            }
        }

        field.appendChild(radios);
        container.appendChild(field);
        return this.container;
    }

}

/**
 * Selection Field
 */

inz.forms.panel.fields.SelectionField = class extends inz.forms.panel.fields.Field {

    constructor(form, $ctx, panelContext, config) {
        super(form, $ctx, panelContext, config);
        this.reId = new RegExp("f_([a-zA-Z0-9]{7,})__(\\d+)");
    }

    reset() {
        let def = "";
        if("default" in this.config)
            def = this.config['default'];
        this.el.value = def;
        //$(this.el).trigger("change");
    }

    setup(initial) {
    }

    optionRender(optId, name, label, value) {
        return `<option value="${value}">${label}</option>`;
    }

    addOption(dst, label, value) {
        let opt = document.createElement("option");
        opt.value = value;
        opt.text = label;
        dst.appendChild(opt);
        return opt;
    }

    renderField() {
        const props = { id: `f_${this.form.elPrefix}_${this.id}`, className: "Xjs-select2 Xjs-select2-theme_darkgrey Xjs-select2-nosearch Xdropdown_body__login" };
        const attrs = {
            title: this.config.content.title,
            autocomplete: "off",
            // style: "width: 50%",
            "data-maxSelection": "1"
        };

        this.el = inz.forms.panel.createElement("select", props, attrs);

        // select2 single-select dropdown requires a blank option with no value or label for the placeholder
        let def = document.createElement("option");
        def.value = "";
        def.text = "";
        this.el.appendChild(def);

        return this.el;
    }

    render() {
        //this.rendered = true;
        let container = this.renderContainer(); // this.container set
        let c = document.createElement("div");
        c.className="Xdropdown__login";
        let field = this.renderField();
        let f = this;
        //this.el.addEventListener('change', () => { f.form.changeEvent({data: f}); });
        $(this.el).change(this, this.form.changeEvent);

        for (const opt of this.config.content.options) {
            this.addOption(field, opt.n, opt.v[0]);
        }

        c.appendChild(field)
        container.appendChild(c);
        $(this.el).select2({
            dropdownParent: c,
            minimumResultsForSearch: 10,
            placeholder: this.config.content.title,
            theme: 'darkgrey',
        });
        return this.container;
    }

    attached() {
    }

}

/**
 * Tool Tips
 */

inz.forms.panel.tooltips = inz.forms.panel.tooltips || {};

inz.forms.panel.tooltips.setup = function(scope) {
    inz.forms.panel.tooltips.setupTooltips(scope.find('[data-toggle="tooltip"]'), scope);
    inz.forms.panel.tooltips.attachEvents(scope);
}

inz.forms.panel.tooltips.setupTooltips = function(selector, scope) {

    var isMobile = $('html.touch').length > 0 && (DO.CurrentBreakpoint() === 'base' || DO.CurrentBreakpoint() === 'small' || DO.CurrentBreakpoint() === 'medium' || DO.CurrentBreakpoint() === 'large'),
        container = scope.closest('.dock').length == 0 || isMobile ? 'body' : '.dock_body';

    selector.each(function() {

        var title = '<h4 class="tooltip_title">' + ($(this).attr('tooltip_title') ? $(this).attr('tooltip_title') : $(this).text()) + '</h4>',
            template = '<div class="tooltip tooltip__small" role="tooltip"><div class="tooltip_wrap"><div class="btn btn__tooltip"><span class="sr-only">Close</span></div><div class="tooltip-arrow"></div>' + title+ '<div class="tooltip-inner"></div></div></div>';

        $(this).addClass('tooltip_content').attr('role', 'button').attr('href', '#').removeAttr('target').prepend('<span class="tooltip-sronly">Glossary for </span>');

        if ($(this).closest('.stripe__darkblue').length > 0) {
            template = '<div class="tooltip tooltip__small tooltip__dark" role="tooltip"><div class="tooltip_wrap"><div class="btn btn__tooltip"><span class="sr-only">Close</span></div><div class="tooltip-arrow"></div>' + title+ '<div class="tooltip-inner"></div></div></div>';
        }

        $(this).tooltip({
            container: container,
            animation: true,
            html: true,
            trigger: 'click manual',
            template: template,
            viewport: 'body'
        });
    });
}

inz.forms.panel.tooltips.attachEvents = function(scope) {

    scope.find('[data-toggle="tooltip"]').on('click', function(e) {
        e.preventDefault();
    });

    scope.find('[data-toggle="tooltip"]').on('inserted.bs.tooltip', function(a,b,c) {
        $('html').addClass('tooltip_isopen');
        if(scope.closest('.data-tool').length > 0) return;
        var top = $(document).scrollTop();
        // Once animation is complete, remove adjust body position and classes
        $('body').css('top', -top + 'px');

    });

    scope.find('[data-toggle="tooltip"]').on('hide.bs.tooltip', function() {

        $('html').removeClass('tooltip_isopen');
        // dont scroll to top of page if inside a tool
        if(scope.closest('.data-tool').length > 0) return;
        var top = $('body').position().top;
        $('body').css('top', -top + 'px');
        //$(document).scrollTop(top*=-1);
    });
}

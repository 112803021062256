
inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};
inz.forms.validations = inz.forms.validations || {};

/**
 * ContentImageField
 */

inz.forms.fields.ContentImage = class extends inz.forms.fields.Field {

    /*constructor(form, $ctx, config) {
        super(form, $ctx, config);
    };*/

    static setupFieldType(parsley) {
    }

    init(form, $ctx, config) {
        super.init(form, $ctx, config);
        this.validation = null;
        this.prefix = "";
    }

    reset() {
        super.reset();
    }

    setup(initial) {
        let _this = this;
        this.$el.on("input", function() { _this.inputTransforms(this); });
        this.defaultDisplay();
    }

    hasValue() {
        return false;
    }
}

inz.forms = inz.forms || {};
inz.forms.calc = inz.forms.calc || {};

inz.forms.calc.MedianWage = class extends inz.forms.Form {

    init($ctx, id, type, pid) {
        super.init($ctx, id, type, pid, "", "False");
        this.rVar = /\{(.*?)\}/g;

        this.enableLeaveWarning = false;
        this.enableLogging = false;
        this.calc_fields = {
            "QRemType": null,
            "Hourly": {},
            "Salary": {},
        }
        this.MedianWage = 0;
        this.MinWage = 0;
        this.haveCalculated = false;
    }

    load(field_config, calc_config) {
        super.load(field_config);

        // resolve fields
        this.MedianWage = calc_config.MeW;
        this.MinWage = calc_config.MnW;
        this.outputHeading = calc_config.OutH;
        this.outputBody = inz.forms.unescapeHTML(calc_config.OutB);
        this.errorHeading = calc_config.EH;
        this.errorBody = inz.forms.unescapeHTML(calc_config.EB);

        this.calc_fields.QRemType = this.getField(calc_config.QRT);
        for (const k in calc_config.H) {
            this.calc_fields.Hourly[k] = this.getField(calc_config.H[k]);
        }
        for (const k in calc_config.S) {
            this.calc_fields.Salary[k] = this.getField(calc_config.S[k]);
        }

        /*this.$form.parsley().on('form:validate', function(e) {
            console.log("calc form valied!!");
        });*/

        //this.$form.find("input.calc_btn").click(this, function(event) {
        this.$form.find("input[type=submit]").click(this, function(event) {
            event.preventDefault();
            event.stopPropagation();
            event.data.calculate(true);
        });

        let _calc = this;
        this.calc_fields.QRemType.$el.on("change", function(event) {
            _calc.display_reset(); // changed type, which resets fields, clear the output.
            _calc.haveCalculated = false;
        });
    }

    get_value_lower(field) {
        let v = field.getValue(true);
        return !v ? undefined : v.toLowerCase();
    }

    get_value_number(field) {
        let v;
        if (field instanceof inz.forms.fields.Number)
            v = field.getNumberValue();
        else
            v = field.getValue(true);
        return !v ? undefined : Number(v);
    }

    /*changeEvent(event) {
        super.changeEvent(event);
    }*/

    focusOut(event) {
        let form = $(this).parents("form").data("form");
        //super.focusOut(event);
        if (form.haveCalculated) {
            form.calculate(false);
        }
    }

    calculate(calc_from_submit) {
        // is it valid?
        if (!this.$form.parsley().validate()) {
            console.log("Not valid");
            return;
        }

        this.haveCalculated = true;
        let log = [];
        let reg = {};
        reg.MedianWage = this.MedianWage;
        // type
        let calc_type = this.get_value_lower(this.calc_fields.QRemType);
        if (!calc_type) {
            console.log("Not Type!");
            return;
        }
        let calc_result;
        if (calc_type.startsWith('s')) {
            calc_result = this.calc_salary(log, reg);
        } else {
            calc_result = this.calc_hourly(log, reg);
        }

        if (calc_result) {
            // MWC-BR-10
            // MWC-BR-11
            log.push("MWC-BR-10");
            log.push("MWC-BR-11");
            let MedComp1, MedComp2;
            if(reg.VRemRate<reg.MedianWage) {
                reg.MedComp1 = "BELOW";
                reg.MedComp2 = "below";
            } else if(reg.VRemRate>reg.MedianWage) {
                reg.MedComp1 = "ABOVE";
                reg.MedComp2 = "above";
            } else {
                reg.MedComp1 = "EQUAL";
                reg.MedComp2 = "equal to";
            }
            this.display_output(log, reg);
        } else {
            this.display_error(log, reg);
        }

        if (this.$form.find('.calc_debug').length !== 0) {
            let debug_output = "<label>Values:</label>\n<ul>";
            for(const v in reg) {
                if (v.startsWith('Q') && (reg[v] === undefined || (typeof reg[v] == 'string' && reg[v] === 'NaN') || (typeof reg[v] == 'number' && isNaN(reg[v])))) {
                    debug_output += `\t<li>${v} = ${reg[v]} <span style="color: red;"><b>(Missing Field Value?)</b></span></li>\n`;
                } else {
                    debug_output += `\t<li>${v} = ${reg[v]}</li>\n`;
                }
            }
            debug_output += "</ul><label>Applied Rules:</label>\n<ul>";
            for(const rule in log) {
                debug_output += `\t<li>${log[rule]}</li>\n`;
            }
            debug_output += "</ul>";
            this.$form.find('#calc_debug_log').html(debug_output);
            this.$form.find('.calc_debug').show();
        }
    }

    calc_salary(log, reg) {
        let f = this.calc_fields.Salary;
        reg.QEmpAgreHours = this.get_value_lower(f.QEmpAgreHours);
        reg.QPayPeriod = this.get_value_lower(f.QPayPeriod);
        reg.QEmpAgreBen = this.get_value_lower(f.QEmpAgreBen);
        reg.QSalMin = this.get_value_number(f.QSalMin);

        if (reg.QEmpAgreHours === undefined ||
            reg.QPayPeriod === undefined ||
            reg.QEmpAgreBen === undefined ||
            reg.QSalMin === undefined) {
            return false;
        }

        switch (reg.QPayPeriod) {
            case 'weekly':
                reg.VPayPeriodsPerYear = 52;
                reg.VPayPeriodsDays = 7;
                break;
            case 'fortnightly':
                reg.VPayPeriodsPerYear = 26;
                reg.VPayPeriodsDays = 14;
                break;
            case 'monthly':
                reg.VPayPeriodsPerYear = 12;
                reg.VPayPeriodsDays = 31;
                break;
        }

        reg.VBenRate = 0.0;
        if (reg.QEmpAgreHours .startsWith("hours s")) {
            // MWC-BR-03
            log.push("MWC-BR-03");
            reg.QHoursMax = this.get_value_number(f.QHoursMax);
            if (reg.QHoursMax === undefined)
                return false;

            reg.VBaseRate = reg.QSalMin / reg.VPayPeriodsPerYear / reg.QHoursMax;

            if (reg.QEmpAgreBen.startsWith('y')) {
                // MWC-BR-07
                log.push("MWC-BR-07");
                reg.QBenPayPeriodValue = this.get_value_number(f.QBenPayPeriodValue);
                if (reg.QBenPayPeriodValue === undefined)
                    return false;
                reg.VBenRate = reg.QBenPayPeriodValue/reg.QHoursMax;
            }
        } else if (reg.QEmpAgreHours.startsWith("rev")) {
            // MWC-BR-04
            log.push("MWC-BR-04");
            reg.QDaysOn = this.get_value_number(f.QDaysOn);
            reg.QDaysOff = this.get_value_number(f.QDaysOff);
            reg.QHoursPShift = this.get_value_number(f.QHoursPShift);
            if (reg.QDaysOn === undefined || reg.QDaysOff === undefined || reg.QHoursPShift === undefined)
                return false;

            reg.VShiftPattern = reg.QDaysOn + reg.QDaysOff;
            reg.VRotations = Math.floor(reg.VPayPeriodsDays / reg.VShiftPattern);
            reg.VRemainder = reg.VPayPeriodsDays - (reg.VRotations * reg.VShiftPattern);
            reg.VAddOn = (reg.VRemainder >= reg.QDaysOn) ? reg.QDaysOn : reg.VRemainder;
            reg.VHoursPerDay = reg.QHoursPShift / reg.QDaysOn;

            //let VMaxPayPeriodDays;
            if (reg.QDaysOn >= reg.VPayPeriodsDays) {
                reg.VMaxPayPeriodDays = reg.VPayPeriodsDays;
            } else {
                reg.VMaxPayPeriodDays = (reg.VShiftPattern >= reg.VPayPeriodsDays) ? reg.QDaysOn : (reg.VRotations * reg.QDaysOn) + reg.VAddOn;
            }
            reg.VBaseRate = reg.QSalMin / reg.VPayPeriodsPerYear / reg.VHoursPerDay / reg.VMaxPayPeriodDays;

            if (reg.QEmpAgreBen.startsWith('y')) {
                // MWC-BR-08
                log.push("MWC-BR-08");
                reg.QBenPayPeriodValue = this.get_value_number(f.QBenPayPeriodValue);
                if (reg.QBenPayPeriodValue === undefined)
                    return false;

                reg.VBenRate = reg.QBenPayPeriodValue / (reg.VHoursPerDay * reg.VMaxPayPeriodDays);
            }
        } else {
            return false; // not specified, can't calc
        }

        reg.VRemRate = (reg.VBaseRate + reg.VBenRate).toFixed(2);
        return true;
    }

    calc_hourly(log, reg) {
        let f = this.calc_fields.Hourly;
        reg.QVariableRate = this.get_value_lower(f.QVariableRate);
        reg.QEmpAgreBen = this.get_value_lower(f.QEmpAgreBen);
        reg.QHourlyWage = this.get_value_number(f.QHourlyWage);
        if (reg.QVariableRate === undefined || reg.QEmpAgreBen === undefined || reg.QHourlyWage === undefined)
            return false;

        if (reg.QVariableRate.startsWith('n')) {
            // MWC-BR-01
            log.push("MWC-BR-01");
            reg.VBaseRate = reg.QHourlyWage;
        } else {
            // MWV-BR-02
            log.push("MWC-BR-02");
            reg.QHourMinWage = this.get_value_number(f.QHourMinWage);
            reg.QMaxWage = this.get_value_number(f.QMaxWage);
            reg.QHourMaxWage = this.get_value_number(f.QHourMaxWage);
            if (reg.QHourMinWage === undefined || reg.QMaxWage === undefined || reg.QHourMaxWage === undefined)
                return false;

            reg.VBaseRate = ((reg.QHourlyWage * reg.QHourMinWage) + (reg.QMaxWage * reg.QHourMaxWage)) / (reg.QHourMinWage + reg.QHourMaxWage);
        }

        reg.VBenRate = 0.0;
        if (reg.QEmpAgreBen.startsWith('y')) {
            reg.QBenHourly = this.get_value_lower(f.QWageBenHourly);
            if (reg.QBenHourly === undefined)
                return false;
            if(reg.QBenHourly.startsWith('y')) {
                // MWC-BR-05
                log.push("MWC-BR-05");
                reg.VBenRate = this.get_value_number(f.QWageBenHourlyRate);
                if (reg.VBenRate === undefined)
                    return false;
            } else {
                // MWC-BR-06
                log.push("MWC-BR-06");
                reg.QBenPayPeriodValue = this.get_value_number(f.QBenPayPeriodValue);
                reg.QBenPayPeriodHours = this.get_value_number(f.QBenPayPeriodHours);
                if (reg.QBenPayPeriodValue === undefined || reg.QBenPayPeriodHours === undefined)
                    return false;
                reg.VBenRate = reg.QBenPayPeriodValue / reg.QBenPayPeriodHours;
            }
        }

        reg.VRemRate = (reg.VBaseRate + reg.VBenRate).toFixed(2);
        return true;
    }

    replace_vars(src, reg) {
        let dst = src.replace(this.rVar, (match, $1) => {
            //console.log(`1 = ${$1}`);
            let v = reg[$1];
            return v !== undefined ? v : "";
        });
        return dst;
    }

    display_output(log, reg) {
        let $output = this.$form.find("#output-banner");
        $output.toggleClass("form_banner_success", true);
        $output.toggleClass("banner__alert ", false);
        $output.find(".banner_icon").toggleClass("banner_icon__comms", true);
        $output.find(".banner_icon").toggleClass("banner_icon__alert", false);

        let heading = this.replace_vars(this.outputHeading, reg);
        $output.find(".banner_title").text(heading);

        let body = this.replace_vars(this.outputBody, reg);
        $output.find(".banner_text").html(body);
        $output.show();
    }

    display_error(log, reg) {
        let $output = this.$form.find("#output-banner");
        $output.toggleClass("form_banner_success", false);
        $output.toggleClass("banner__alert ", true);
        $output.find(".banner_icon").toggleClass("banner_icon__comms", false);
        $output.find(".banner_icon").toggleClass("banner_icon__alert", true);

        let heading = this.replace_vars(this.errorHeading, reg);
        $output.find(".banner_title").text(heading);

        let body = this.replace_vars(this.errorBody, reg);
        $output.find(".banner_text").html(body);
        $output.show();
    }

    display_reset() {
        let $output = this.$form.find("#output-banner");
        $output.hide();
        this.$form.find('.calc_debug').hide();
    }

    round(num, decimalPlaces = 0) {
        var p = Math.pow(10, decimalPlaces);
        var n = (num * p) * (1 + Number.EPSILON);
        return Math.round(n) / p;
    }
}


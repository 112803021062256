/**
 * Field
 */

inz.forms.fields = inz.forms.fields || {};
//inz.forms.fields.Field = function() { };
inz.forms.fields.Field = class {

    static STATE_HIDDEN = 0;
    static STATE_NORMAL = 1;
    static STATE_READ_ONLY = 2;
    static STATE_MAP = {
        "HIDE": 0,
        "SHOW": 1,
        "SHOW_RO": 2,
    }

    init(form, $ctx, config) {
        this.form = form;
        this.config = config;
        this.name = config.name;
        this.id = config.id;
        this.display = config.display;
        //this.state = config.state;
        this.state = null;
        this.validation = true;
        this.isHidden = (this.display !== inz.forms.fields.Field.STATE_HIDDEN);  // set to opposite

        this.$el = $ctx.find("#f_"+this.id).first();
        this.$container = $ctx.find("#c_"+this.id).first();

        this.$el.data("field", this);
        this.$el.addClass("obj_form_field");
        this.$container.data("field", this);
        this.$container.addClass("obj_form_container");

        if(this.$container.parents(".form_group_tmpl").length !== 0)
            this.inGroupTmpl = true;
        else
            this.inGroupTmpl = false;

        this.children = [];
        this.parent = null;
        this.group = null;
        this.conditions = [];
        this.processAll = config.processAll!==undefined?config.processAll:false;
        this.dependants = [];

        if ("group" in config)
            this.group = this.form.getField(config["group"]);

        if ("parent" in config && this.group === null) {
            let parent = this.form.getField(config.parent);
            if(parent === null)
                console.log("Form Error: Parent: "+config.parent+" not in fields registry");
            else {
                this.parent = parent;
                this.parent.addChild(this);
            }
        }

        // console.log("New "+config.type+" Field: "+this.id+" name: "+this.name);
        if ("edit_link" in config) {
            // console.log("Edit Link:" + config.edit_link);
            let edit_label;
            switch(config.type) {
                case 'FieldGroup':
                    edit_label = 'Edit Field Group';
                    break;
                case 'Fieldset':
                    edit_label = 'Edit Field Set';
                    break;
                default:
                    edit_label = 'Edit';
            }
            let edit_btn = `<a class="form-edit-link inz_edit_button inz_edit_button_dark icon-edit" target="_blank" href="${config.edit_link}">${edit_label}</a>`;
            if(config.type === 'FieldGroup') {
                this.$container.find('.form_actions').prepend($(edit_btn));
            } else {
                this.$container.prepend($(edit_btn));
            }
        }
    }

    addConditions(conditions) {
        for(let i = 0; i<conditions.length; i++) {
            let cond;
            if(inz.forms.resv.isResCondition(conditions[i])) {
                cond = Object.create(inz.forms.resv.Condition.prototype);
                this.form.resvConditionFields.push(this);
            } else {
                cond = Object.create(inz.forms.Condition.prototype);
            }
            if(cond.init(this.form, this, conditions[i]))
                this.conditions.push(cond);
        }
    }

    addCondition(condition) {
        let cond;
        if(inz.forms.resv.isResCondition(condition)) {
            cond = Object.create(inz.forms.resv.Condition.prototype);
            this.form.resvConditionFields.push(this);
        } else {
            cond = Object.create(inz.forms.Condition.prototype);
        }
        if(cond.init(this.form, this, condition))
            this.conditions.push(cond);
    }

    addChild(child) {
        this.children.push(child);
    }

    addDependant(dependant) {
        //if(dependant in this.dependants)
        if (this.dependants !== undefined && this.dependants.indexOf(dependant) !== -1)
            return;
        this.dependants.push(dependant);
    }

    removeDependant(dependant) {
        let i = this.dependants.indexOf(dependant);
        if(i> -1)
            this.dependants.splice(i, 1);
    }

    depChanged(field, value) { }    // called on dependant change

    addChildren(children) {
        $.merge(this.children, children);
    }

    setup(initial) {
        this.$el.change(this, this.form.changeEvent);
        this.$el.on("focusout", this.form.focusOut);
        this.defaultDisplay();
        // if (initial !== undefined && initial)
        //     this.initialError();
    }

    initialError() {
        if("error" in this.config) {
            let msg = this.config["error"];
            if(msg === null)
                msg = "There is an error with this field";
            let field = this.$el.parsley();
            //window.ParsleyUI.addError(field, "initialError", msg);
            window.ParsleyUI.addError(field, "custom-error-message", msg);
            //$(this.getErrorContainer()).append("<span class='err-msg filled' role='alert'><span class='parsley-custom-error-message'>"+msg+"</span></span>");
        }
    }

    defaultDisplay(no_reset) {
        if(this.display === inz.forms.fields.Field.STATE_NORMAL || this.display === inz.forms.fields.Field.STATE_READ_ONLY) {
            if(this.isHidden) {
                this.show(undefined, no_reset);
                return true;
            }
            this.setState(this.display, false);
        } else if(this.display === inz.forms.fields.Field.STATE_HIDDEN) {
            if(!this.isHidden) {
                this.hide(undefined, no_reset);
                return false;
            }
            this.setState(this.display, false);
        }
    }

    createCopy($ctx, id, name) {
        let copy = Object.create(this);
        copy.init(this.form, $ctx, this.config);
        copy.setId(id);
        copy.setName(name);
        if (this.form instanceof inz.forms.SMCAppForm && this.dependants !== null && this.dependants.length!==0) {
            // retain dependants for SMCAppForm only so as not to change behaviour in other forms
            copy.dependants = this.dependants.slice();  // copy of array
        } else {
            copy.dependants = [];
        }
        copy.isHidden = this.isHidden;  // retain state
        return copy;
    }

    inGroup() { return this.group!==null; }

    getName() { return this.$el.attr("name"); }

    setName(name) {
        this.name = name;
        this.$el.attr("name", name);
    }

    getId() { return this.$el.attr("id"); }

    setId(id) {
        this.id = id;
        this.$el.attr("id", "f_"+id);
        this.$container.attr("id", "c_"+id);
    }

    hasValue() { return true; };

    useGetOnSubmit() { return false; };

    getElement() { return this.$el; };

    getName() { return this.name; };

    getValue() { return this.$el.val(); };

    setValue(value) { this.$el.val(value).change(); };

    reset() { this.$el.val('').change(); };

    setRequired(req) {
        if(req) {
            let $label = this.$container.find("label").first();
            if($label.find("span.req").length === 0) {
                $label.append("<span class=\"req\">*</span>");
            }
            this.$el.attr("required", "required");
        } else {
            this.$container.find("label span.req").remove();
            this.$el.removeAttr("required");
        }
    }

    stateChange(state) {
        if(state === inz.forms.fields.Field.STATE_READ_ONLY) {
            this.$el.attr("readonly", "readonly");
            this.$container.attr("data-st", "ro");
            this.disableValidation();
            this.clearValidation();
        } else if (state === inz.forms.fields.Field.STATE_NORMAL) {
            this.$el.removeAttr("readonly");
            this.$container.attr("data-st", "rw");
            this.enableValidation();
        }
    }

    enableValidation() {
        this.$el.removeClass('disable_val');
        this.$el.removeAttr('disabled', 'disabled');
        this.validation = true;
    }

    disableValidation() {
        this.$el.addClass('disable_val');
        this.$el.attr('disabled', 'disabled');
        this.validation = false;
    }

    isDisabled() {
        let hidden = this.$el.is(":hidden");
        if (this.state === inz.forms.fields.Field.STATE_READ_ONLY && hidden === false) {
            return false;   // can have validation disabled, but be RO
        }
        return this.$el.hasClass('disable_val') || hidden;
    }

    clearValidation() {
        this.$container.find(".err-msg").remove();
        this.$container.find(".field__error").remove();
        this.$container.removeClass('parsley-error');
        //this.$container.find(".form_field.parsley-error").removeClass('parsley-error');
        this.$container.find(".has-error").removeClass('has-error');
    }

    getErrorContainer(el) {
        //return el.$element.closest(".form_field");
        let e = this.$container.find(".error-container");
        if(e.length === 0)
            return this.$container.find(".field-col");
        else
            return e;
    }

    show(initial, no_reset) {
        this.isHidden = false;
        if (no_reset === undefined || no_reset === false)
            this.reset();
        this.enableValidation();

        if(initial !== true) {
            for(let i = 0; i<this.children.length; i++) {
                //this.children[i].show();
                if(!this.form.settingValues)  // Change for BERI form issue
                    this.children[i].processConditions();  // default display, or whatever conditions
            }
        }
        this.$container.show(initial);

        if(this.form.isInAccordian)
            this.form.adjustHeight();
    }

    hide(initial, no_reset) {
        this.isHidden = true;
        this.$container.hide();
        if (no_reset === undefined || no_reset === false)
            this.reset();

        if(initial !== true) {
            for(let i = 0; i<this.children.length; i++) {
                this.children[i].hide();
            }
        }
        this.disableValidation();

        if(this.form.isInAccordian)
            this.form.adjustHeight();
    }

    setState(state, initial) {
        if(state !== null) { // && this.state !== state) {
            this.state = state;
            this.stateChange(state);
        }

        if(initial !== true) {
            for(let i = 0; i<this.children.length; i++) {
                this.children[i].setState(state, initial);
            }
        }

        if(this.form.isInAccordian)
            this.form.adjustHeight();
    }

    processConditions(src, value, no_default, no_reset) {
        let resultShow = null;
        let resultState = null;

        if (this.processAll)
            value = undefined;  // make value be fetched for each condition

        for(let i = 0; i<this.conditions.length; i++) {
            let cond = this.conditions[i];
            if(this.processAll || cond.shouldTest(src)) {
                if(cond.test(value)) {
                    if(cond.op === "SHOW") {
                        resultShow = true;
                        resultState = inz.forms.fields.Field.STATE_NORMAL;
                    } else if(cond.op === "SHOW_RO") {
                        resultShow = true;
                        resultState = inz.forms.fields.Field.STATE_READ_ONLY;
                    } else if(cond.op === "HIDE") {
                        resultShow = false;
                        resultState = null; // no state change
                    }
                    if (!this.processAll || cond.shouldStopProcessing())  // early exit if not processing all, or condition says to stop
                        return this.actionConditions(resultShow, resultState);
                }
            }
        }
        return this.actionConditions(resultShow, resultState, no_default, no_reset);
    }

    actionConditions(resultShow, resultState, no_default, no_reset) {

        let didShow = false;
        if (resultShow !== null) {
            if (this.isHidden && resultShow === true) {
                this.show(false, no_reset);
                didShow = true;
            } else if (!this.isHidden && resultShow === false) {
                this.hide(false, no_reset);
                didShow = false;
            }
        }

        if (resultState !== null && resultState !== this.state) {
            // state change
            this.setState(resultState, false);
        }

        if (didShow === false) {
            for(let i = 0; i<this.children.length; i++) {
                if(!this.form.settingValues)  // Change for BERI form issue
                    this.children[i].processConditions(undefined, undefined, false, true);  // default display, or whatever
            }
        }

        if (resultShow !== null || resultState !== null) {  // i.e. a condition passed test
            return didShow;
        }

        // nothing matched, do default
        if (no_default === undefined || no_default === false)
            return this.defaultDisplay(no_reset);
    }
}

/*                    if(cond.op === "SHOW") {
                        if(this.isHidden) {
                            this.show(false, no_reset);
                            did_show = true;
                        }
                        if(this.state !== inz.forms.fields.Field.STATE_NORMAL) {   // update state if needed
                            this.setState(inz.forms.fields.Field.STATE_NORMAL, false);
                        }
                    } else if(cond.op === "SHOW_RO") {
                        if(this.isHidden) {
                            this.show(false, no_reset);
                            did_show = true;
                        }
                        if(this.state !== inz.forms.fields.Field.STATE_READ_ONLY) {   // update state if needed
                            this.setState(inz.forms.fields.Field.STATE_READ_ONLY, false);
                        }
                    } else if(cond.op === "HIDE") {
                        if(!this.isHidden) {
                            this.hide(false, no_reset);
                        }
                    }
                    return did_show;
*/


/**
 * Check Box Field
 */

inz.forms.fields.CheckBoxField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.CheckBoxField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.CheckBoxField.prototype.constructor = inz.forms.fields.CheckBoxField;

inz.forms.fields.CheckBoxField.prototype.getValue = function() { return this.$el.is(':checked') ? this.$el.val() : null; };
inz.forms.fields.CheckBoxField.prototype.setValue = function(value)
{
    if (value === null || value === undefined)
        this.$el.removeAttr('checked');
    else
        this.$el.find('input[type=checkbox][value=\"'+inz.forms.escapeHTML(value)+'\"]').prop('checked', true);
    this.$el.find("input[type='checkbox']").first().change();
};

inz.forms.fields.CheckBoxField.prototype.reset = function()
{
    $('input[name="'+this.name+'"]:checked').removeAttr('checked');
    this.$el.find("input[type='checkbox']").first().change();
};

inz.forms.fields.CheckBoxField.prototype.setup = function(initial)
{
    this.$el.find("input[type='checkbox']").change(this, this.form.changeEvent);
    this.defaultDisplay();
};


/**
 * Check Boxes Field
 */

inz.forms.fields.CheckBoxesField = function() { inz.forms.fields.Field.prototype.call(this); };
inz.forms.fields.CheckBoxesField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.CheckBoxesField.prototype.constructor = inz.forms.fields.CheckBoxesField;
inz.forms.fields.CheckBoxesField.prototype.useGetOnSubmit = function() { return true; }

inz.forms.fields.CheckBoxesField.prototype.getValue = function(real)
{
    if (real === undefined)
        real = false;
    let vals = [];
    let s = this.$container.find('input:checked');
    for(let i = 0; i<s.length; i++) {
        if (real)
            vals.push($(s[i]).val());
        else
            vals.push(s[i].getAttribute("data-ouid"));  // match via id now
        //vals.push($(s[i]).val());
    }
    return vals;
};

inz.forms.fields.CheckBoxesField.prototype.setValue = function(values)
{
    this.$container.find('input:checked').removeAttr('checked'); // clear old
    if (values === null || values === undefined) {
        this.$container.find('input:checked').removeAttr('checked');
    } else {
        for (let i=0;i<values.length;i++) {
            if (values[i].startsWith("__oid_")) {
                this.$container.find("input[data-ouid='"+values[i]+"']").prop('checked', true);
                //this.$container.find("#"+values[i].substring(6)).prop('checked', true);
            } else {
                this.$container.find('input[type=checkbox][value=\"'+inz.forms.escapeHTML(values[i])+'\"]').prop('checked', true);
            }
        }
    }
    this.$el.find("input[type='checkbox']").first().change();
};

inz.forms.fields.CheckBoxesField.prototype.reset = function()
{
    this.$container.find('input:checked').removeAttr('checked');
    this.$el.find("input[type='checkbox']").first().change();
};

inz.forms.fields.CheckBoxesField.prototype.setup = function(initial)
{
    this.$el.find("input[type='checkbox']").change(this, this.form.changeEvent);
    this.$el.find("input[type='checkbox']").on("focusout", this.form.focusOut);
    this.defaultDisplay();
};

inz.forms.fields.CheckBoxesField.prototype.setName = function(name)
{
    this.name = name;
    //this.$el.attr("name", name);
    this.$el.find("input").each(function(idx)
    {
        $(this).attr("name", name);
    });
};

inz.forms.fields.CheckBoxesField.prototype.setId = function(id)
{
    inz.forms.fields.Field.prototype.setId.call(this, id);
    var rb = this;
    // update button options
    this.$el.find("input").each(function(idx)
    {
        var $e = $(this);
        var oldId = $e.attr("id");
        var newId = "opt_"+id+String(idx);
        $e.attr("id", "opt_"+id+String(idx));
        $e.attr("data-parsley-multiple", id);
        rb.$el.find("label[for='"+oldId+"']").attr("for", newId);
    });
};

inz.forms.fields.CheckBoxesField.prototype.enableValidation = function()
{
    this.$el.removeClass('disable_val');
    this.$el.find("input").removeClass('disable_val');
    this.$el.find("input").removeAttr('disabled', 'disabled');
};

inz.forms.fields.CheckBoxesField.prototype.disableValidation = function()
{
    this.$el.addClass('disable_val');
    this.$el.find("input").addClass('disable_val');
    this.$el.find("input").attr('disabled', 'disabled');
};


/**
 * Text Area
 */

inz.forms.fields.TextAreaField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.TextAreaField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.TextAreaField.prototype.constructor = inz.forms.fields.TextAreaField;

inz.forms.fields.TextAreaField.prototype.setup = function(initial)
{
    this.$el.keyup(this, this.form.changeEvent);
    this.$el.on("focusout", this.form.focusOut);
    this.defaultDisplay();
};


/**
 * Date Picker Field
 */

inz.forms.fields.DatePickerField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.DatePickerField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.DatePickerField.prototype.constructor = inz.forms.fields.DatePickerField;

// inz.forms.DatePickerField.prototype.getValue = function() { return this.$container.find('input:checked').val(); };
// inz.forms.DatePickerField.prototype.reset = function() { this.$container.find('input:checked').removeAttr('checked'); };

inz.forms.fields.DatePickerField.prototype.setup = function(initial)
{
    this.setupPickers();
    this.$el.change(this, this.form.changeEvent);
    this.$el.on("focusout", this.form.focusOut);
    this.defaultDisplay();
};

inz.forms.fields.DatePickerField.prototype.setupPickers = function()
{
    //Loop through each calendar and set it up
    //$(context).find('.calendarWrap, .aeCalendarWrap').each(function()
    this.$container.find('.calendarWrap').each(function()
    {
        var cWrap = $(this);
        var cLink = cWrap.find('.datePicker');
        var cInput = cWrap.find('.input');
        var cid = cWrap.attr('id');
        // Syntax : setCalendar( ID , TriggeringElement , TargetEditField , EnableComments , clickHandler , config )
        $A.setCalendar(cWrap.attr('id'), cLink[0], cInput[0], false,
            function(ev, dc, targ)
            { // targ is the Input field
                // Save the desired date string
                //targ.value = dc.range.current.mDay + ' ' + dc.range[dc.range.current.month].name + ' ' + dc.range.current.year;
                // The above doesn't work in IE9. Set the value like this instead:
                var dateVal = dc.range.current.mDay+' '+dc.range[dc.range.current.month].name+' '+dc.range.current.year;
                $(targ).val(dateVal);

                //Remove error message if exists when date is selected
                var field = $(targ).closest('.form_field');
                //if(field.hasClass('parsley-error')){
                field.removeClass('parsley-error').addClass('parsley-success');
                $(targ).parent().find('.err-msg').remove();
                //}
                $(targ).parsley().validate();
                // Then close the date picker
                dc.close();
                $(targ).trigger("change");
            },
            {
                ajax: function(dc, save)
                {
                    // Run before the datepicker renders
                    if(!$(cLink[0]).data('dependant'))
                    {
                        // no data attr
                        dc.open();
                        return;
                    }
                    var dependentInput = $('#'+$(cLink[0]).data('dependant'));
                    if(dependentInput.length == 0)
                    {
                        // ID doesn't exist
                        dc.open();
                        return;
                    }
                    var dependantDateString = dependentInput.val();
                    if(dependantDateString == '')
                    {
                        // dependant value hasn't been set
                        dc.open();
                        return;
                    }
                    var dependentDate = new Date(Date.parse(dependantDateString));

                    var dependentCurrent = {
                        day: dependentDate.getDate(),
                        month: dependentDate.getMonth(),
                        year: dependentDate.getFullYear(),
                        weekDay: dependentDate.getDay()
                    };

                    // clear out disabled ranges
                    dc.range[dc.range.current.month].disabled[dc.range.current.year] = [];
                    // Disable all dates prior to the current day
                    if(dependentCurrent.year>dc.range.current.year || (dependentCurrent.year === dc.range.current.year && dependentCurrent.month>dc.range.current.month))
                    {
                        // console.log('disable all');
                        dc.range[dc.range.current.month].disabled[dc.range.current.year] =
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
                    }

                    if(dependentCurrent.year === dc.range.current.year && dependentCurrent.month === dc.range.current.month)
                    {
                        for(var day = 1; day<=dependentCurrent.day; day++)
                        {
                            dc.range[dc.range.current.month].disabled[dc.range.current.year].push(day);
                        }
                    }

                    dc.open();
                },
                autoPosition: 0,
                offsetTop: 0,
                offsetLeft: 0,
                months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
                days: [ { s: 'Su', l: 'Sunday'}, { s: 'Mo', l: 'Monday' }, { s: 'Tu', l: 'Tuesday' }, { s: 'We', l: 'Wednesday' }, { s: 'Th', l: 'Thursday' }, { s: 'Fr', l: 'Friday' }, { s: 'Sa', l: 'Saturday' } ]
            }
        );
        $(cInput).click(function() {
            $(cLink).trigger('click');
        });
    });
};

/**
 * Date Range Field
 */

inz.forms.fields.DateRangeField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.DateRangeField.prototype = Object.create(inz.forms.fields.DatePickerField.prototype);
inz.forms.fields.DateRangeField.prototype.constructor = inz.forms.fields.DateRangeField;

inz.forms.fields.DateRangeField.prototype.setup = function(initial)
{
    this.setupPickers();
    this.defaultDisplay();

    this.$from = this.$el.find("input#f_"+this.id+"_from");
    this.$to = this.$el.find("input#f_"+this.id+"_to");
};

inz.forms.fields.DateRangeField.prototype.getValue = function()
{
    var value = this.$from.val()+" to "+this.$to.val();
    return value;
};

inz.forms.fields.DateRangeField.prototype.setValue = function(value)
{
    if (values === undefined)
    {
        this.reset();
        return;
    }

    var values = value.split(' to ');
    if (values.length !=2)
        return;
    this.$from.val(values[0]).change();
    this.$to.val(values[1]).change();
};

inz.forms.fields.DateRangeField.prototype.initialError = function()
{
    if ("error" in this.config)
    {
        window.ParsleyUI.addError(this.$from.parsley(), "custom-error-message", this.config["error_from"]);
        window.ParsleyUI.addError(this.$to.parsley(), "custom-error-message", this.config["error_to"]);
    }
};

inz.forms.fields.DateRangeField.prototype.setName = function(name)
{
    this.name = name;
    this.$el.find("input").each(function(idx)
    {
        var oldName = $(this).attr("name");
        if (oldName.endsWith("_from"))
            $(this).attr("name", name+"_from");
        else if (oldName.endsWith("_to"))
            $(this).attr("name", name+"_to");
        else
            $(this).attr("name", name);
    });
};

inz.forms.fields.DateRangeField.prototype.setId = function(id)
{
    inz.forms.fields.Field.prototype.setId.call(this, id);
    var dr = this;

    this.$el.find(".calendarWrap").each(function()
    {
        var oldId = $(this).attr("id");
        if (oldId.endsWith("_from"))
            $(this).attr("id", "c_" + id + "_from");
        else if (oldId.endsWith("_to"))
            $(this).attr("id", "f_" + id + "_to");
    });

    this.$el.find("input").each(function(idx)
    {
        var oldId = $(this).attr("id");
        var newId;
        if (oldId.endsWith("_from"))
            newId = "f_" + id + "_from";
        else if (oldId.endsWith("_to"))
            newId = "f_" + id + "_to";

        $(this).attr("id", newId);
        var a =dr.$el.find("a[data-name='"+oldId+"']").first();
        a.attr("aria-describedby", newId);
        a.attr("data-name", newId);
        if (oldId.endsWith("_to"))
            a.attr("data-dependant", "f_" + id + "_from");
    });

    this.$from = this.$el.find("input#f_"+this.id+"_from");
    this.$to = this.$el.find("input#f_"+this.id+"_to");
};

inz.forms.fields.DateRangeField.prototype.enableValidation = function()
{
    this.$el.removeClass('disable_val');
    this.$el.find("input").removeClass('disable_val');
    this.$el.find("input").removeAttr('disabled', 'disabled');
};

inz.forms.fields.DateRangeField.prototype.disableValidation = function()
{
    this.$el.addClass('disable_val');
    this.$el.find("input").addClass('disable_val');
    this.$el.find("input").attr('disabled', 'disabled');
};

inz.forms.fields.DateRangeField.prototype.getErrorContainer = function(el)
{
    if (el.$element.attr("id").endsWith("_from"))
        return this.$el.find(".err_container_from");
    if (el.$element.attr("id").endsWith("_to"))
        return this.$el.find(".err_container_to");
};

/**
 * PAF Field
 */

inz.forms.fields.PAFField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.PAFField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.PAFField.prototype.constructor = inz.forms.fields.PAFField;

inz.forms.fields.PAFField.prototype.init = function(form, $ctx, config)
{
    inz.forms.fields.Field.prototype.init.call(this, form, $ctx, config);
    this.reId = new RegExp("f_([a-zA-Z0-9]{7,})__(\\d+)");
};

// inz.forms.PAFField.prototype.getValue = function() { return this.$container.find('input:checked').val(); };
inz.forms.fields.PAFField.prototype.reset = function() { this.$el.val("").trigger("change"); };

inz.forms.fields.PAFField.prototype.createCopy = function($ctx, id, name)
{
    var copy = inz.forms.fields.Field.prototype.createCopy.call(this, $ctx, id, name);
    copy.$container.find("span.select2").remove();
    return copy;
};

inz.forms.fields.PAFField.prototype.setup = function(initial)
{
    var pafFieldSelects = this.$container.find('select.paf_field');
    pafFieldSelects.select2();
    //pafFieldSelects.on('select2:select', inz.forms.pafField.onSelect);
    pafFieldSelects.on('select2:select', function(e) {
        $(this).parents(".paf_field").data("field").onSelect(e);
    });
    this.defaultDisplay();
};

inz.forms.fields.PAFField.prototype.onSelect = function(e)
{
    var updates = e.params.data['updates'];
    for(var fieldId in updates)
    {
        if(updates.hasOwnProperty(fieldId))
        {
            this.findElementById(fieldId, e.target).val(updates[fieldId]).trigger('change');
        }
    }
};

inz.forms.fields.PAFField.prototype.findElementById = function(rawId, context)
{
    if (this.reId.test(context.id))
    {
        var idx = context.id.match(this.reId)[2];
        return $("#"+rawId+"__"+String(idx));
    }
    return $('#'+rawId);
};

/**
 * File Field
 */

inz.forms.fields.FileField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.FileField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.FileField.prototype.constructor = inz.forms.fields.FileField;

inz.forms.fields.FileField.prototype.init = function(form, $ctx, config)
{
    inz.forms.fields.Field.prototype.init.call(this, form, $ctx, config);
};

inz.forms.fields.FileField.prototype.setValue = function(value) { this.$el.val(""); }; // can't set file inputs

/**
 * Text Message Field
 */

inz.forms.fields.TextMessageField = function() {
    inz.forms.fields.Field.call(this);
    this.interlock = 0;
};

inz.forms.fields.TextMessageField.interlock = 0;
inz.forms.fields.TextMessageField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.TextMessageField.prototype.constructor = inz.forms.fields.TextMessageField;

inz.forms.fields.TextMessageField.prototype.getValue = function() { if (this.isHidden) {return null;} else {return this.$el.find("input[type='hidden']").val();} };
inz.forms.fields.TextMessageField.prototype.setValue = function(value)
{
    this.$el.find("input[type='hidden']").val(value).change();
};

inz.forms.fields.TextMessageField.prototype.setContent = function(title, note) {
    let $note, $title = null;
    if (this.config['style'] === "NOTE") {
        $note = this.$container.find("div.exp_text");
        $title = this.$container.find("div.exp_title");
    } else if (this.config['style'] === "TEXT") {
        $note = this.$container.find("div.rt_content");
        $title = null;
    } else if (this.config['style'] === "ALERT" || this.config['style'] === "MESSAGE") {
        $note = this.$container.find("div.banner_textcopy");
        $title = this.$container.find(".banner_title");
    }
    if (Boolean(title) && $title !== null) {
        $title.html(title);
    }
    $note.html(note);
    //this.$container.find("div.exp_text").html(note);
};

inz.forms.fields.TextMessageField.prototype.reset = function()
{
    this.$el.find("input[type='hidden']").change();
};

inz.forms.fields.TextMessageField.prototype.setup = function(initial)
{
    /* The hidden value will never actually change */
    this.$el.change(this, this.form.changeEvent);
    this.defaultDisplay();
};

inz.forms.fields.TextMessageField.prototype.show = function(initial)
{
    inz.forms.fields.Field.prototype.show.call(this, initial);
    if (initial !== true)
    {
        if (this.$el.data('terminate') === 'True')
        {
            inz.forms.fields.TextMessageField.interlock += 1;
            this.interlock += 1; // keep track of our personal interlock contributions
            this.form.$form.find('.actions').hide();
            this.form.$form.find('.actions input').attr('disabled', 'disabled');
        }
    }
}

inz.forms.fields.TextMessageField.prototype.hide = function(initial)
{
    inz.forms.fields.Field.prototype.hide.call(this, initial);
    if (initial !== true)
    {
        if (this.$el.data('terminate') === 'True')
        {
            if (inz.forms.fields.TextMessageField.interlock > 0)
            {
                inz.forms.fields.TextMessageField.interlock -= this.interlock; // remove our contribution of interlocks
                if (inz.forms.fields.TextMessageField.interlock < 0) {
                    inz.forms.fields.TextMessageField.interlock = 0;
                }
            }
            this.interlock = 0; // no more interlock
            if (inz.forms.fields.TextMessageField.interlock == 0)
            {
                /* Show the form action buttons again */
                this.form.$form.find('.actions').show()
                this.form.$form.find('.actions input').removeAttr('disabled', 'disabled');
            }
        }
    }
}

inz.forms.fields.TextMessageField.prototype.isDisabled = function()
{
    if (this.$el.data('terminate') === 'True')
    {
        /* The field element itself is always hidden */
        return this.$el.hasClass('disable_val') || this.$container.is(":hidden");
    } else {
        // If we are not a terminal field, then we always decline to actually submit ourselves:
        return true;
    }
};

/**
 * Captcha Field
 */

inz.forms.fields.CaptchaField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.CaptchaField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.CaptchaField.prototype.constructor = inz.forms.fields.CaptchaField;

inz.forms.fields.CaptchaField.prototype.init = function(form, $ctx, config)
{
    inz.forms.fields.Field.prototype.init.call(this, form, $ctx, config);
    this.widgetId = null;
    this.$cc = this.$container.find('input[name=completed_captcha]');
    inz.forms.captchaField = this;
}

inz.forms.fields.CaptchaField.prototype.setup = function(initial) {
    //this.defaultDisplay();
    this.setupCaptcha();
}

inz.forms.fields.CaptchaField.prototype.setupCaptcha = function() {
    if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.render !== 'undefined' && this.widgetId === null) {
        this.widgetId = grecaptcha.render(this.$container.find(".g-recaptcha").get(0), {
            'sitekey': this.config['site_key'],
            'callback': inz.forms.captchaCallback,
            'expired-callback': inz.forms.captchaExpired
        });
    }
}

inz.forms.fields.CaptchaField.prototype.show = function(initial) { };
inz.forms.fields.CaptchaField.prototype.hide = function(initial) { };

inz.forms.fields.CaptchaField.prototype.isDisabled = function() {
    return false;
}

inz.forms.fields.CaptchaField.prototype.getValue = function() { return null; }
inz.forms.fields.CaptchaField.prototype.setValue = function(value) { }

inz.forms.fields.CaptchaField.prototype.reset = function()
{
    //console.log("Captcha Reset: "+this.widgetId);
    this.$cc.val('').change();
    if (this.widgetId !== null)
        grecaptcha.reset(this.widgetId);
}

inz.forms.captchaCallback = function() {
    inz.forms.captchaField.$cc.val('Captcha completed');
    inz.forms.captchaField.$container.find('.captcha_wrap .err-msg').remove();
    if (window.form && window.form.uLog)
        window.form.addLogEntry("Captcha", "C", "Completed");
}

inz.forms.captchaExpired = function() {
    inz.forms.captchaField.$cc.val('');
    if (window.form && window.form.uLog)
        window.form.addLogEntry("Captcha", "C", "Expired");
}

/**
 * Test Mode Field
 */

inz.forms.fields.TestModeField = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.TestModeField.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.TestModeField.prototype.constructor = inz.forms.fields.TestModeField;

inz.forms.fields.TestModeField.prototype.getValue = function() { if (this.isHidden) {return null;} else {return this.$el.find("input[type='hidden']").val();} };
inz.forms.fields.TestModeField.prototype.setValue = function(value)
{
    this.$el.find("input[type='hidden']").val(value).change();
};

inz.forms.fields.TestModeField.prototype.reset = function()
{
    this.$el.find("input[type='hidden']").change();
};

/**
 * Fieldset
 */

inz.forms.fields.Fieldset = function() { inz.forms.fields.Field.call(this); };
inz.forms.fields.Fieldset.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.Fieldset.prototype.constructor = inz.forms.fields.Fieldset;

inz.forms.fields.Fieldset.prototype.init = function(form, $ctx, config)
{
    inz.forms.fields.Field.prototype.init.call(this, form, $ctx, config);
    //this.reId = new RegExp("f_([a-zA-Z0-9]{7,})__(\\d+)");
};

// inz.forms.fields.PAFField.prototype.getValue = function() { return this.$container.find('input:checked').val(); };
inz.forms.fields.Fieldset.prototype.reset = function()
{ // process any conditions / dependants
    this.form.changeEvent({data: this});
};

/*inz.forms.fields.Fieldset.prototype.createCopy = function($ctx, id, name)
{
    var copy = inz.forms.fields.Field.prototype.createCopy.call(this, $ctx, id, name);
    copy.$container.find("span.select2").remove();
    return copy;
};*/

inz.forms.fields.Fieldset.prototype.setup = function(initial)
{
    this.defaultDisplay();
};

inz.forms.fields.Fieldset.prototype.hasValue = function() { return false; };

/**
 * Field Group Field
 */

inz.forms.fields.FieldGroup = function() {
};

inz.forms.fields.FieldGroup.prototype = Object.create(inz.forms.fields.Field.prototype);
inz.forms.fields.FieldGroup.prototype.constructor = inz.forms.fields.FieldGroup;

inz.forms.fields.FieldGroup.prototype.init = function(form, $ctx, config ){
    // name, prefix, minGroups, maxGroups
    inz.forms.fields.Field.prototype.init.call(this, form, $ctx, config);

    this.$container.addClass("obj_form_container");
    this.$template = this.$container.find(".form_group_tmpl").first();
    this.templateFields = [];
    this.prefix = config.prefix;
    this.minGroups = config.minGroups;
    this.maxGroups = config.maxGroups;
    this.disabledActions = false;

    this.groupTitleIndex = this.$container.find(".grp_title span.grp_idx").length !==0;
    this.groupIntroIndex = this.$container.find(".grp_intro span.grp_idx").length !==0;

    this.$groups = this.$container.find(".form_groups").first();
    // {prefix}[{idx}].{name}
    //this.reName = new RegExp(this.prefix+"\\[(\\d+)\\]\\.(.*)");
    //this.reName = new RegExp(this.prefix+"\\[\\d+\\]\\.(.*)");
    this.reName = new RegExp("g_"+name+"\\[\\d+\\]\\.(.*)");
    this.reId = new RegExp("f_([a-zA-Z0-9]{7,})__\\d+");

    if (this.groupTitle === "" && this.groupIntro === "")
        this.$template.find(".group_sep").remove();
        //this.$container.find(".form_group_tmpl .group_sep").remove();

//    this.$container.find(".form_add_item").on("click", function() { $(this).parents(".form_fieldgroup").data("field").addGroup(); } );
//    this.$container.find(".form_remove_item").on("click", function() { $(this).parents(".form_fieldgroup").data("field").removeGroup(); });
    let field = this;
    this.$container.find(".form_add_item").on("click", function() { field.addGroup(); } );
    this.$container.find(".form_remove_item").on("click", function() { field.removeGroup(); });
};

inz.forms.fields.FieldGroup.prototype.setup = function(initial) {
    // this.setupGroup(); // setup groups last for conditions etc
    this.templateFields = this.children;
    this.children = [];
    this.defaultDisplay();
};

inz.forms.fields.FieldGroup.prototype.disableActions = function() {
    this.disabledActions = true;
}

inz.forms.fields.FieldGroup.prototype.hideActions = function() {
    this.$container.find(".form_add_item").hide();
    this.$container.find(".form_remove_item").hide();
}

inz.forms.fields.FieldGroup.prototype.showActions = function() {
    this.$container.find(".form_add_item").show();
    this.$container.find(".form_remove_item").show();
}

// inz.forms.fields.FieldGroup.prototype.reset = function()
// {
//     this.form.changeEvent({data: this});
// };

inz.forms.fields.FieldGroup.prototype.setupGroup = function() {
    for (let i=0;i<this.templateFields.length;i++)
        this.templateFields[i].disableValidation();

    // var fg = this;
    // var $rec = this.$container.find(".form_group_record").first();
    // $rec.data("idx", 0);
    // this.setGroupTitle($rec, 0);
    // $rec.find(".obj_form_container").each(function()
    // {
    //     var field = $(this).data("field");
    //     field.setId(fg.createFullId(field.getId(), 0));
    //     field.setName(fg.createFullGroupName(field.getName(), 0));
    // });

    // we have minimum showing?
    if (this.minGroups>0)
    {
        for(let i = 0; i<this.minGroups; i++)
            this.addGroup();
    }
    this.processConditions();
    this.normaliseChildren();
    this.updateActions();
};

inz.forms.fields.FieldGroup.prototype.normaliseChildren = function() {
    if (this.isHidden)
    {
        for (let i=0;i<this.children.length;i++)
        {
            this.children[i].hide();
        }
    }
}

inz.forms.fields.FieldGroup.prototype.getValue = function() {
    let count = this.$container.find(".form_group").length;
    return {"count": count};
};

inz.forms.fields.FieldGroup.prototype.setValue = function(value) {
    if (!("count" in value))
        return;

    let curCount = this.$container.find(".form_group").length;
    let target = value.count;

    if (curCount === target)
        return;

    if (curCount < target)
    { // add some
        for (let i=curCount; i!==target; i++)
            this.addGroup();
    } else if (curCount > target)
    { // remove some
        for (let i=curCount; i!==target; i--)
            this.removeGroup(true);
    }
};

inz.forms.fields.FieldGroup.prototype.getCount = function(value) {
    return this.$container.find(".form_group").length;
}

inz.forms.fields.FieldGroup.prototype.stateChange = function(state) {
    inz.forms.fields.Field.prototype.stateChange.call(this, state);
    if(state === inz.forms.fields.Field.STATE_READ_ONLY) {
        // disable add / remove actions
        this.$container.find(".form_actions .form_remove_item").prop('disabled', true);
        this.$container.find(".form_actions .form_add_item").prop('disabled', true);
    } else if(state === inz.forms.fields.Field.STATE_NORMAL) {
        this.$container.find(".form_actions .form_remove_item").prop('disabled', false);
        this.$container.find(".form_actions .form_add_item").prop('disabled', false);
    }
}

// {prefix}[{idx}].{name}
//zero pad based on maximum, 999

inz.forms.fields.FieldGroup.prototype.createFullGroupName = function(name, idx) {
    let paddedIdx = String(idx); //.padStart(3, "0");
    if (this.reName.test(name))
        return "g_"+ this.id + "[" + paddedIdx + "]." + name.match(this.reName)[1];
    else
        return "g_"+ this.id + "[" + paddedIdx + "]." + name;
};

inz.forms.fields.FieldGroup.prototype.createFullId = function(id, idx) {
    if (this.reId.test(id))
        return id.match(this.reId)[1]+"__"+String(idx);
    else if (id.startsWith("f_"))
        return id.substring(2)+"__"+String(idx);
    else
        return id+"__"+String(idx);
};

inz.forms.fields.FieldGroup.prototype.createGroupId = function(id, idx) {
    return id+"__"+String(idx);
};

inz.forms.fields.FieldGroup.prototype.getGroupNamePrefix = function(idx) {
    if (this.group)
        this.group.getGroupNamePrefix(idx);
    return "g_"+ this.id + "[" + idx + "].";
};

inz.forms.fields.FieldGroup.prototype.createCopy = function($ctx, id, name)
{
    let copy = inz.forms.fields.Field.prototype.createCopy.call(this, $ctx, id, name);
    return copy;
};

inz.forms.fields.FieldGroup.prototype.hasValue = function() { return false; };

inz.forms.fields.FieldGroup.prototype.updateActions = function(idx) {
    if (this.disabledActions)
        return;

    if (idx === undefined)
        idx = this.$container.find(".form_group").length - 1;

    let cnt = 0;
    if (idx === this.minGroups-1) // can't remove more than min
        this.$container.find(".form_actions .form_remove_item").hide();  // this.$container.find(".form_actions .form_remove_item").prop('disabled', true).addClass("disabled");
    else {
        this.$container.find(".form_actions .form_remove_item").show();  // this.$container.find(".form_actions .form_remove_item").prop('disabled', false).removeClass("disabled");
        cnt += 1;
    }
    if (idx === this.maxGroups-1)
        this.$container.find(".form_actions .form_add_item").hide();  // this.$container.find(".form_actions .form_add_item").prop('disabled', true).addClass("disabled");
    else{
        this.$container.find(".form_actions .form_add_item").show();  // this.$container.find(".form_actions .form_add_item").prop('disabled', false).removeClass("disabled");
        cnt += 1;
    }
    if (cnt === 1)
        this.$container.find(".form_actions").addClass("single_action").remove("multi_action");
    else
        this.$container.find(".form_actions").removeClass("single_action").addClass("multi_action");
};

inz.forms.fields.FieldGroup.prototype.setGroupTitle = function($group, idx) { // {index}
    if(this.groupTitleIndex)
        $group.find(".grp_title span.grp_idx").html(String(idx+1));
    if(this.groupIntroIndex)
        $group.find(".grp_intro span.grp_idx").html(String(idx+1));
};

inz.forms.fields.FieldGroup.prototype.addGroup = function() {
    let fg = this;
    //var tmpl = this.$container.find(".form_group_record").last();
    let groups = this.$groups.find(".form_group");
    let newGroup = this.$template.clone();
    newGroup.removeClass("form_group_tmpl");
    newGroup.addClass("form_group");
    let idx = groups.length;

    this.setGroupTitle(newGroup, idx);
    newGroup.find(".obj_form_container").each(function()
    { // duplicate objects
        let field = fg.$template.find("#"+$(this).attr("id")).data("field");
        //var newId = fg.createFullId(field.getId(), idx);
        let newId = fg.createGroupId(field.id, idx);
        //var newName = fg.createFullGroupName(field.getName(), idx);
        let newName = fg.getGroupNamePrefix(idx)+field.name;
        let newField = field.createCopy(newGroup, newId, newName);
        fg.form.addField(newField);

        //fg.addChild(newField);
        // {prefix}[{idx}].{name}
        $(this).data("field", newField);
        newField.enableValidation();  // validation always disabled on template fields
        newField.isHidden = (newField.display!=inz.forms.fields.Field.STATE_HIDDEN);  // opposite so show / hide is applied
        //newField.clearValidation();
    });

    newGroup.find(".obj_form_container").each(function()
    { // setup conditions & parents
        let field = $(this).data("field");

        if ("parent" in field.config) {  // set to in correct in group parent
            let parent;
            if (field.config.parent === fg.id) {
                parent = fg;
            } else {
                let parentId = fg.createGroupId(field.config.parent, idx);
                parent = fg.form.getField(parentId);
            }
            field.parent = parent;
            parent.addChild(field);
        }

        if (field.config.conditions.length !== 0) {
            for(let i = 0; i<field.config.conditions.length; i++) {
                let config = field.config.conditions[i];
                let orgDependsId = config.depends;
                // update depends field id, assumed same group
                config.depends = fg.createGroupId(config.depends, idx);
                field.addCondition(config);
                config.depends = orgDependsId;
            }
        }

        if (field.dependants.length !== 0) {
            // update dependants, should only be in SMC App forms for now
            for(let i=0;i<field.dependants.length;i++) {
                // create duplicate condition with dependField being this new one in the group
                let src = field.dependants[i];
                for(let j =0;j<src.config.conditions.length;j++) {
                    //if (src.conditions[j].dependField === field.dependants[i]) {
                    if (src.config.conditions[j].depends !== null && field.id.startsWith(src.config.conditions[j].depends)) {
                        // found corresponding condition
                        let cconfig = { ...src.config.conditions[j]};  // duplicate condition config
                        cconfig.depends = field.id;  // point at group field
                        let cond = Object.create(inz.forms.Condition.prototype);
                        cond.init(src.form, src, cconfig, false);
                        src.conditions.push(cond);  // manually down otherwise it will add as a dependant
                        break;
                    }
                }
            }
        }
    });

    newGroup.find(".obj_form_container").each(function()
    {
        let field = $(this).data("field");
        field.setup();  // add handlers, default display etc...
        field.processConditions();  // process conditions to show/hide depending on external fields
    });

    newGroup.data("idx", idx);
    newGroup.appendTo(this.$groups);
    $(document).trigger('app:inittooltips', {target: newGroup});
    this.updateActions(idx);

    this.form.$form.parsley()._refreshFields();

    if(this.form.isInAccordian)
        this.form.adjustHeight();
};

inz.forms.fields.FieldGroup.prototype.removeGroup = function(noScroll) {
    let fg = this;
    let $rec = this.$container.find(".form_group").last();
    // field remove call
    $rec.find(".obj_form_container").each(function()
    { // remove fields
        let field = $(this).data("field");
        fg.form.removeField(field);
        for (let i=0; i<field.conditions.length; i++)
        { // tidy up dependants
            field.conditions[i].remove();
        }

        if (field.dependants.length !== 0) {
            // remove from dependants, should only be in SMC App forms for now
            for(let i=0;i<field.dependants.length;i++) {
                // remove duplicated condition with dependField being this new one in the group
                let src = field.dependants[i];
                for(let j =0;j<src.conditions.length;j++) {
                    if (src.conditions[j].dependField === field) {
                        // found corresponding condition
                        src.conditions.splice(j, 1); // manually remove
                        break;
                    }
                }
            }
        }

    });

    $rec.remove();
    this.updateActions();

    if(this.form.isInAccordian)
        this.form.adjustHeight();

    if (noScroll !== undefined && noScroll)
        return;

    let firstErrPos;
    let groups = this.$container.find(".form_group");
    if (groups.length === 0)
        firstErrPos = this.$container.offset().top - inz.modularForms.errorScrollOffset;
    else
        firstErrPos = this.$container.find(".form_group").last().offset().top - inz.modularForms.errorScrollOffset;
    $('html, body').animate({
        'scrollTop': firstErrPos
    }, 666);
};

inz.forms.validators = inz.forms.validators || {};

/**
 * Validates that the specified string is a valid IRD number
 * Reference: 2020 RWT and NRWT Specification Document v1.0 (https://www.classic.ird.govt.nz/resources/d/a/dac415c0-456f-4b2f-87b6-0e76cbedd3ec/2020+RWT%26NRWT+Specification+Document+v1.0.pdf)
 * @param str the string value to be validated
 */
inz.forms.validators.isValidIRDNumber = function(str)
{
    str = String(str).replace(/-/g, '');
    if(!/^\d+$/.test(str))
    {
        return false;
    }
    var num = parseInt(str, 10);

    // num is a positive integer; reset str to be the string version of num
    str = String(num);

    // check valid range
    if(num<10000000 || num>150000000)
    {
        return false;
    }

    // form eight digit base number
    var checkDigit = str.substring(str.length-1);
    var baseNumber = str.substring(0, str.length-1).padStart(8, '0');

    // calculate the check digit
    var weightFactors = [3, 2, 7, 6, 5, 4, 3, 2];
    var values = weightFactors.map(function(num, index) {return num*baseNumber.charAt(index)})
    var acc = values.reduce(function(acc, val) {return acc+val;}, 0);

    var calculatedCheckDigit = acc%11 === 0 ? 0 : 11-(acc%11);
    if(calculatedCheckDigit>9)
    {
        // recalculate the check digit
        var secondaryWeightFactors = [7, 4, 3, 2, 5, 2, 7, 6];
        values = secondaryWeightFactors.map(function(num, index) {return num*baseNumber.charAt(index)})
        acc = values.reduce(function(acc, val) {return acc+val;}, 0);
        calculatedCheckDigit = acc%11 === 0 ? 0 : 11-(acc%11);
        if(calculatedCheckDigit>9)
        {
            return false;
        }
    }

    // compare the check digit
    return calculatedCheckDigit === +checkDigit;
}


inz.forms.validators.initNZBankAccountNumberWidget = function()
{
    // all of the NZ Bank Account Number fields that have auto-hyphens enabled
    var fields = $('input[data-parsley-nz-bank-account-number][data-auto-hyphen]');

    // connect them to the jquery mask plugin
    fields.mask('00-0000-0000000-009');
}

inz.forms.validators.isValidNZBankAccountNumber = function(str)
{
    // var r = RegExp(/^(\d{1,2})-(\d{1,4})-(\d{1,8})-(\d{1,4})$/).exec(str);  // this is the correct validation for the IRD spec, however INZ require that we reject suffixes that are not 2 or 3 characters
    var r = RegExp(/^(\d{1,2})-(\d{1,4})-(\d{1,8})-(\d{2,3})$/).exec(str);
    if(!r)
    {
        return false;
    }
    var bank = r[1].padStart(2, '0');
    var branch = r[2].padStart(4, '0');
    var accountBase = r[3].padStart(8, '0');
    var accountSuffix = r[4].padStart(4, '0');

    // console.log(bank, branch, accountBase, accountSuffix);

    // check for valid bank
    var bankData = inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_BANK_DATA[bank];
    if(!bankData)
    {
        return false;
    }

    // check for valid branch
    var validBranch = false;
    for(var n = 0; n<bankData.branches.length; n++)
    {
        if(branch>=bankData.branches[n].from && branch<=bankData.branches[n].to)
        {
            validBranch = true;
            break;
        }
    }

    if(!validBranch)
    {
        return false;
    }

    // get algorithm
    var algorithm = null;
    if(bankData.algorithm === undefined)
    {
        // use the default -- A for base numbers < 990000; else B
        algorithm = accountBase<990000 ? inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_ALGORITHMS.A : inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_ALGORITHMS.B;
    } else
    {
        // there was an algorithm specified in the bank data, so use that
        algorithm = inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_ALGORITHMS[bankData.algorithm];
    }
    if(!algorithm)
    {
        console.error('Could not look up alogrithm for "'+str+'"');
        return false;
    }

    var paddedAccountNumber = bank+branch+accountBase+accountSuffix;
    var products = algorithm.weights.map(function(weight, index)
    {
        switch(bankData.algorithm)
        {
            case 'E':
            case 'G':
                var val = weight*paddedAccountNumber.charAt(index);
                val = Math.floor(val/10)+(val%10);
                val = Math.floor(val/10)+(val%10);
                return val;

            default:
                return weight*paddedAccountNumber.charAt(index);
        }
    });
    var acc = products.reduce(function(acc, val) {return acc+val;}, 0);

    // bank account is valid if mod by modulo is zero
    return acc%algorithm.modulo === 0;
}

inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_BANK_DATA = {
    "01": {"branches": [{"from": 1, "to": 999}, {"from": 1100, "to": 1199}, {"from": 1800, "to": 1899}]},
    "02": {"branches": [{"from": 1, "to": 999}, {"from": 1200, "to": 1299}]},
    "03": {"branches": [{"from": 1, "to": 999}, {"from": 1300, "to": 1399}, {"from": 1500, "to": 1599}, {"from": 1700, "to": 1799}, {"from": 1900, "to": 1999}, {"from": 7350, "to": 7399}]},
    "04": {"branches": [{"from": 2020, "to": 2024}]},
    '05': {"algorithm": "X", "branches": [{"from": 8884, "to": 8889}]},  // NOTE: added manually based on the current bank branches as there is no allocated branch range
    "06": {"branches": [{"from": 1, "to": 999}, {"from": 1400, "to": 1499}]},
    "08": {"algorithm": "D", "branches": [{"from": 6500, "to": 6599}]},
    "09": {"algorithm": "E", "branches": [{"from": 0, "to": 0}]},
    "10": {"branches": [{"from": 5165, "to": 5169}]},
    "11": {"branches": [{"from": 5000, "to": 6499}, {"from": 6600, "to": 8999}]},
    "12": {"branches": [{"from": 3000, "to": 3299}, {"from": 3400, "to": 3499}, {"from": 3600, "to": 3699}]},
    "13": {"branches": [{"from": 4900, "to": 4999}]},
    "14": {"branches": [{"from": 4700, "to": 4799}]},
    "15": {"branches": [{"from": 3900, "to": 3999}]},
    "16": {"branches": [{"from": 4400, "to": 4499}]},
    "17": {"branches": [{"from": 3300, "to": 3399}]},
    "18": {"branches": [{"from": 3500, "to": 3599}]},
    "19": {"branches": [{"from": 4600, "to": 4649}]},
    "20": {"branches": [{"from": 4100, "to": 4199}]},
    "21": {"branches": [{"from": 4800, "to": 4899}]},
    "22": {"branches": [{"from": 4000, "to": 4049}]},
    "23": {"branches": [{"from": 3700, "to": 3799}]},
    "24": {"branches": [{"from": 4300, "to": 4349}]},
    "25": {"algorithm": "F", "branches": [{"from": 2500, "to": 2599}]},
    "26": {"algorithm": "G", "branches": [{"from": 2600, "to": 2699}]},
    "27": {"branches": [{"from": 3800, "to": 3849}]},
    "28": {"algorithm": "G", "branches": [{"from": 2100, "to": 2149}]},
    "29": {"algorithm": "G", "branches": [{"from": 2150, "to": 2299}]},
    "30": {"branches": [{"from": 2900, "to": 2949}]},
    "31": {"algorithm": "X", "branches": [{"from": 2800, "to": 2849}]},
    "33": {"algorithm": "F", "branches": [{"from": 6700, "to": 6799}]},
    "35": {"branches": [{"from": 2400, "to": 2499}]},
    "38": {"branches": [{"from": 9000, "to": 9499}]},
    "88": {"algorithm": "X", "branches": [{"from": 8800, "to": 8803}, {"from": 8805, "to": 8805}]}  // NOTE: added manually based on the current bank branches as there is no allocate branch range

};

inz.forms.validators.NZ_BANK_ACCOUNT_NUMBER_ALGORITHMS = {
    "A": {"modulo": 11, "weights": [0, 0, 6, 3, 7, 9, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0]},
    "B": {"modulo": 11, "weights": [0, 0, 0, 0, 0, 0, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0]},
    "C": {"modulo": 11, "weights": [3, 7, 0, 0, 0, 0, 9, 1, 10, 5, 3, 4, 2, 1, 0, 0, 0, 0]},
    "D": {"modulo": 11, "weights": [0, 0, 0, 0, 0, 0, 0, 7, 6, 5, 4, 3, 2, 1, 0, 0, 0, 0]},
    "E": {"modulo": 11, "weights": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 4, 3, 2, 0, 0, 0, 1]},
    "F": {"modulo": 10, "weights": [0, 0, 0, 0, 0, 0, 0, 1, 7, 3, 1, 7, 3, 1, 0, 0, 0, 0]},
    "G": {"modulo": 10, "weights": [0, 0, 0, 0, 0, 0, 0, 1, 3, 7, 1, 3, 7, 1, 0, 3, 7, 1]},
    "X": {"modulo": 1, "weights": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
};

inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};
inz.forms.validations = inz.forms.validations || {};
inz.forms.modals = inz.forms.modals || {};

inz.forms.fields.Upload = class extends inz.forms.fields.Field {
    static MAX_UPLOAD_SIZE = 1024*1024*20.1; // 20 MB upload limit
    static MAX_UPLOADS = 30; // 30 uploads maximum

    static MSG_FAIL = "fail";
    static MSG_FAIL_DELETE = "del";
    static MSG_FAIL_VIRUS = "vr";

    static LABEL_MODAL_UPLOAD = "up";
    static LABEL_MODAL_FINISHED = "fin";

    /*
                "lbs": {
                "up": self.html_escape(self.label_modal_upload),
                "fin": self.html_escape(self.label_modal_finished),
                "mc": self.html_escape(self.label_modal_close),

     */

    static setupFieldType(parsley) {
    }

    init(form, $ctx, config) {
        super.init(form, $ctx, config);
        this.modal = form.getModal("Upload");
        this.$list = this.$el.find("ul").first();
        this.fileInfos = [];
        if (config.settings.size === null)
            config.settings.size = inz.forms.fields.Upload.MAX_UPLOAD_SIZE;
        else
            config.settings.size = config.settings.size*1024*1024; // convert from MB to bytes
        config.settings.max = Math.min(config.settings.max, inz.forms.fields.Upload.MAX_UPLOADS);
        if (config.settings.accept) {
            // generate accept list
            this.acceptList = [];
            for (let acc of config.settings.accept.split(',')) {
                this.acceptList.push(acc.toLowerCase().trim());
            }
        }
    }

    show(initial, no_reset) {
        super.show(initial, no_reset);
    }

    hide(initial, no_reset) {
        super.hide(initial, no_reset);
    }

    setup(initial) {
        // setup modal links
        this.$el.find('.ae_supporting_documents_link').on('click', this, function(e) {
            e.preventDefault();
            let field = e.data;
            return field.modal.linkHandler(this, field.modal);
        });
        super.setup(initial);
    }

    useGetOnSubmit() { return false; } // get data from the get function as this isn't a normal input field

    getMessage(key) {
        if (!(key in this.config.settings.msgs)) {
            return "";
        }
        return this.config.settings.msgs[key];
    }

    getLabel(key) {
        if (!(key in this.config.settings.lbs)) {
            return "";
        }
        return this.config.settings.lbs[key];
    }

    getValue() {
        return JSON.stringify(this.fileInfos);
    }

    setValue(value) {
        this.clear();
        if (value === null)
            return;
        let infos = JSON.parse(value); // array of infos
        for (const info of infos) {
            this.addFile(info);
        }
        // trigger change event
        this.form.changeEvent({data: this});
    }

    validate() {
        console.log("Upload validate");
        if (this.$el !== undefined && this.$el.hasClass('upload_required')) {
            let numberFiles = this.$el.find('li').length;
            if (numberFiles === 0)
                return false; // required but no files
        }
        return true;
    }

    clear() {
        this.fileInfos = [];
        this.$list.empty();
    }

    reset() {
        this.form.changeEvent({data: this});
        //this.$el.val('').change();
    }

    addSavedFile(fileInfo) {
        // {
        //     "filename": "shows.txt",
        //     "fieldname": "A File",
        //     "fieldhash": "pL69Ve0",
        //     "unique_identifier": "83483",
        //     "size": "238B"
        // }

        /*if (typeof(fileinfo.unique_id) !== "undefined") {
            fileinfo.unique_identifier = fileinfo.unique_id;
        }*/
        this.addFile(fileInfo);
    }

    static isFileTransient(fileInfo) {
        return fileInfo.unique_identifier !== undefined;
    }

    static getFileId(fileInfo) {
        if (fileInfo.unique_identifier !== undefined) {
            return fileInfo.unique_identifier;
        }
        return fileInfo.unique_id;
    }

    addFile(fileInfo) {
        let html;
        let fuid = inz.forms.fields.Upload.getFileId(fileInfo);
        //if (! String(fileInfo.unique_identifier).match(/_/)) {
        if (!inz.forms.fields.Upload.isFileTransient(fileInfo)) {
            // This is a file obj that is already attached to a saved submission
            html = `<li data-fuid="${fuid}"><span>${fileInfo.filename}</span> <span>(${fileInfo.size})</span><input type="hidden" class="retain_file_marker" id="file_${fuid}" name="__retain_files:list" value="${fuid}" /><input class="uploadField" type="hidden" name="${this.getName()}:list" value='{"unique_id": "${fuid}"}' /></li>`;
        } else {
            html = `<li data-fuid="${fuid}"><span>${fileInfo.filename}</span> <span>(${fileInfo.size})</span><input class="uploadField" type="hidden" name="${this.getName()}:list" value='{"unique_identifier": "${fuid}"}' /></li>`;
        }
        this.$list.append($(html));
        this.fileInfos.push(fileInfo);

        if(this.fileInfos.length === 1) {
            this.$el.find('.ae_doc_list_container').show(); // now has a document, show it
            this.$el.find('.ae_supporting_documents_link').first().hide();
        }

        if (this.form.isInAccordian) // work around for bad accordian design
            this.form.adjustHeight();

        this.$el.parsley().validate(true);
        this.form.changeEvent({data: this});
        return html;
    }

    removeFile(fileUid) {
        let entry = this.$list.find(`li[data-fuid='${fileUid}']`);
        if (entry.length === 0) {
            console.log(`Remove file: ${fileUid}, but not in list`);
            //return;
        } else {
            entry.remove();
        }
        for (const idx in this.fileInfos) {
            if (String(inz.forms.fields.Upload.getFileId(this.fileInfos[idx])) === String(fileUid)) {
                this.fileInfos.splice(idx, 1);
                break;
            }
        }

        if (this.fileInfos.length === 0) {
            this.$el.find('.ae_doc_list_container').hide(); // no documents, hide it
            this.$el.find('.ae_supporting_documents_link').first().show();
        }
        this.$el.parsley().validate(true);
        this.form.changeEvent({data: this});
    }

}

inz.forms.modals.Upload = class {
    //static UPLOAD_URL = window.form.action.replace(/(app_)?submit/, 'save_temp');
    //static REMOVE_URL = window.form.action.replace(/(app_)?submit/, 'remove_temp');


    init(form) {
        //console.log("Init form modal");
        this.form = form;
        this.$modal = $('#upload-file');
        //$('#upload-file #upload_link').on('keydown', inz.accreditedemployers.upload.uploadLinkTabHandler);
        let _this = this;
        let $ctx = this.form.$form;

        this.$modal.find('#upload_link').on('click', this, function(e) {
            e.preventDefault();
            let modal = e.data;

            // validate number of files first
            let maximumUploadNumber = modal.field.config.settings.max;
            let files = $(modal.container).find('tr');
            if (files.length >= maximumUploadNumber) {
                modal.showUploadProgress(0);
                modal.showErrorMessage('The maximum number of files ('+maximumUploadNumber+') has already been uploaded. Delete an uploaded file first if you want to replace it.');
                return false;
            }
            //modal.$modal.find("#upload").trigger('click');
            modal.$modal.find("label[for='upload']").trigger('click');
        });
    }

    linkHandler(link, modal) {
        modal.field = $(link).closest(".obj_form_field").data("field");
        modal.container = $(link).parents('div.ae_supporting_documents');

        modal.setupForField(modal.field);
        modal.hideErrorMessage();
        modal.showUploadProgress(2);
        modal.setUploadProgress('Preparing for uploads...', '', undefined, true);

        modal.showUploadProgress(0);

        $(link).modal();
        window.setTimeout(function() { modal.setTabHandlers(); }, 250);
        modal.$modal.find('.close-modal').text(''); // remove close label

        modal.$modal.find('.close-modal').on('keydown', function(e) {
            if((e.which === 9 && !e.shiftKey)) {
                e.preventDefault();
                let d = modal.$modal.find('.upload-delete:first');
                if(d.length !== 0)
                    $(d).focus();
                else
                    modal.$modal.find('#upload_link').focus();
            }
        });

        modal.$modal.on('keydown', function(e) {
            if((e.which === 9)) {
                e.preventDefault();
                let d = modal.$modal.find('.upload-delete:first');
                if(d.length !== 0)
                    $(d).focus();
                else
                    modal.$modal.find('#upload_link').focus();
                modal.$modal.off('keydown'); // only do it on first open
            }
        });
        return false;
    }

    removeLinkTabHandler(e) {
        let modal = e.data;
        if(e.which === 9) { // tab backwards from link, should go to close link
            e.preventDefault();
            let $parent = $($(this).parent('tr'));
            if(e.shiftKey) { // backward
                let prev = $parent.prev('tr');
                if(prev.length !== 0)
                    $(prev).find('.upload-delete').focus();
                else
                    modal.$modal.find(".close-modal").focus();
            } else {
                // forward
                let next = $parent.next('tr');
                if(next.length !== 0)
                    $(next).find('.upload-delete').focus();
                else
                    modal.$modal.find("#upload_link").focus();
            }
        }
    }

    uploadLinkTabHandler(e) {
        let modal = e.data;
        if(e.which === 9 && e.shiftKey) { // tab backwards from link, should go to last file or close link
            e.preventDefault();

            let lastFile = $('#upload-file .upload-delete:last');
            if(lastFile.length !== 0)
                $(lastFile).focus();
            else
                $('#upload-file .close-modal').focus();
        }
    }

    setTabHandlers() {
        this.$modal.find(".upload-delete").off('keydown'); // remove all handlers
        this.$modal.find(".upload-delete").on('keydown', this, this.removeLinkTabHandler);
    }

    fileFieldClickHandler(e) {
        let modal = $(this).data("modal");
        let fileObj = modal.$modal.find("#upload").get(0).files[0];
        if(fileObj === undefined)
            return;
        modal.doUpload(fileObj);
    }

    updateFileField(field) {
        //$('#upload-file #upload').replaceWith('<input id="upload" type="file" style="display: none" onchange="inz.accreditedemployers.upload.fileFieldClick();" />');
        //$('#upload-file #upload').replaceWith('<input id="upload" type="file" style="display: none" />');
        let accept_attribute=field.config.settings.accept ? ` accept="${field.config.settings.accept}"` : "";
        $('#upload-file #upload').replaceWith(`<input id="upload" type="file" style="display: none" ${accept_attribute}/>`);
        $("#upload-file #upload").data("modal", this);
        $("#upload-file #upload").on('change', this.fileFieldClickHandler);
    }

    setupForField(field) {
        this.setFileList(field);
        this.$modal.find("#uploaded-files h4").text(`${field.config.settings.label}`);
        // update ctas
        this.$modal.find("#upload_link").text(field.getLabel(inz.forms.fields.Upload.LABEL_MODAL_UPLOAD));
        this.$modal.find("#upload_finished").text(field.getLabel(inz.forms.fields.Upload.LABEL_MODAL_FINISHED));

        this.updateFileField(field);
    }

    setFileList(field) {
        // copy in documents, from field
        //var c = $('[data-upload="'+field+'"]').parent('.ae_supporting_documents');
        let src = field.$el.find('ul').first();
        let dst = this.$modal.find('#uploaded-files tbody');
        dst.empty();

        for (let info of field.fileInfos) {
            let html = this.addFileHtml(info);
            dst.append($(html));
        }
        //src.find('li').clone().append('<a class="upload-delete" href="#" style="float: right">delete</a>').appendTo(dst);
        dst.find('a').on('click', this, this.deleteFileHandler);
        this.setTabHandlers(dst);
    }

    getNumberFiles(context) {
        let docList = $(context).find('.ae_supporting_documents_list');
        if(docList.length === 0)
            return 0;
        return docList.find('li').length;
    }

    addFile(fileInfo) {
        // add to from page widget and modal
        //var c = $('[data-upload="'+field+'"]').parent('.ae_supporting_documents');
        // let src = $($(context).find('ul')[0]);
        // let fnli = $('<li>').append($('<span>', {text: fileName}));
        // src.append(fnli.clone());
        // if(context.find('.ae_doc_list_container').hasClass('hidden'))
        //     context.find('.ae_doc_list_container').removeClass('hidden'); // now has a document, show it

        // add to field
        let entry_html = this.field.addFile(fileInfo);

        let dst = this.$modal.find('#uploaded-files table tbody');
        //dst.append($(entry_html).append('<a class="upload-delete btn btn__small btn__secondary" href="#" style="float: right">delete</a></li>'));
        //let html = `<tr data-fuid="${fileInfo.unique_identifier}"><td><span>${fileInfo.filename}</span> <span>(${fileInfo.size})</span></td><td class="btn_action"><a class="upload-delete btn btn__small btn__secondary" href="#">delete</a></td></tr>`;
        let html = this.addFileHtml(fileInfo);
        //dst.append($(entry_html).append('<a class="upload-delete btn btn__small btn__secondary" href="#" style="float: right">delete</a></li>'));
        dst.append($(html));
        dst.find('a').off('click');
        dst.find('a').on('click', this, this.deleteFileHandler);
        this.setTabHandlers(dst);
    }

    addFileHtml(fileInfo) {
        let fuid = inz.forms.fields.Upload.getFileId(fileInfo);
        let html = `<tr data-fuid="${fuid}"><td><span>${fileInfo.filename}</span> <span>(${fileInfo.size})</span></td><td class="btn_action"><a class="upload-delete btn btn__small btn__secondary" href="#">delete</a></td></tr>`;
        return html;
    }

    removeFile(fuid) {
        // add to from page widget and modal
        //var c = $('[data-upload="'+field+'"]').parent('.ae_supporting_documents');
        /*let src = $($(context).find('ul')[0]);
        $(src.find(':contains(\''+fileName+'\')')[0]).remove();
        if(src.find('li').length === 0) {
            context.find('.ae_doc_list_container').addClass('hidden'); // no documents, hide it
            this.$modal.find("#upload_link").focus().blur();
        }*/
        this.field.removeFile(fuid);
        //let dst = this.$modal.find(`#uploaded-files ul li[data-fuid='${fuid}']`).remove();
        let dst = this.$modal.find(`#uploaded-files table tr[data-fuid='${fuid}']`).remove();
        //$(dst.find(':contains(\''+fileName+'\')')[0]).remove();
        this.setTabHandlers(dst);
    }

    deleteFileHandler(e) {
        e.preventDefault();
        let modal = e.data;

        let form = modal.$modal;
        let fileName = $(this).parent().find('span').first().text();
        //let fuid = $(this).closest("li").data("fuid");
        let fuid = $(this).closest("tr").data("fuid");

        modal.showUploadProgress(2);
        modal.setUploadProgress('Deleting file - \''+fileName+'\'', '');

        // dbg: console.log("Field: "+field);
        if (! String(fuid).match(/_/)) {
            // Don't need to tell the webservice; this is managed by the saving machinery already
            modal.removeFile(fuid);
            if (modal.field.validate !== undefined)
                modal.field.$el.parsley().validate(true);
                //$(modal.field.validate).parsley().validate();
            modal.showUploadProgress(0);
            return
        }
        let params = {
            'fileName': fileName,
            'field': modal.field.getName(),
            'unique_identifier': fuid,
        };

        let jqxhr = $.ajax({
            //url: window.form.action.replace(/(app_)?submit/, 'remove_temp'),
            url: "/@@acc_sf_remove_temp/"+ window.form.action.substring(window.form.action.lastIndexOf('/')+1),
            type: "POST",
            data: params
        }).done(function(data) {
            // dbg: console.log("Output:");
            // dbg: console.log( data );
            modal.removeFile(fuid);
            if (modal.field.validate !== undefined)
                modal.field.$el.parsley().validate(true);
                //modal.field.validate(true);
                //$(modal.field.validate).parsley().validate();
            modal.showUploadProgress(0);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            console.log("Failed Status: "+jqXHR.status);
            console.log("StatusText: "+jqXHR.statusText);
            // display orange banner
            modal.showUploadProgress(0);
            //modal.showErrorMessage('The selected file could not be deleted at this time. Please try again later.');
            modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL_DELETE));
        });
    }

    validateFile(fileObj) {
        // check number of uploads
        //const maximumUploadNumber = inz.forms.fields.Upload.maximumNumberUploads;
        const maximumUploadNumber = this.field.config.settings.max;
        //let files = $(context).find('li');
        let files = this.field.$el.find('li');
        if (files.length >= maximumUploadNumber) {
            this.showUploadProgress(0);
            this.showErrorMessage('The maximum number of files ('+maximumUploadNumber+') has already been uploaded. Delete an uploaded file first if you want to replace it.');
            return false;
        }

        // check size
        //const maximumUploadSize = inz.forms.fields.Upload.maximumUploadSize;
        const maximumUploadSize = this.field.config.settings.size;
        if (fileObj.size > maximumUploadSize) {
            this.showUploadProgress(0);
            this.showErrorMessage('The selected file ('+fileObj.name+') was too large ('+this.formatBytes(fileObj.size)+') to be uploaded. Maximum upload size accepted is '+this.formatBytes(maximumUploadSize)+'.');
            return false;
        }

        // check file name
        if (this.field.acceptList !== undefined) {
            let ext = "." + fileObj.name.split('.').pop().trim().toLowerCase();
            if (!this.field.acceptList.includes(ext)) {
                this.showUploadProgress(0);
                let msg;
                if (this.field.acceptList.length === 1) {
                    msg = `The file must have an extension of ${this.field.config.settings.accept}`;
                } else {
                    msg = `The file must have an extension of any one of the following: ${this.field.config.settings.accept}`;
                }
                this.showErrorMessage(`The selected file (${fileObj.name}) does not have a valid extension. ${msg}`);
                return false;
            }
        }
        return true;
    }

    formatBytes(a, b) {
        if(0 === a) return "0 Bytes";
        let c = 1e3, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a)/Math.log(c));
        return parseInt((a/Math.pow(c, f)).toFixed(d))+" "+e[f]
    }

    doUpload(fileObj) {
        // do upload
        let form = $('#upload-file');
        let modal = this;

        //var field = inz.accreditedemployers.form.uploadFields[inz.accreditedemployers.upload.field];
        if(this.field === undefined) { // unknown field, can't do upload
            console.log("Unknown field, can't do upload");
            $.modal.close();
            return;
        }

        // prepare progress
        let fileInfo = {
            filename: fileObj.name,
            fieldname: this.field.getName(),
            fieldhash: this.field.getName(),
            unique_identifier: null,
            type: fileObj.type,
            size: modal.formatBytes(fileObj.size),
        };

        let fileName = fileObj.name;
        if (this.validateFile(fileObj) === false) // failed validation
            return;

        this.showUploadProgress(1);
        this.setUploadProgress('Uploading file - \''+inz.forms.escapeHTML(fileName)+'\'', '', 0);

        // dbg: console.log("Field: "+field);
        let formData = new FormData();
        //formData.append('appId', application.applicationId);
        //formData.append('section', field.section);
        formData.append('field', fileInfo.fieldname);
        formData.append('fileName', fileInfo.filename);
        formData.append('file', fileObj);
        if(this.index !== undefined)
            formData.append('index', inz.accreditedemployers.upload.index);

        let jqxhr = $.ajax({
            //url: window.form.action.replace(/(app_)?submit/, 'save_temp'),
            url: "/@@acc_sf_save_temp/" + window.form.action.substring(window.form.action.lastIndexOf('/')+1),
            type: "POST",
            data: formData,
            processData: false,
            contentType: false
        }).done(function(data) {
            console.log("Upload result: "+data.result);
            fileInfo.unique_identifier = data.unique_identifier;
            // Ensure that the details we reflect back on to the page have been escaped:
            fileInfo.filename = inz.forms.escapeHTML(fileName);
            modal.addFile(fileInfo);

            if(modal.field.validate !== undefined)
                modal.field.$el.parsley().validate(true);
                //$(modal.field.validate).parsley().validate();

            modal.updateFileField(modal.field);
            modal.showUploadProgress(0);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            console.log("Failed Status: "+jqXHR.status);
            console.log("StatusText: "+jqXHR.statusText);
            modal.updateFileField(modal.field);
            modal.showUploadProgress(0);
            if(jqXHR.status === 422) {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL));
            } else if(jqXHR.status === 406) {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL_VIRUS));
            } else if(jqXHR.status === 413) {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL));
            } else if(jqXHR.status === 412) {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL));
            } else if(jqXHR.status === 415) {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL));
            } else {
                modal.showErrorMessage(modal.field.getMessage(inz.forms.fields.Upload.MSG_FAIL));
            }
        }).uploadProgress(function(evt) {
            if(evt.lengthComputable) {
                let percentComplete = (evt.loaded/evt.total)*100.0;
                let msg = 'Uploaded ' + modal.formatBytes(evt.loaded)+' of ' + modal.formatBytes(evt.total);
                modal.setUploadProgress(undefined, msg, percentComplete);

                if(percentComplete>=100.0)
                    modal.setUploadProgress(undefined, 'File uploaded. Processing...', undefined, true, false);
            }
        });
    }

    setUploadProgress(heading, message, progress, showSpinner, showProgress) {
        let form = $('#upload-file');

        if(heading !== undefined)
            form.find('.upload_heading').text(heading);
        if(message !== undefined)
            form.find('.upload_message').text(message);
        if(progress !== undefined)
            form.find('.ae_progress_meter span').width(progress.toFixed(0)+'%');
        if(showSpinner !== undefined)
            showSpinner ? form.find('.ae_upload_spinner').show() : form.find('.ae_upload_spinner').hide();
        if(showProgress !== undefined)
            showProgress ? form.find('.ae_progress_meter').show() : form.find('.ae_progress_meter').hide();
    }

    showUploadProgress(show) {
        if (show === 0) { // hide progress, back to file list
            $('#uploaded-files').show();
            $('#upload-actions').show();
            $('#upload-file-progress').hide();
            this.$modal.find('.close-modal').show();
            return;
        }

        if(show === 1) { // progress, no spinner
            this.$modal.find('.ae_progress_meter span').width('0%');
            this.$modal.find('.ae_progress_meter').show();
            this.$modal.find('.ae_upload_spinner').hide();
        } else if(show === 2) { // spinner no progress
            this.$modal.find('.ae_progress_meter').hide();
            this.$modal.find('.ae_upload_spinner').show();
        } else if(show === 3) { // no progress, keep error only
            this.$modal.find('.ae_progress_meter').hide();
            this.$modal.find('.ae_upload_spinner').hide();
            $('#uploaded-files').hide();
            $('#upload-actions').hide();
            $('#upload-file-progress').show();
            this.$modal.find('.close-modal').show();
            return;
        } else { // show nothing
            this.$modal.find('.ae_progress_meter').hide();
            this.$modal.find('.ae_upload_spinner').hide();
        }

        this.hideErrorMessage();
        this.$modal.find('#uploaded-files').hide();
        this.$modal.find('#upload-actions').hide();
        this.$modal.find('#upload-file-progress').show();
        this.$modal.find('.close-modal').hide();
    }

    showErrorMessage(msg) {
        this.$modal.find('.banner_textcopy').text(msg);
        this.$modal.find('.banner').show();
    }

    hideErrorMessage() {
        this.$modal.find('.banner').hide();
    }
}

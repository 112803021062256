window.inz = window.inz || {};
var inz = window.inz;
inz.login = {};

inz.login.init = function(login_required)
{
    if (login_required) {
        inz.forms.message.showMessage('<p>Please wait while the form is loaded</p>', null, 'Form loading', true);
        //inz.forms.message.scrollToMessage();
        $.ajax({
            dataType: "json",
            method: "POST",
            url: "/@@acc_login_session_check",
        }).done(function(data, textStatus, jqXHR)  {
            // User is apparently logged in OK. Start session
            inz.login.session = new inz.login.Session(data);
            // Next step is to load saved form state. For now, just show the form
            let form_user_action_event = new Event("form_user_logged_in", {
                bubbles: true
            });
            $('body')[0].dispatchEvent(form_user_action_event);
            $('.authorised_form_content').show();
        }).fail(function(jqXHR, textStatus, errorThrown) {
            if(jqXHR.status === 403 || jqXHR.status === 401) {
                $('.non_authorised_form_content').show();
                inz.forms.message.hideMessage();
            }
        });
    } else {
        $('.authorised_form_content').show();
    }
};

inz.login.Session = class {
    static States = Object.freeze({
        LoggedIn: "loggedin",
        Drafts: "drafts",
        Loaded: "loaded",
        Saving: "saving",
        Saved: "saved",
        LoggingOut: "loggingout",
        Warning: "warning",
        Reload: "reload",
    });

    static Events = Object.freeze({
        Drafts: "drafts", // on drafts loaded
        Loaded: "loaded", // submission loaded
        New: "new", // on create new
        Active: "active", // form edit activity
        Save: "save",
        Cancel: "cancel",
        LogOut: "logout",
        Check: "check",
        TimeOut: "timeout",
        Warning: "warning",
        TabLogout: "tabout",
    });

    static WARNING_TIME = 5 * 60000;  // ms left to show warning
    static MAXIMUM_CHECK_TIME = 24 * 60000; // maximum time between session checks / refresh

    constructor(data) {
        this.activity = 0;
        this.formActivity = 0;
        this.data = data;
        this.submissionId = null;
        this.state = null;
        this.stateData = null;
        this.lastState = null;
        this.lastData = null;
        this.timeoutId = 0;
        this.initStates();
        this.channel = new BroadcastChannel("flgs");
        //this.timeout_id = window.setTimeout(() => { sess.session_check() }, timeout_delay);
        //$('#termination_form').on('click', '#save_and_exit', sess.save_and_exit);
        let sess = this;
        $('#termination_form').on('click', '#save_and_exit', () => sess.processEvent(inz.login.Session.Events.Save));
        this.channel.addEventListener("message", (event) => {
            let sevt = event.data;
            sess.processEvent(sevt);
        });

        this.transition(inz.login.Session.States.LoggedIn, data);
    }

    initStates() {
        this.states = {
            [inz.login.Session.States.LoggedIn]: new inz.login.StateLoggedIn(inz.login.Session.States.LoggedIn, this),
            [inz.login.Session.States.Drafts]: new inz.login.StateDrafts(inz.login.Session.States.Drafts, this),
            [inz.login.Session.States.Loaded]: new inz.login.StateLoaded(inz.login.Session.States.Loaded, this),
            [inz.login.Session.States.Saving]: new inz.login.StateSaving(inz.login.Session.States.Saving, this),
            [inz.login.Session.States.Saved]: new inz.login.StateSaved(inz.login.Session.States.Saved, this),
            [inz.login.Session.States.LoggingOut]: new inz.login.StateLoggingOut(inz.login.Session.States.LoggingOut, this),
            [inz.login.Session.States.Warning]: new inz.login.StateWarning(inz.login.Session.States.Warning, this),
            [inz.login.Session.States.Reload]: new inz.login.StateReload(inz.login.Session.States.Reload, this),
        }
    }

    currentStateId() {
        return this.state === null ? null : this.state.id;
    }

    stateEntered(stateId, data) {
        console.debug("Session: entered_state: "+stateId+" Data: "+String(data));
        this.lastState = this.state;
        this.lastData = this.stateData;
        this.state = this.states[stateId];
        this.stateData = data;
    }

    stateExited(stateId) {
        console.debug("Session: stateExited: "+stateId);
    }

    transition(stateId, data) {
        let newState = this.states[stateId];
        if (typeof newState === "undefined") {
            console.warn(`Unknown state: ${stateId} (${data})`);
            return false;
        }
        if (!newState.guard()) {
            console.warn(`Cannot transition to state: ${stateId} (${data}) currently in state ${this.state !== null ? this.state.id : "None"}`);
            return false;
        }
        newState.entry(data);
        return true;
    }

    transitionLast() {
        let newState = this.lastState;
        if (!newState.guard()) {
            console.warn(`Cannot transition last to state: ${String(this.lastState.id)} (${String(this.lastData)}) currently in state ${String(this.state.id)}`);
            return false;
        }
        newState.entry(this.lastData);
    }

    processEvent(event, data) {
        console.debug(`Session: event: ${event} data: ${data}`);
        if (this.state !== null)
            this.state.run(event, data);
        return false;
    }

    sessionRemaining() {
        let currtime = (new Date).getTime();
        let remaining = (this.data.time_over*1000) - currtime;
        if (remaining > inz.login.Session.MAXIMUM_CHECK_TIME) {
            // More than maximum time, use maximum
            remaining = inz.login.Session.MAXIMUM_CHECK_TIME;
        }
        console.debug(`sessionRemaining: ${remaining}`);
        return remaining;
    }

    sessionWarningTimeout() {
        let currtime = (new Date).getTime();
        let remaining = (this.data.time_over*1000) - currtime;
        if (remaining < inz.login.Session.WARNING_TIME) {
            return 2000; // minimum
        }
        return remaining - inz.login.Session.WARNING_TIME;
    }

    sessionDataUpdate(data) {
        this.data = data;
        if (this.timeoutId !== 0)
            this.timeoutStart(true); // update timer
        console.debug("Session Data Update");
    }

    timeoutUrl() {
        let form_identity = $('#form-identity').data().formIdentity;
        return '/@@acc_login_timeout?FormIdentity='+form_identity;
    }

    timeoutStart(replace) {
        if ( ((typeof replace === "undefined") || !replace) && this.timeoutId !== 0)
            return;

        let sess = this;
        this.timeoutStop();
        let remaining = this.sessionWarningTimeout();
        console.debug("Session time out warning in: "+remaining);
        this.timeoutId = window.setTimeout(() => { sess.processEvent(inz.login.Session.Events.Warning) }, remaining);
    }

    timeoutStop() {
        if (this.timeoutId !== 0) {
            window.clearTimeout(this.timeoutId);
        }
        this.timeoutId = 0;
    }

    active(form) {
        this.activity = (new Date).getTime();
        if (form)
            this.formActivity = this.activity;
    }

    formIsModified() { if (this.isSubmitted()) { return false; } else { return this.formActivity !== 0; }}
    beenActive() { return this.activity !==0; }
    haveSaved() { this.formActivity = 0; }
    inDrafts() { return $(".modal_forms_message table.savedDrafts").is(":visible") || $('#noapps').is(":visible"); }
    isSubmitted() { return $('#success_page').is(":visible"); }
    lastStateWas(stateId) {
        if (this.lastState == null)
            return false;
        return this.lastState.id === stateId;
    }

    randomInt(min, max) {
        return Math.floor(Math.random() * max) + min;
    }

    warningTimeOut() {
        // warning timeout expired.
        let sess = this;
        if (this.beenActive()) {
            // refresh, user has been doing stuff
            this.refresh(this.activity).done(function(data) {
                if (data !== null)
                    sess.data = data;
                sess.activity = 0;
                sess.timeoutStart(true);
            });
        } else {
            // inactive, session_check to get updated remaining time.
            $.ajax({
                dataType: "json",
                method: "POST",
                url: "/@@acc_login_session_check",
            }).done(function(data, textStatus, jqXHR) {
                sess.data = data;
                let warningTimeout = sess.sessionWarningTimeout();
                console.debug(`Warning timeout remaing: ${warningTimeout}`);
                if (warningTimeout < inz.login.Session.WARNING_TIME) {
                    // less than warning time left, show message
                    sess.transition(inz.login.Session.States.Warning);
                } else {
                    // set another warning check in future
                    sess.timeoutStart(true);
                }
            }).fail(function(jqXHR, textStatus, errorThrown) {
                console.debug(`Session check error: ${jqXHR.status} / ${textStatus} / ${errorThrown} `);
                if (jqXHR.status === 403) { // not logged in, no session, quick reload
                    sess.transition(inz.login.Session.States.Reload, 0);
                } else if (jqXHR.status === 401) { // not logged in, but session and token, can save
                    if (sess.isSubmitted()) {
                        // The form has been submitted - we don't save anything here
                        sess.transition(inz.login.Session.States.Reload);
                    } else if (sess.state instanceof inz.login.StateLoaded && sess.formIsModified()) {
                        sess.transition(inz.login.Session.States.Saving, inz.login.Session.States.Reload);
                    } else {
                        sess.transition(inz.login.Session.States.Reload);
                    }
                } else {
                    sess.timeoutStart(true);
                }
            });
        }
    }

    refresh(lastseen) {
        let data = {};
        if (lastseen) {
            data['lastseen'] = lastseen;
        }
        let deferred = $.Deferred();
        $.ajax({
            dataType: "text",
            method: "POST",
            url: "/@@realme-tokencheck",
            data: data,
        }).done(function(data, textStatus, jqXHR) {
             // Job done
            let bodyText = $.parseHTML(data)[2].textContent;
            let response = JSON.parse(bodyText.trim());
            deferred.resolve(response);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            if(jqXHR.status === 403) {
                deferred.resolve(null);
                window.location = inz.login.session.timeoutUrl();
            } else {
                // Let the caller re-call if they want:
                deferred.resolve(null);
            }
        });
        return deferred.promise()
    }
}

/**
 * States
 */

inz.login.State = class {
    constructor(id, session) {
        this.session = session;
        this.id = id;
    }

    guard() { return true; }  // can we enter this state
    entry(data) { this.session.stateEntered(this.id, data); } // enter state
    exit() { this.session.stateExited(this.id); } // leave state
    run(event, data) {
        switch (event) {
            case inz.login.Session.Events.Warning:
                this.session.warningTimeOut();
                break;
            case inz.login.Session.Events.TabLogout:
                let state = this;
                window.setTimeout(() => {
                    if (state.session.formIsModified()) {
                        state.session.transition(inz.login.Session.States.Saving, inz.login.Session.States.Reload);
                    } else {
                        state.session.transition(inz.login.Session.States.Reload, state.session.randomInt(500, 7500));
                    }
                }, this.session.randomInt(1000, 5000));
                break;
            default:
                console.warn(`Session (${this.id}): Unsupported event: ${event}`);
                break;
        }
    } // event driven
}


inz.login.StateLoggedIn = class extends inz.login.State {
    guard() {
        return this.session.currentStateId() === null;
    }

    entry(data) {
        this.session.timeoutStart();
        super.entry(data);
    }

    run(event, data) {
        console.debug(`Session (${this.id}) : event: ${event} data: ${data}`);
        switch (event) {
            case inz.login.Session.Events.Drafts:
                this.session.transition(inz.login.Session.States.Drafts);
                break;
            case inz.login.Session.Events.New:
                this.session.transition(inz.login.Session.States.Loaded, null);
                break;
            case inz.login.Session.Events.Warning:
                this.session.lastState = inz.login.session.states[inz.login.Session.States.Drafts]; // fudge last so we end up in drafts after warning
                this.session.warningTimeOut();
                break;
            default:
                console.warn(`Session (${this.id}): Unsupported event: ${event}`);
                break;
        }
    }
}

inz.login.StateDrafts = class extends inz.login.State {

    entry(data) {
        this.session.timeoutStart();
        super.entry(data);
    }

    run(event, data) {
        console.debug(`Session (${this.id}) : event: ${event} data: ${data}`);
        switch (event) {
            case inz.login.Session.Events.New:
                this.session.transition(inz.login.Session.States.Loaded, null);
                break;
            case inz.login.Session.Events.Loaded:
                this.session.transition(inz.login.Session.States.Loaded, data);
                break;
            case inz.login.Session.Events.Active:
                break; // silent drop, as these are generated when loading a form as well
            case inz.login.Session.Events.TabLogout:
                this.session.transition(inz.login.Session.States.Reload);
                break;
            case inz.login.Session.Events.LogOut:
                this.session.transition(inz.login.Session.States.LoggingOut);
                break;
            case inz.login.Session.Events.Warning:
                this.session.warningTimeOut();
                break;
            default:
                console.warn(`Session (${this.id}): Unsupported event: ${event} (${data})`);
                break;
        }
    }
}

inz.login.StateLoaded = class extends inz.login.State {

    entry(data) {
        this.session.timeoutStart(); // make sure warning timeout is running
        this.session.activity = 0;
        this.session.formActivity = 0;
        this.session.submissionId = data;
        super.entry(data);
    }

    run(event, data) {
        console.debug(`Session (${this.id}) : event: ${event} data: ${data}`);
        switch (event) {
            case inz.login.Session.Events.Active:
                this.session.active(true);
                break;
            case inz.login.Session.Events.Save:
                this.session.transition(inz.login.Session.States.Saving);
                break;
            case inz.login.Session.Events.Cancel:
                //this.session.transition(inz.login.Session.States.Drafts);
                break;
            case inz.login.Session.Events.Warning:
                this.session.warningTimeOut();
                break;
            case inz.login.Session.Events.TabLogout:
                let state = this;
                window.setTimeout(() => {
                    if (state.session.formIsModified()) {
                        state.session.transition(inz.login.Session.States.Saving, inz.login.Session.States.Reload);
                    } else {
                        state.session.transition(inz.login.Session.States.Reload, state.session.randomInt(500, 7500));
                    }
                }, this.session.randomInt(1000, 5000));
                break;
            default:
                console.warn(`Session (${this.id}): Unsupported event: ${event} (${data})`);
                break;
        }
    }
}

inz.login.StateSaving = class extends inz.login.State {
    constructor(id, session) {
        super(id, session);
        this.successStateId = inz.login.Session.States.Saved;
    }

    entry(data) {
        this.session.timeoutStop();
        this.session.submissionId = data;
        this.successStateId = ( (typeof data === "undefined") || data === null ? inz.login.Session.States.Saved : data);
        super.entry(data);
        this.doSave();
    }

    doSave() {
        let state = this;
        window.form.setLeaveWarning(false);
        inz.forms.message.showMessage('<p>Your information is being saved for later</p>', 'Saving progress', 'Draft saving', true);
        inz.forms.message.scrollToMessage();
        form.saveState().done(function(success) {
            if(success) {
                state.session.haveSaved();
                state.session.transition(state.successStateId);
            } else {
                // retry?
                state.session.transition(state.successStateId);
            }
        });
    }
}

inz.login.StateSaved = class extends inz.login.State {

    entry(data) {
        let state = this;
        this.session.timeoutStart();
        $('#pd_print, #pd_download').hide();
        inz.forms.message.hideMessage();
        inz.forms.message.showMessage('<p>We have saved your information. You can log out and complete the form later.</p><input name="session_close" class="btn btn__blockxs btn__primary center-block" type="submit" value="Log out" /><input name="view_drafts" class="btn btn__blockxs btn__secondary center-block" type="button" value="View Drafts" />', 'Progress saved', 'Draft saved', false);
        inz.forms.message.scrollToMessage();
        $('input[name="session_close"]').click(function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).attr("disabled", "disabled");
            $('input[name="view_drafts"]').attr("disabled", "disabled");

            state.session.timeoutStop();
            inz.forms.message.hideMessage();
            inz.forms.message.showMessage('', 'Logging out', '', true);
            inz.forms.message.scrollToMessage();
            state.session.transition(inz.login.Session.States.LoggingOut);
        });
        $('input[name="view_drafts"]').click(function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).attr("disabled", "disabled");
            $('input[name="session_close"]').attr("disabled", "disabled");

            window.location.reload();
        });
        super.entry(data);
    }
}

inz.login.StateLoggingOut = class extends inz.login.State {

    entry(data) {
        super.entry(data);
        window.form.setLeaveWarning(false);
        this.session.timeoutStop(); // don't want warnings while trying to logout
        this.doLogout();
    }

    doLogout() {
        let state = this;
        $.ajax({
            dataType: "json",
            method: "POST",
            url: "/@@realme-cancel-session",
            data: {
                ReturnUrl: this.session.timeoutUrl()
            },
        }).done(function(data, textStatus, jqXHR) {
            state.session.channel.postMessage(inz.login.Session.Events.TabLogout);
            window.location = data.url;
        }).fail(function(jqXHR, textStatus, errorThrown) {
            window.location = data.url;
        });
    }
}

inz.login.StateWarning = class extends inz.login.State {
    constructor(id, session) {
        super(id, session);
        this.warningTimer = 0;
    }

    entry(data) {
        super.entry(data);
        this.session.timeoutStop();

        // are we in drafts?
        let inDrafts = this.session.inDrafts();
        let isSubmitted = this.session.isSubmitted();
        if (inDrafts) {
            inz.forms.message.showMessage('<p>You have been inactive and your session is about to expire. If you would like to continue working please click the "Continue Working" button below. Alternatively, click "Log Out" to log out.</p><input name="session_refresh" class="btn btn__blockxs btn__primary center-block" value="Continue Working" /> <a class="btn btn__blockxs btn__secondary center-block save_and_exit" type="button">Log Out</input>', 'Session expiring', 'Login session inactivity', true);
        } else if (isSubmitted) {
            inz.forms.message.showMessage('<p>You have been inactive and your session is about to expire. If you would like to continue working please click the "View Drafts" button below, otherwise, click "Log out".</p><input name="session_close" class="btn btn__blockxs btn__primary center-block" type="submit" value="Log out" /><input name="view_drafts" class="btn btn__blockxs btn__secondary center-block" type="button" value="View Drafts" />', 'Session expiring', 'Login session inactivity', true);
        } else {
            inz.forms.message.showMessage('<p>You have been inactive and your session is about to expire. If you time out, your work may be lost. If you would like to continue working please click the "Continue Working" button below. Alternatively, click "Save and Complete Later" to save your data and log out.</p><input name="session_refresh" class="btn btn__blockxs btn__primary center-block" value="Continue Working" /><a class="btn btn__blockxs btn__secondary center-block save_and_exit">Save and Complete Later</input>', 'Session expiring', 'Login session inactivity', true);
        }
        inz.forms.message.scrollToMessage();

        // Save state?
        let state = this;
        $('input[name="session_refresh"]').click(function(e) {
            $(this).attr("disabled", "disabled");
            $('.modal_forms_message a.save_and_exit').attr("disabled", "disabled");

            state.cancelTimeout();
            state.session.refresh().done(function(data) {
                // Don't really care if it was successful or not.
                if (inDrafts) { // reload page
                    window.location.reload();
                } else {
                    if (data !== null) { // have updated session data
                        state.session.data = data;
                        let remaining = state.session.sessionRemaining(data);
                        console.debug(`Refresh, session remaining: ${remaining}`);
                    }
                    inz.forms.message.hideMessage();
                    // resume state
                    state.session.transitionLast();
                }
            });
        });
        $('.modal_forms_message a.save_and_exit').click(function(e) {
            $(this).attr("disabled", "disabled");
            $('input[name="session_refresh"]').attr("disabled", "disabled");

            state.cancelTimeout();
            if (inDrafts) { // just logout
                //sess.logout(); // will reload page
                state.session.processEvent(inz.login.Session.Events.LogOut);
            } else {
                state.session.processEvent(inz.login.Session.Events.Save);
                //sess.save_and_exit();
                /*sess.closeout().done(function(success) {
                    // Don't really care if it was successful or not.
                    inz.forms.message.hideMessage();
                });*/
            }
        });
        $('input[name="session_close"]').click(function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).attr("disabled", "disabled");
            $('input[name="view_drafts"]').attr("disabled", "disabled");

            state.session.timeoutStop();
            inz.forms.message.hideMessage();
            inz.forms.message.showMessage('', 'Logging out', '', true);
            inz.forms.message.scrollToMessage();
            state.session.transition(inz.login.Session.States.LoggingOut);
        });
        $('input[name="view_drafts"]').click(function(event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).attr("disabled", "disabled");
            $('input[name="session_close"]').attr("disabled", "disabled");
            window.location.reload();
        });
        let remaining = this.session.sessionRemaining();
        let warningTimeout = inz.login.Session.WARNING_TIME - 10000; // actual session time remaining doesn't matter due to grace period for saving
        console.debug(`Login: Session remaing: ${remaining} Warning Timeout: ${warningTimeout}`);
        this.warningTimer = window.setTimeout(() => { state.warningTimeout() }, warningTimeout ); // 10s before actual timeout
    }

    exit() {
        this.cancelTimeout();
        super.exit();
    }

    run(event, data) {
        console.debug(`Session (${this.id}) : event: ${event} data: ${data}`);
        switch (event) {
            case inz.login.Session.Events.Active:
                this.session.active();
                break;
            case inz.login.Session.Events.Drafts:
                this.session.transition(inz.login.Session.States.Drafts);
                break;
            case inz.login.Session.Events.Save:
                this.session.transition(inz.login.Session.States.Saving, inz.login.Session.States.LoggingOut);
                break;
            case inz.login.Session.Events.LogOut:
                this.session.transition(inz.login.Session.States.LoggingOut);
                break;
            case inz.login.Session.Events.TimeOut:
                break;
            case inz.login.Session.Events.TabLogout:
                if (this.shouldSave()) {
                    this.session.transition(inz.login.Session.States.Saving, inz.login.Session.States.Reload);
                } else {
                    this.session.transition(inz.login.Session.States.Reload);
                }
                break;
            default:
                console.warn(`Session (${this.id}): Unsupported event: ${event} (${data})`);
                break;
        }
    }

    doLogout() {
        let state = this;
        $.ajax({
            dataType: "json",
            method: "POST",
            url: "/@@realme-cancel-session",
            data: {
                ReturnUrl: this.session.timeoutUrl()
            },
        }).done(function(data, textStatus, jqXHR) {
            state.session.timeoutStop();
            window.location = data.url;
        }).fail(function(jqXHR, textStatus, errorThrown) {
            state.session.timeoutStop();
            window.location = data.url;
        });
    }

    shouldSave() {
        console.debug(`Should save: Last: ${this.session.lastState === null? "None" : this.session.lastState.id} InDrafts: ${this.session.inDrafts()} Form Modified: ${this.session.formIsModified()}`);
        if (this.session.inDrafts() || this.session.lastStateWas(inz.login.Session.States.Saved) || this.session.lastStateWas(inz.login.Session.States.Drafts))
            return false;
        return this.session.formIsModified();
    }

    warningTimeout() {
        console.warn("Warning Timeout");
        if (this.shouldSave()) { // save, then logout
            this.session.transition(inz.login.Session.States.Saving, inz.login.Session.States.LoggingOut);
        } else { // log out
            this.session.transition(inz.login.Session.States.LoggingOut);
        }
    }

    cancelTimeout() {
        if (this.warningTimer !== 0) {
            window.clearTimeout(this.warningTimer);
            this.warningTimer = 0;
        }
    }
}

inz.login.StateReload = class extends inz.login.State {
    constructor(id, session) {
        super(id, session);
        this.reloadDelay = this.session.randomInt(500, 5000);
    }

    entry(data) {
        super.entry(data);
        this.session.timeoutStop();
        window.form.setLeaveWarning(false);

        inz.forms.message.showMessage('<p>Your session has ended.', 'Session expired', '', true);

        let reloadDelay = this.reloadDelay;
        if ((typeof data !== "undefined") && data !== null) {
            let delay = Number(data);
            if (Number.isSafeInteger(delay) && delay > 0) {
                reloadDelay = delay;
            }
        }
        if (reloadDelay > 0 ) {
            window.setTimeout(()=>{ window.location.reload(); }, reloadDelay);
        } else {
            window.location.reload();
        }
    }

    run(event, data) {
    }
}


/**
 * Logged In ->
 *  on entry -> setup session timer, on timer -> Warning
 *  if drafts -> Drafts
 *  else new -> Loaded
 *
 * Drafts ->
 *  New
 *  Loaded, fail back to drafts
 *
 * Loaded (Active, New) -> (change, Cancel, Save)
 *  on change -> Loaded Active
 *  Cancel -> Drafts (reload)
 *  Save -> Saving
 *
 * Saving -> (success, fail)
 *  on success -> Saved, or Logged out (reload)
 *  on failure -> ? back to New Active or Loaded Active with message?
 *
 * Saved -> (view drafts, log out)
 *  on view drafts -> Reload (back to Drafts)
 *  on log out -> Logging out
 *
 * Logging out -> (success, fail)
 *  on entry -> notify other tabs
 *  on success -> Reload
 *  on failure
 *
 * Warning -> (time out, continue, log out)
 *  on time out -> if Active Saving (success Logged Out) else Logging Out
 *  on log out -> if Active Saving (success Logged Out) else Logging Out
 *  on continue -> Loaded
 */

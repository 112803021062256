
inz.forms = inz.forms || {};
inz.forms.fields = inz.forms.fields || {};
inz.forms.validations = inz.forms.validations || {};

/**
 * Number Field
 */

inz.forms.fields.Number = class extends inz.forms.fields.Field {

    static setupFieldType(parsley)
    {
        window.Parsley.addValidator(
            'numberField',
            {
                validateString: function(value, requirement, instance)
                {
                    let field = instance.$element.data("field");
                    let isValid = field.isValid();
                    /*if(!isValid)
                    {
                        window.Parsley.addMessage('en', 'internationalPhoneNumber', ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"][iti.getValidationError()]);
                    }*/
                    return isValid;
                },
            }
        );
    }


    useGetOnSubmit() { return true; };

    init(form, $ctx, config) {
        super.init(form, $ctx, config);
        //this.validation = null;
        //this.prefix = null;
        //this.inputMask = "999.99";
        this.inputMask = {
            'alias': 'decimal',
            'digits': this.config.cfg.dp,
            'digitsOptional': false,
        };

        if ('plh' in this.config.cfg) {
            this.inputMask.placeholder = this.config.cfg.plh;
        }

        if (this.config.cfg.sep === true) {
            this.inputMask.groupSeparator = ',';
            this.inputMask.autoGroup = true;
        }

        this.restrictInput = this.config.cfg.res;

        if ('pfx' in this.config.cfg) {
            this.inputMask.prefix = this.config.cfg.pfx;
        }

        if ('sfx' in this.config.cfg) {
            this.inputMask.suffix = ' '+this.config.cfg.sfx;
        }

        if ('max' in this.config.cfg && this.restrictInput) { // only set maximum if restrict input set
            this.inputMask.max = this.config.cfg.max;
        }
        /*if ('min' in this.config.cfg) {
            this.inputMask.min = this.config.cfg.min;
        }*/

        /*if ('max' in this.config.cfg) {
            let max_str = String(this.config.cfg.max);
            if (max_str.indexOf('.') !== -1) {
                max_str = max_str.substring(0, max_str.indexOf('.'));
            }
            this.inputMask.mask = `9{0,${max_str.length}}`;
            if (this.config.cfg.dp!==0) {
                this.inputMask.mask += `.9{0,${this.config.cfg.dp}}`;
            }
        }*/
    }

    reset() {
        super.reset();
    }

    enableValidation()
    {
        //console.log("enable val: "+this.id);
        this.$el.removeClass('disable_val');
        this.$el.removeAttr('disabled', 'disabled');
    }

    setup(initial) {
        let _this = this;
        //this.$el.on("input", function() { _this.inputTransforms(this); });
        this.$el.inputmask(this.inputMask);
        //this.$el.attr("data-parsley-error-message", "Please enter "+message);

        /*this.$el.on("focusout", function() {
            let v = _this.getNumberValue();
            console.log(`Focus out: ${v}`);
        });*/
        this.$el.on("focusout", this.form.focusOut);
        this.$el.keyup(this, this.form.changeEvent);
        this.defaultDisplay();
    }

    setLabel(label) {
        this.$container.find("label").contents().first().replaceWith(label);
    }

    inputTransforms(field) {
        if (this.validation === null || this.validation === undefined)
            return;
        this.validation.inputTransforms(field);
    }

    validateInput(str) {
        // var r = RegExp(/^(\d{1,2})-(\d{1,4})-(\d{1,8})-(\d{1,4})$/).exec(str);  // this is the correct validation for the IRD spec, however INZ require that we reject suffixes that are not 2 or 3 characters
        //console.log("Bank Code: Validate Input: "+this.validation);
        if (this.inputMask && !this.$el.inputmask("isComplete"))
            return false;
        if (this.validation === null || this.validation === undefined)
            return true;
        if (!this.validation.validate(str))
            return false;
        //return this.validation.validate(str);
        return true;
    }

    getValue() {
        return this.$el.val();
    }

    getNumberValue() {
        let v = this.$el.inputmask('unmaskedvalue');
        return v;
    }

    isValid() {
        let n = this.getNumberValue();
        if (isNaN(n)) {
            return false;
        }
        // min max?
        if ('max' in this.config.cfg) {
            if (n > this.config.cfg.max) {
                console.log(`Over: value: ${n} is over max: ${this.config.cfg.max}`);
                return false;
            }
        }
        if ('min' in this.config.cfg) {
            if (n < this.config.cfg.min) {
                console.log(`Under: value: ${n} is under min: ${this.config.cfg.min}`);
                return false;
            }
        }

        return true;
    }
}

inz.forms = inz.forms || {};
inz.forms.resv = inz.forms.resv || {};

inz.forms.resv.isResCondition = function(config) {
    return config.test === "NATIONAL_SECURITY" || config.test === "ADDITIONAL_INFORMATION";
}

inz.forms.resv.isResField = function(form, field) {
    let id = field.id;
    for (let f of form.resvFieldIds) {
        if (id.startsWith(f))
            return true;
    }
    return false;
}

inz.forms.resv.reId = new RegExp("f_([a-zA-Z0-9]{7,})__(\\d)+");

inz.forms.resv.Condition = class extends inz.forms.Condition {

    static FIELDS = ['country_of_birth', 'citizenship', 'date_of_birth', 'gender'];
    static NATIONAL_COUNTRIES = ['CHN', 'CUB', 'EGY', 'HKG', 'ISR', 'KEN', 'LBN', 'MAC', 'NGA', 'PAK', 'PSE', 'SOM', 'TUR'];
    static ADD_COUNTRIES_1 = ['PRK'];
    static ADD_COUNTRIES_2 = ['IRN', 'IRQ', 'SSD', 'SDN', 'SYR', 'DZA', 'AGO', 'BLR', 'BDI', 'CIV', 'COG', 'ETH', 'HTI', 'LBR', 'LBY', 'MMR', 'RUS', 'RWA', 'SLE', 'LKA', 'ZWE'];
    static ADD_COUNTRIES_3 = ['AFG', 'SOM', 'YEM'];
    static ADD_COUNTRIES_4 = ['KHM'];

    init(form, field, config) {
        this.form = form;
        this.field = field;
        this.testType = config.test;
        this.testValue = config.tvalue;
        this.op = config.op;

        this.resFields = {};
        this.dependField = null;
        // depend field is source of test fields
        let src_in_group = false;

        if (this.form instanceof inz.forms.SMCAppForm) {
            this.dependField = form.getField(config.depends);
            src_in_group = this.dependField.$container.closest('.form_group').length !==0;
        }

        // add as dependant on fields in field map
        //field_names = ['date_of_birth', 'country_of_birth', 'citizenship', 'gender'];
        let ret = true;
        let in_group = this.field.$container.closest('.form_group').length !==0;
        //let in_set = this.field.$container.closest('.form_fieldset').length !==0;
        //console.log("In group: "+in_group);
        if (src_in_group) {
            //let group = this.field.$container.closest('.form_group');
            let map = form.fieldMap[this.dependField.group.id];
            let idx = inz.forms.resv.reId.exec(field.getId())[2];
            if(map === undefined)
                return false;
            this.mapFields(map, "__"+idx);
        } else if (in_group) {
            //let group = this.field.$container.closest('.form_group');
            let map = form.fieldMap[field.group.id];
            let idx = inz.forms.resv.reId.exec(field.getId())[2];
            if(map === undefined)
                return false;
            this.mapFields(map, "__"+idx);
        /*} else if (in_set) {
            let fieldset = this.field.$container.closest('.form_fieldset');
            let map = form.fieldMap[field.group.id];
            if(map === undefined)
                return false;
            this.mapFields(map, "");*/
        } else {
            if (form.fieldMap['__'] === undefined || Object.keys(form.fieldMap['__']).length === 0)
                return false;
            this.mapFields(form.fieldMap['__'], "");
        }
        return ret;
    }

    mapFields(map, postfix) {
        for (let field_name of Object.keys(map)) {
            for (let field_id of map[field_name]) {
                let field = this.form.getField(field_id+postfix);
                if (this.resFields[field_name] !== undefined) {
                    this.resFields[field_name].push(field);
                    field.addDependant(this.field);
                } else {
                    this.resFields[field_name] = [field];
                    field.addDependant(this.field);
                }
            }
        }
    }

    remove() {  // tidy up for condition removal
        for (let name of Object.keys(this.resFields)) {
            for (let f of this.resFields[name]) {
                f.removeDependant(this.field);
            }
        }
    }

    shouldTest(src) {
        return true;
    }

    test(value) {
        //console.log(`Res Test: ${this.field.getId()}`);
        if(this.testType === "NATIONAL_SECURITY") {
            if(this.testNational())
                return true;
        } else if(this.testAdditional())
            return true;
        return false;
    }

    calcAge(dob) {
        if (!(dob instanceof Date))
            dob = new Date(dob);
        let dt = new Date(Date.now() - dob);
        let age = Math.abs(dt.getUTCFullYear()-1970);
        return age;
    }

    getFieldValue(name) {
        if (name in this.resFields) {
            for(let field of this.resFields[name]) {
                if(field.isHidden === false) {
                    return field.getValue(true); // want real value
                }
            }
        }
        return undefined;
    }

    testNational(grp, postfix) {
        let citizenship = this.getFieldValue('citizenship');
        let birth = this.getFieldValue('country_of_birth');
        //console.log(`testNational: Citizenship: ${citizenship} Birth: ${birth}`);
        // Country of Citizenship or Country of birth is one of the following
        if (!inz.forms.resv.Condition.NATIONAL_COUNTRIES.includes(citizenship) && ! inz.forms.resv.Condition.NATIONAL_COUNTRIES.includes(birth)) {
            // not in country list
            return false;
        }
        let dob = new Date(this.getFieldValue('date_of_birth'));
        let age = this.calcAge(dob);
        //let dob = new Date(this.resFields[grp]['date_of_birth'].getValue());
        //console.log(`DOB: ${dob} - Age: ${age}`);
        if (age >= 17)
            return true;

        return false;
    }

    testAdditional(grp, postfix) {
        let citizenship = this.getFieldValue('citizenship');
        let birth = this.getFieldValue('country_of_birth');
        //console.log(`testAdditional: Citizenship: ${citizenship} Birth: ${birth}`);
        // Country of Citizenship or Country of birth is one of the following
        if (inz.forms.resv.Condition.ADD_COUNTRIES_1.includes(citizenship) || inz.forms.resv.Condition.ADD_COUNTRIES_1.includes(birth)) {
            // North Korea
            return true;
        }

        // need age now
        let dob = new Date(this.getFieldValue('date_of_birth'));
        let age = this.calcAge(dob);
        // OR
        if (inz.forms.resv.Condition.ADD_COUNTRIES_2.includes(citizenship) || inz.forms.resv.Condition.ADD_COUNTRIES_2.includes(birth)) {
            if (age >= 17)
                return true;
        }

        //need gender now
        //let gender = this.form.getField(this.form.fieldMap[grp]['gender']+postfix).getValue(); // 'M'
        let gender = this.getFieldValue('gender'); // 'M'
        // OR
        if (inz.forms.resv.Condition.ADD_COUNTRIES_3.includes(citizenship) || inz.forms.resv.Condition.ADD_COUNTRIES_3.includes(birth)) {
            if (gender === "M" && age >= 17)
                return true;
        }

        // OR
        if (inz.forms.resv.Condition.ADD_COUNTRIES_4.includes(citizenship) || inz.forms.resv.Condition.ADD_COUNTRIES_4.includes(birth)) {
            if (gender === "M" && dob < new Date('01-01-1981'))
                return true;
        }

        return false;
    }

}

